import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResultEnvelope } from '../../../../../api/src/_core/models/paginated-result-envelope';
import { QueryParams } from './collection/collection.model';
import { DefaultResponse } from './session';

/**
 * This is the base service for most
 */
@Injectable()
export abstract class BaseEntityService {

  	constructor() { }

	/**
	 * Returns a list of all Entities
	 */
	abstract get(params: QueryParams, append?: boolean, returnOnly?: boolean, ...args): Observable<DefaultResponse<any>>;

	/**
	 * Create Entity
	 */
	create?(entity: any): Observable<any>;

	/**
	 * Clone Entity
	 */
	clone?(entity: any, ...args): Observable<any>;

	/**
	 * Remove Entity
	 */
	remove?(entity: any, ...args): Observable<DefaultResponse<any>>;

}
