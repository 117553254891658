<div class="export-destinations-settings-dialog__header">
	<h1 mat-dialog-title class="export-destinations-settings-dialog__header-title">
		{{ data.exportDestination.name }}
	</h1>
	<mat-icon class="export-destinations-settings-dialog__header-btn" [mat-dialog-close]="null">close</mat-icon>
</div>

<div mat-dialog-content>

	<div [formGroup]="form" class="export-destinations-settings-dialog__form">
		<div class="export-destinations-settings-dialog__group">
			<span class="export-destinations-settings-dialog__title" i18n="Form Group Title@@exportDestinationsSelectEntityTitle">Select {{maxEntities == 1 ? data.exportDestination.action.entityName : data.exportDestination.action.entityNamePlurar}}</span>
		</div>

		<ng-container *ngIf="categoryField">
			<app-select-input
				*ngFor="let category of (categories$ | async)"
				[label]="category.name"
				[placeholder]="categoryField.placeholderValue"
				[options]="category.values"
				[fullWidth]="true"
				(valueChange)="selectedCategory = $event"
			></app-select-input>
		</ng-container>

		<div class="export-destinations-settings-dialog__group">
			<mat-form-field class="no-underline form-field dark-color export-destinations-settings-dialog__input" floatLabel="never" appearance="fill">
				<mat-label i18n="Form Label@@exportDestinationsSelectEntitiesLabel">Select {{maxEntities == 1 ? data.exportDestination.action.entityName : data.exportDestination.action.entityNamePlurar}}</mat-label>
				<mat-select formControlName="printers" multiple [compareWith]="printerCompare">
					<mat-option
						*ngFor="let entity of (data.allEntities | filterArray: printerCategoryFilter: selectedCategory?.value)"
						[value]="entity"
						[disabled]="entity | transformObject: isPrinterOptionDisabled: (form.value.printers || []): customAddresses.value: maxEntities"
					>
						{{ entity.bottlerName }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="export-destinations-settings-dialog__group">
			<div class="printer-info flex separator-bottom pb-3 pt-3" *ngFor="let selectedPrinter of (this.form.value.printers || [])">
				<div class="flex flex-column flex-grow" *ngIf="selectedPrinter.contacts | transformObject: getActionDetails: data.allEntities as description">
					<div class="name">{{ description.bottlerName || '' }}</div>
					<div class="description">{{ description.contacts }}</div>
				</div>
				<div class="actions">
					<mat-icon class="cursor-pointer p-2" (click)="onDeselectPrinters(selectedPrinter)">close</mat-icon>
				</div>
			</div>
			<ng-container formGroupName="customAddresses">
				<div *ngFor="let fieldCustomization of customAddresses?.controls; index as index" [formGroupName]="index" class="form-group-element separator-bottom pb-3 pt-3">
					<mat-form-field class="form-field dark-color no-min-width no-label full-width" appearance="fill">
						<mat-label i18n="Form Label@@exportDestinationsCustomAddressEntityNameLabel">{{data.exportDestination.action.entityName}} name</mat-label>
						<input matInput formControlName="bottlerName"/>
					</mat-form-field>
					<mat-form-field class="form-field dark-color no-min-width no-label full-width" appearance="fill">
						<mat-label i18n="Form Label@@exportDestinationsCustomAddressEmailAddressLabel">Email address</mat-label>
						<input type="text" matInput formControlName="contacts">
					</mat-form-field>
					<div class="actions">
						<mat-icon class="cursor-pointer p-2" (click)="removeCustomAddresses(index)">close</mat-icon>
					</div>
				</div>
			</ng-container>

			<div class="mt-4">
				<button mat-button class="size-small" (click)="addCustomAddress()" *ngIf="(form.value.printers?.length || 0 + customAddresses.value?.length || 0) < maxEntities">
					<mat-icon inline>add</mat-icon>
					<ng-container i18n="Add Button@@exportDestinationsCustomAddressAddEntityLabel">
						Add {{data.exportDestination.action.entityName | titlecase}}
					</ng-container>
				</button>
			</div>
		</div>

		<div class="export-destinations-settings-dialog__group mt-2">
			<span class="export-destinations-settings-dialog__title" i18n="Form Group Title@@exportDestinationsCommentsTitle">Comments</span>
			<span class="export-destinations-settings-dialog__subtitle" i18n="Context Subtitle@@exportDestinationsCommentsSubtitle">Optional</span>
		</div>

		<mat-form-field class="no-underline form-field dark-color export-destinations-settings-dialog__input" floatLabel="never" appearance="fill">
			<textarea matInput rows="2" cols="20" formControlName="comment" placeholder="Enter your comments here" i18n-placeholder="Form Placeholder@@exportDestinationsCommentsPlaceholder"></textarea>
		</mat-form-field>

		<div class="form-error-container" *ngIf="!form.valid">
			<mat-icon class="mr-1">clear</mat-icon>
			<span i18n="Required fields not valid Message@@exportDestinationsRequiredFieldsNotValidMessage">Required fields not valid.</span>
		</div>
	</div>
</div>


<div mat-dialog-actions class="pt-5 pb-3">
	<button class="margin-left-auto export-destinations-settings-dialog__button" mat-button [mat-dialog-close]="null" i18n="Dialog Action Button@@exportDestinationsCancelButtonLabel">
		Cancel
	</button>
	<button mat-flat-button color="primary" [disabled]="!form.valid || (!this.form.value.printers?.length && !this.form.value.customAddresses?.length)" class="remove button-extra-small export-destinations-settings-dialog__button" (click)="submit()"
			i18n="Dialog Action Button@@exportDestinationsSaveButtonLabel">
		Save
	</button>
</div>
