import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformObject'
})
export class TransformObjectPipe implements PipeTransform {
  transform(object: any, transformFunction: (...any) => any, ...params: any[]): any {

    if (object === undefined || object === null || !transformFunction) {
      return object;
    }

    return transformFunction(object, ...params);

  }
}
