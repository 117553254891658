import { Injectable } from '@angular/core';
import { Survey } from './survey.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface SurveyState extends EntityState<Survey> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'survey', resettable: true })
export class SurveyStore extends EntityStore<SurveyState> {
	constructor() {
		super();
	}
}
