import {Clip} from "../../_core/state/clip";
import { StorySegment, StorySegmentQuery } from "../../_core/state/story-segment";
import { Story } from "../../_core/state/story";
import { VideoClip } from "../../_core/state/project";
import { EncodeJobObject } from "../../_core/state/global/global.model";
import { AudioLayer } from "../../../../../api/src/encode/models/job-def.dto";
import { CloudinaryVideoTransformPipe } from "../pipes/cloudinary-video-transform.pipe";

export function buildOrderClipData(clip: Clip, clipIndex: number, order: EncodeJobObject, story: Story, storySegmentQuery: StorySegmentQuery) {
	const format = order.storyFormat;
	const segment = storySegmentQuery.getEntityWithUI(clip.segmentId);

	const orderHasCustomBackgroundVideo = storySegmentQuery.getAll().find((segment: StorySegment) => segment.endingSegment);
	const durationOverride = order.voiceovers.some(vo => vo.overrideDurationValidation);
	const clipLength = order.clips.length;

	const underlayString: string | null =
		format.tileUnderlaySizeURL || clip.horizontalOffset || clip.verticalOffset
			? format.tileUnderlaySizeURL || "https://res.cloudinary.com/dh3gpwrps/image/upload/v1697560826/video_builder/images/16-9-transparent.png"
			: null;

	let videoi: number,
		voi: number,
		musici: number,
		audioTransitionIn: { st: number; duration: number },
		audioTransitionOut: { st: number },
		audioTransitionLayersEnabled: AudioLayer[]

	if (orderHasCustomBackgroundVideo) {
		segment.endingSegment
			? (audioTransitionIn = { st: 0, duration: 0.35 })
			: (audioTransitionOut = { st: clip.duration - 0.35 });
	}

	if (story.storySegments.length === 1) {
		audioTransitionOut = { st: segment.maximumDuration - 0.35 };
	}

	if (segment.endingSegment) {
		if (clip.volume || clip.volume == 0) {
			const rangeStart = -70; // 0%
			const rangeEnd = -5; // 100%
			videoi = rangeStart + (clip.volume / 100) * (rangeEnd - rangeStart);
		}

		voi = clip.muteVoiceOver ? -70 : -12;
		musici = clip.muteMusic ? -70 : -24;
	}

	if (durationOverride) {
		audioTransitionLayersEnabled = [AudioLayer.SFX];
	}

	const uiOverlays = segment?.ui?.activeClip?.overlays;

	let path: string = clip.video.urls.original;
	let horizontalOffset: number = uiOverlays.find(o => o.clipOffsetHorizontal && storySegmentQuery.isOverlayVisible(o, segment))?.clipOffsetHorizontal || clip.horizontalOffset;
	if (clip.video.isCustomVideo) {
		let videoTransform = new CloudinaryVideoTransformPipe();
		let params = {
			width: 1920, height: 1080
		};
		if (clip.crop) params["crop"] = clip.crop;
		path = videoTransform.transform(path, params);
		horizontalOffset /= 2;
	}

	return {
		path: path,
		duration: segment.maximumDuration || clip.duration || clip.video.duration,
		overlayOffset: clip?.overlays.find(o => o?.offset)?.offset,
		overlayDuration: clip?.overlays.find(o => o?.duration)?.duration,
		// HACK: #32 REMOVE ME WHEN SLATES ARE HANDLED
		//seek: (idx === 0) ? 7 : clip.seek,
		seek: clip.seek,
		horizontalOffset: horizontalOffset,
		verticalOffset: uiOverlays.find(o => o.clipOffsetVertical && storySegmentQuery.isOverlayVisible(o, segment))?.clipOffsetVertical || clip.verticalOffset,
		audioImusic: musici,
		audioIvo: voi,
		audioIvideo: videoi,
		audioTransitionLayersEnabled: audioTransitionLayersEnabled,
		audioTransitionOut: audioTransitionOut,
		audioTransitionIn: audioTransitionIn,
		underlay: underlayString,
		useGlobalOverlay: clipIndex < clipLength - 1 && !clip?.overlays.find(o => o.duration)?.duration ? true : false,
		overlayData: segment?.ui.overlays.find(o => o.id?.length && o?.id?.includes('merged-overlay'))?.snapshot
	} as VideoClip;
}
