<ng-container [ngSwitch]="type">
	<!-- Group -->
	<ng-container *ngSwitchCase="'group'">
		<app-group-input
			[label]="label"
			[parentForm]="parentForm"
			[controlName]="controlName"
			[patchChanges]="patchChanges"
			[currentValue]="currentValue"
			[placeholder]="placeholder"
			[hint]="hint"
			(valueChange)="changeValue($event)"
		></app-group-input>
	</ng-container>

	<!-- Text -->
	<ng-container *ngSwitchCase="'text'">
		<app-text-input
			[label]="label"
			[parentForm]="parentForm"
			[controlName]="controlName"
			[patchChanges]="patchChanges"
			[currentValue]="currentValue"
			[placeholder]="placeholder"
			[hint]="hint"
			(valueChange)="changeValue($event)"
		></app-text-input>
	</ng-container>

	<!-- Select -->
	<ng-container *ngSwitchCase="'select'">
		<app-select-input
			[label]="label"
			[parentForm]="parentForm"
			[controlName]="controlName"
			[patchChanges]="patchChanges"
			[currentValue]="currentValue"
			[options]="options"
			[placeholder]="placeholder"
			[hint]="hint"
			(valueChange)="changeValue($event)"
		></app-select-input>
	</ng-container>

</ng-container>
