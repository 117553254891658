import { environment } from "../../../environments/environment";


export abstract class CloudinaryTransformPipe {

  readonly cloudinaryCloudName: string = environment.cloudinaryCloudName;

  readonly paramsMap = {
    width: "w_",
    height: "h_",
    mode: "c_",
    gravity: "g_",
    startOffset: "so_",
    endOffset: "eo_",
    duration: "du_",
    crop: "c_"
  };

  abstract type: "video" | "image";

  transform(assetPath: string, params: any): any {

    if (!params || Object.keys(params).length == 0) return assetPath;

    let transformations: string[] = [];

    for (let key of Object.keys(params)) {
      if (this.paramsMap[key]) {
        transformations.push(this.paramsMap[key] + params[key]);
      }
    }

    let joinedTransformations = `${transformations.join(',')}${transformations.length > 0 ? '/' : ''}`;
    let qualityOptimization = params.quality ? `${params.quality}/` : '';
	let advancedTransformation = params.adv ? `${params.adv}/` : '';

    return `https://res.cloudinary.com/${this.cloudinaryCloudName}/${this.type}/fetch/${joinedTransformations}${qualityOptimization}${advancedTransformation}${assetPath}`;
  }

}
