import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { StrapiCollectionResponse } from '../global/global.model';
import { AILanguage, createLanguage } from './language.model';
import { LanguageStore } from './language.store';
import qs from "qs";

@Injectable({ providedIn: 'root' })
export class LanguageService {
	constructor(
		private readonly languageStore: LanguageStore,
		private readonly http: HttpClient
	) {}

	public get() {
		const query = qs.stringify({ populate: "*" });

		return this.http.get<StrapiCollectionResponse<AILanguage>>(`${environment.cmsUrl}/api/languages?${query}`, {
			headers: {
				Authorization: `Bearer ${environment.cmsToken}`
			}
		}).pipe(
			map(response => response?.data
				.map(languageEntity=> createLanguage(languageEntity))
				.sort((a, b) => {
					return Number(b.defaultLanguage) - Number(a.defaultLanguage);
				}) || []
			),
			tap(languages => this.languageStore.set(languages))
		);
	}

}

