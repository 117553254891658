import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Music } from './music.model';

export interface MusicState extends EntityState<Music>, ActiveState {
	activeOption: 'none' | 'preMade';
	selectedMusic: Music;
}

const initialState = {
	active: undefined,
	activeOption: undefined,
	selectedMusic: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'music'
})
export class MusicStore extends EntityStore<MusicState> {
	constructor() {
		super(initialState);
	}
}
