import { IsNotEmpty, IsString } from 'class-validator';

export class WorkspaceState {
	/* Need to fill this out roughly once we figure out what Snapshot or Persist state looks like on the front end */
	'story-format': {
		ids: number[];
		active: number;
		entities: {
			[key: string]: StoryFormat;
		};
	};
	story: {
		ids: number[];
		active: number;
		entities: {
			[key: string]: {
				title: string;
			};
		};
	};
	survey: {
		entities: {
			[key: string]: {
				questions: Array<{
					slug: string;
					title: string;
					value: string;
				}>;
			};
		};
		ids: number[];
	};
	'UI/story-segment': {
		ids: number[];
		entities: {
			[key: string]: {
				activeClip: {
					id: string;
					name: string;
					duration: number;
					video: {
						// TODO
						[key: string]: any;
					};
					// TODO
					overlays?: Array<any>;
				};
			};
		};
	};
}

export class StoryFormat {
	title: string;
	canvasHeight: number;
	canvasWidth: number;

	constructor(data?: any) {
		if (!data) return;
		this.title = data.title;
		this.canvasHeight = data.canvasHeight;
		this.canvasWidth = data.canvasWidth;
	}

	get resolutionText(): string {
		return this.canvasWidth + 'x' + this.canvasHeight;
	}
}
