import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { stringToSlug } from '../../../_core/utils/string.utils';

export interface ConfirmDialogData {
	title?: string;
	message?: string;
	confirmWithString?: boolean;
	confirmationString?: string;
	entityName?: string;
	confirm?;
	cancel?: string;
	unlink?: boolean;
	remove?: boolean;
	showEntityName?: boolean;
}

/**
 * Confirm Dialog
 * Use to confirm a user action.
 * Supports remove, unlink and custom messages
 * Supports type confirmation as well
 */
@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
	confirmation = new UntypedFormGroup({
		verifyString: new UntypedFormControl([''])
	});

	constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}

	yes() {
		this.dialogRef.close(true);
	}

	no() {
		this.dialogRef.close();
	}

	match() {
		if (this.confirmation?.value?.verifyString === this.data?.confirmationString) return true;
		return false;
	}
}
