import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { StoryQuery } from '../story';
import { StorySegmentQuery } from '../story-segment/story-segment.query';
import { MusicStore, MusicState } from './music.store';
import {Music} from './music.model';

@Injectable({ providedIn: 'root' })
export class MusicQuery extends QueryEntity<MusicState> {
	isMusicValid$ = this.select().pipe(
		map(state => {
			if (state.activeOption === 'none') {
				return true;
			} else if (state.activeOption === 'preMade' && state.active) {
				return true;
			} else {
				return false;
			}
		})
	);

	public getSelectedMusic(): Music | undefined {
		return this.getValue().selectedMusic;
	}

	public getActiveOption() {
		return this.getValue().activeOption;
	}

	constructor(protected override store: MusicStore) {
		super(store);
	}
}
