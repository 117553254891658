import { Asset, flattenStrapiEntity, StrapiEntity } from '../global/global.model';
import { AILanguage, AILanguageSettings, createLanguageSettings } from "../language";

export interface Voiceover {
	id: string;
	overrideDurationValidation?: boolean;
	targetDuration: number;
	fill?: boolean;
	startOffset?: number;
	endOffset?: number;
	audio: Asset;
	kind: 'upload' | 'ai';
	type: 'core' | 'endCard';
}

export interface AIVoiceoverStory {
	id: string;
	slug: string;
	title: string;
	type: 'core' | 'endCard';
	duration: number;
	createdAt: string;
	updatedAt: string;
	voiceoverSegments: VoiceoverSegment[];
}

export class AIVoice {
	uuid: string;
	name: string;
	// TODO: Probably want to pull this from the monorepo.
	provider?: 'resembleAI' | 'amazonPolly' | 'elevenLabs';
	similarity: number;
	stability: number;
	defaultVoice: boolean;
	languageConfigs?: AILanguageSettings[];
}

export interface AIVoiceSettings {
	similarity: number;
	stability: number;
}

export interface VoiceoverPackage {
	id: string;
	slug: string;
	title: string;
	duration: number;
	description: string;
	createdAt: string;
	updatedAt: string;
	voiceoverSegments: VoiceoverSegment[];
}

export interface VoiceoverPackageConfig {
	selectedVoice: AIVoice;
	selectedLanguage: AILanguage;
	segments: {
		[key: string]: VoiceoverSegmentConfig;
	};
	settings?: AIVoiceSettings;
}

export interface VoiceoverSegment {
	id: string;
	slug: string;
	title: string;
	duration: number;
	editable: boolean;
	defaultValue: string;
	startOffset?: number;
	endOffset?: number;
	enableNoneOption: boolean;
	enablePremade: boolean;
	enableUpload: boolean;
	enableAI: boolean;
	defaultOption: 'none' | 'premade' | 'upload' | 'ai';
	overrideDurationValidation: boolean;
	fill: boolean;
}

export interface VoiceoverSegmentConfig {
	selectedOption: 'none' | 'premade' | 'upload' | 'ai';
	voiceover: Voiceover;

	// AI Options
	selectedVoice?: AIVoice;
	voiceText?: string;
	voiceSpeed?: string;
}

export function getVoiceSettings(voice: AIVoice, languageCode?: string) {
	const languageConfig = voice?.languageConfigs?.find(l => l.language?.languageCode === languageCode);

	return {
		similarity: languageConfig?.similarity ?? voice?.similarity,
		stability: languageConfig?.stability ?? voice?.stability,
	};

}

export function mapVoiceoverSegments(segments: VoiceoverSegment[], packageDuration: number) {
	const fillSegmentsNum = segments.filter(s => s.fill).length;
	const noFillSegments = segments.filter(s => !s.fill);
	const noFillDuration = noFillSegments.reduce((acc, s) => acc + s.duration, 0) || 0;

	segments.forEach((segment, index) => {
		if (segment.fill) {
			if (fillSegmentsNum > 1) {
				segment.duration = (packageDuration - noFillDuration) / fillSegmentsNum;
			} else {
				segment.duration = packageDuration - noFillDuration;
			}

			// adjust the offset of every segment which comes after the filled segment
			let nextSegments = segments.filter((_, i) => i > index);

			if (nextSegments.length > 0) {
				nextSegments.forEach(nextSeg => {
					const beforeNextSeg = segments.filter((_, i) => i < segments.indexOf(nextSeg));

					nextSeg.startOffset = beforeNextSeg.reduce((acc, s) => acc + s.duration, 0) || 0;
				});
			}
		}
	});
	return segments;
}

export function createVoiceover(params: Partial<Voiceover>) {
	return {} as Voiceover;
}

export function createAIVoiceoverStory(strapiEntity: StrapiEntity<AIVoiceoverStory>, params?: Partial<AIVoiceoverStory>) {
	return {
		...flattenStrapiEntity(strapiEntity),
		...params
	} as AIVoiceoverStory;
}

export function createVoiceoverPackage(strapiEntity: StrapiEntity<VoiceoverPackage>, params?: Partial<VoiceoverPackage>) {
	const duration = strapiEntity.attributes.duration;

	return {
		...flattenStrapiEntity(strapiEntity),
		voiceoverSegments: mapVoiceoverSegments(strapiEntity.attributes.voiceoverSegments, duration),
		...params
	} as VoiceoverPackage;
}

export function createVoice(strapiEntity: StrapiEntity<AIVoice>, params?: Partial<AIVoice>) {
	const languageConfigs = strapiEntity.attributes.languageConfigs as unknown as AILanguageSettings[];

	return {
		...flattenStrapiEntity(strapiEntity),
		languageConfigs: languageConfigs?.map(l => createLanguageSettings(l as any)) || [],
		...params
	} as AIVoice;
}
