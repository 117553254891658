import { Story } from '../story';
import { StorySegment } from '../story-segment';
import { Survey } from '../survey/survey.model';
import { AssetFilter, AssetQueryRequest } from './global.model';

export function getAssetFilterTagCriteria(
	entityType: string,
	storyFormatFilters: AssetFilter[],
	storyFilters: AssetFilter[],
	surveys?: Survey[],
	storySegments?: StorySegment[]
) {
	let includeTags: any = [];
	let excludeTags: any = [];

	if (storyFormatFilters.length) {
		includeTags = combineAssetFilters(storyFormatFilters, entityType, 'include');
		excludeTags = combineAssetFilters(storyFormatFilters, entityType, 'exclude');
	}

	if (storyFilters.length) {
		let storyIncludeTags = combineAssetFilters(storyFilters, entityType, 'include');
		if (checkForParentOverride(storyFilters, entityType, 'include')) {
			includeTags = storyIncludeTags;
		} else {
			includeTags = includeTags.concat(storyIncludeTags);
		}

		let storyExcludeTags = combineAssetFilters(storyFilters, entityType, 'exclude');
		if (checkForParentOverride(storyFilters, entityType, 'exclude')) {
			excludeTags = storyExcludeTags;
		} else {
			excludeTags = excludeTags.concat(storyExcludeTags);
		}
	}

	if (surveys?.length) {
		surveys.forEach(survey => {
			// Loop through the survey, find correct answers and then try to combine all of those filters into the query.
			survey.questions.forEach(question => {
				let correctAnswers = [];
				if (question.questionType === 'multiSelect') {
					// For multiple choice, we need to combine all of the select options filters together, then
					// check if any of them have a parent override to see if we need to remove parents or concat them.
					correctAnswers = question.answers.filter(answer => question.value.includes(answer.label));
				} else {
					correctAnswers = [question.answers.find(answer => answer.label === question.value)];
				}

				if (!correctAnswers.length) {
					return;
				}

				let surveyIncludeTags = combineAssetFilters(correctAnswers, entityType, 'include');

				if (!surveyIncludeTags.length) {
					return;
				}

				// If its a multi-select, add OR between the tags and put inside of parenthesis.
				if (question.questionType === 'multiSelect') {
					surveyIncludeTags = [`(${surveyIncludeTags.join(' OR ')})`];
				}

				if (checkForParentOverride(correctAnswers, entityType, 'include')) {
					includeTags = surveyIncludeTags;
				} else {
					includeTags = includeTags.concat(surveyIncludeTags);
				}

				let surveyExcludeTags = combineAssetFilters(correctAnswers, entityType, 'exclude');
				if (checkForParentOverride(correctAnswers, entityType, 'exclude')) {
					excludeTags = surveyExcludeTags;
				} else {
					excludeTags = excludeTags.concat(surveyExcludeTags);
				}
			});
		});
	}

	if (storySegments?.length) {
		storySegments.forEach(storySegment => {
			let storySegmentIncludeTags = combineAssetFilters(storySegment.assetFilters, entityType, 'include');
			if (checkForParentOverride(storySegment.assetFilters, entityType, 'include')) {
				includeTags = storySegmentIncludeTags;
			} else {
				includeTags = includeTags.concat(storySegmentIncludeTags);
			}

			let storySegmentExcludeTags = combineAssetFilters(storySegment.assetFilters, entityType, 'exclude');
			if (checkForParentOverride(storySegment.assetFilters, entityType, 'exclude')) {
				excludeTags = storySegmentExcludeTags;
			} else {
				excludeTags = excludeTags.concat(storySegmentExcludeTags);
			}
		});
	}

	const storyDynamicAssetTagsFilters = storyFilters.filter(filter => filter.tags.startsWith('${') && filter.filterCriteria === 'include');
	if (storyDynamicAssetTagsFilters.length > 0) {
		includeTags = includeTags.concat(storyDynamicAssetTagsFilters.map(filter => filter.tags));
	}

	let response = {};
	if (includeTags.length > 0) {
		response['includeTags'] = includeTags.join(',');
	}
	if (excludeTags.length > 0) {
		response['excludeTags'] = excludeTags.join(',');
	}

	return response;
}

export function combineAssetFilters(assetFilters: AssetFilter[], entityType: string, filterCriteria: string) {
	return (
		assetFilters
			?.filter(assetFilter => assetFilter?.filterCriteria === filterCriteria && assetFilter.entityType === entityType)
			.map(assetFilter => {
				return assetFilter.tags.split(',');
			})
			.reduce((a, b) => a.concat(b), []) || []
	);
}

export function checkForParentOverride(assetFilters: AssetFilter[], entityType: string, filterCriteria: string) {
	return assetFilters
		?.filter(assetFilter => assetFilter?.filterCriteria === filterCriteria && assetFilter.entityType === entityType)
		.find(assetFilter => assetFilter.overrideParentEntityFilters);
}


export function trackById(index: number, item: any): number {
	return item.id;
}

export function compareWithSomething(a: any, b: any): boolean {
	if (a?.id && b?.id) {
		return a.id === b.id;
	}

	if (a?.value && b?.value) {
		return a.value === b.value;
	}

	return a === b;
}

export function groupItemsByParent(items: { id: string, parent: { id: string; [key: string]: any; }; [key: string]: any; }[]) {
	// Find the root items first and add them to the groupedItems array.
	let rootItems = items.filter(item => !item.parent);

	// Recursively find all of the children of the root items and add them to the children property of the parent item.
	let findChildren = (parentItem: any) => {
		let children = items.filter(item => item.parent?.id === parentItem.id);
		if (children.length) {
			return children.map(child => {
				let children = findChildren(child);

				if (children) {
					return {
						...child,
						children: children
					}
				} else {
					return child;
				}
			});
		} else {
			return undefined;
		}
	};

	rootItems = rootItems.map(item => {
		let children = findChildren(item);

		if (children) {
			return {
				...item,
				children: children
			}
		} else {
			return item;
		}
	});

	console.log('rootItems with children', rootItems);

	return rootItems;
}
