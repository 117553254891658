import { Business } from '../business/business.entity';
import { PublicTag, Tag } from './tag.entity';
import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

export type PublicBusinessesTag = Pick<BusinessesTag, 'id' | 'businessesId' | 'tagId'> & {
	tag?: PublicTag;
};
@Entity('businessTags')
export class BusinessesTag {
	constructor(value?: Partial<BusinessesTag>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}
	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@ManyToOne(
		() => Business,
		Business => Business.businessTags,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'businessesId' })
	public business: Business | Partial<Business>[];

	@Column('uuid')
	public businessesId: string;

	@Column('uuid', { nullable: false })
	public tagId: string;

	@ManyToOne(
		() => Tag,
		tag => tag.id,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'tagId' })
	public tag: Tag | Partial<Tag>;

	public toPublic(self: boolean = false) {
		let pub: Partial<PublicBusinessesTag> = {
			id: this.id,

			businessesId: this.businessesId,

			tagId: this.tagId
		};

		if (this.tag) {
			pub.tag = new Tag(this.tag).toPublic();
		}

		return pub as PublicBusinessesTag;
	}
}
