import {DialogButton, flattenStrapiEntity, StrapiEntity, StrapiMedia, StrapiSingleResponse} from '../global/global.model';

export interface News {
	id: string;
	title: string;
	copy?: string;
	media?: StrapiSingleResponse<StrapiMedia>;
	content?: string;
	newsDate: Date;
	expirationDate?: Date;
	showPopup?: boolean;
	popupShowFrequency?: number;
	showOnList: boolean;
	popupTriggerUrl?: string;
	popupButtonsLeft?: DialogButton[];
	popupButtonsRight?: DialogButton[];
	popupSize?: 'xlg' | 'lg' | 'md' | 'sm';
	popupBackgroundColor?: 'default' | 'alternative' | 'white';
	popupCustomCondition?: 'businessExternalPaymentMethodSetup' ;
	isFeatured: boolean;
}

export function createNews(strapiEntity: StrapiEntity<News>) {
	return flattenStrapiEntity(strapiEntity);
}
