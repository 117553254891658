import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Clip } from './clip.model';

export interface ClipState extends EntityState<Clip> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'clip'
})
export class ClipStore extends EntityStore<ClipState> {
	constructor() {
		super();
	}
}
