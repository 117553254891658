<div *ngIf="tools.includes(ImageActionType.TextToImage)" class="group mt-3 mb-3">

	<ng-container *ngIf="config.dataSource.config.models?.length">
		<app-select-input
			class="block mb-3"
			label="Model"
			i18n-label="Model@@imageToolImageGenerationModelLabel"
			[options]="config.dataSource.config.models"
			[parentForm]="textToImageForm"
			controlName="model"
		></app-select-input>
	</ng-container>

	<app-textarea-input
		class="block mb-3"
		label="Text to image Prompt"
		i18n-label="Text to image Prompt@@imageToolImageGenerationTextToImagePromptLabel"
		placeholder="Enter a prompt of what you want to generate."
		i18n-placeholder="Text to image Prompt Placeholder@@imageToolImageGenerationTextToImagePromptPlaceholder"
		[parentForm]="textToImageForm"
		[controlName]="'prompt'"
	></app-textarea-input>

	<app-select-input
		*ngIf="textToImageForm.get('aspect_ratio')"
		class="block mb-3"
		label="Aspect Ratio"
		i18n-label="Aspect Ratio@@imageToolImageGenerationAspectRatioLabel"
		[options]="aspectRatios"
		[parentForm]="textToImageForm"
		[controlName]="'aspect_ratio'"
	></app-select-input>

	<button mat-flat-button class="block with-loading mt-3" color="primary" (click)="replaceBackground()" [disabled]="loadingAction === ImageActionType.TextToImage">
		<span class="text" i18n="Generate Button@@imageToolImageGenerationGenerateButtonLabel">Generate</span>
		<span class="spinner"><mat-spinner diameter="20" color="accent"></mat-spinner></span>
	</button>
</div>
