import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'localizeWithLocMap'
})
export class LocalizeWithLocMapPipe implements PipeTransform {
	transform(input: string, localizationMap: {[key: string]: any}): string {
		const translation = localizationMap?.[input?.replace(/\./g, "").toLowerCase()];

		if (translation) {
			return translation;
		}
		return input;
	}
}
