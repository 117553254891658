import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalQuery } from '../global';
import { environment } from '../../../../environments/environment';
import { NewsStore } from './news.store';
import {StrapiCollectionResponse} from '../global/global.model';
import {map, tap} from 'rxjs/operators';
import {createNews, News} from './news.model';
import {Profile} from '../session';
import {CookiesUtils} from '../../utils/cookies.utils';
import {SessionService} from '../session/session.service';
import qs from 'qs';
import {Time} from '../../utils/time.utils';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NewsService {
	private readonly NEWS_STORAGE_KEY = 'modularVideoNews';
	constructor(
		private readonly newsStore: NewsStore,
		private readonly sessionService: SessionService,
		private readonly http: HttpClient,
	) {}


		get() {

		const now = new Date().toISOString();
			const query = qs.stringify(
				{
					populate: "*",
					filters: {
						locale: 'en', // get cms locale
						newsDate :  {
							'$lte': now
						},
						expirationDate :  {
							'$gte': now
						}
					}
				}
			);

		return this.http.get<StrapiCollectionResponse<News>>(`${environment.cmsUrl}/api/news-list?${query}`, {
			headers: {
				Authorization: `Bearer ${environment.cmsToken}`
			}
		}).pipe(
			map(response => response.data?.map(newsEntity => createNews(newsEntity)) || []),
			tap(news => this.newsStore.set(news))
		);

	}

	showPopupFilter(news: News, userProfile: Profile): boolean {
		// check if user dismissed news
		if (!news.showPopup || userProfile.settings?.dismissedNews?.includes(news.id)){
			return false;
		}

		// if modal should be shown based on frequency
		const frequency = news.popupShowFrequency || 0;

		if (frequency === 0){
			return false;
		}


		// if user hasn't seen news before
		const seenNews = this.getPopupViewedStatus(news.id, userProfile.id);

		if (!seenNews){
			return true;
		}

		const now = new Date();
		const seenNewsDate = new Date(seenNews);
		const seenNewsDays =  Math.ceil(Time.msToD(Math.abs(now.getTime() - seenNewsDate.getTime())));

		const frequencyTime = (new Date(news.expirationDate)).getTime() - (new Date(news.newsDate)).getTime() || 0;
		const frequencyDays = Math.ceil(Time.msToD(frequencyTime) / frequency);

		return seenNewsDays >= frequencyDays;

	}

	setPopupViewed(newsId: News['id'], userId: Profile['id']): void {

		const newsStatus = localStorage.getItem(this.NEWS_STORAGE_KEY);
		const newsStatusObj = newsStatus ? JSON.parse(newsStatus) : {};

		const userNewsStatus = newsStatusObj?.[userId] || {};

		userNewsStatus[newsId] = new Date().toISOString();

		localStorage.setItem(this.NEWS_STORAGE_KEY, JSON.stringify({
			...newsStatusObj,
			[userId]: userNewsStatus
		}));
	}

	getPopupViewedStatus(newsId: News['id'], userId: Profile['id']) {
		const newsStatus = localStorage.getItem(this.NEWS_STORAGE_KEY);

		return newsStatus ? JSON.parse(newsStatus)?.[userId]?.[newsId] : null;
	}

	setDismissedNews(newsId: News['id'], userProfile: Profile): Observable<any> {
		const dismissedNews = userProfile.settings?.dismissedNews || [];
		return this.sessionService.updateProfile({dismissedNews : Array.from(new Set([...dismissedNews, newsId]))});

	}
}
