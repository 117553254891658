import AWS, { S3 as AWSS3 } from 'aws-sdk';
import { Readable, Stream } from 'stream';
import { v4 as uuid } from 'uuid';
import { Request } from 'express';

export type NotificationEvent = {
	Records: {
		eventVersion: string;
		eventSource: 'aws:s3';
		awsRegion: string;
		eventTime: string;
		userIdentity: {
			principalId: string;
		};
		requestParameters: {
			sourceIPAddress: string;
		};
		responseElements: {
			'x-amz-request-id': string;
			'x-amz-id-2': string;
		};
		s3: {
			s3SchemaVersion: string;
			configurationId: string;
			bucket: {
				name: string;
				ownerIdentity: {
					principalId: string;
				};
				arn: string;
			};
			object: {
				key: string;
				size: number;
				eTag: string;
				sequencer: string;
			};
		};
	}[];
};

export class S3 {
	private static readonly isDebug = process.env.DEBUG || false;

	private static s3Config = {
		region: process.env.AWS_S3_REGION || process.env.AWS_REGION || '',
		bucketName: process.env.AWS_S3_BUCKET_NAME,
		accessKeyId: process.env.AWS_S3_ACCESS_KEY_ID || process.env.AWS_ACCESS_KEY_ID,
		secretAccessKey: process.env.AWS_S3_SECRET_ACCESS_KEY || process.env.AWS_SECRET_ACCESS_KEY
	};

	public static async upload(
		fileBuffer: Buffer,
		fileName: string,
		fileMime: string,
		folderName?: string,
		generateNewName?: boolean,
		acl = 'public-read'
	) {
		AWS.config.update({
			accessKeyId: this.s3Config.accessKeyId,
			secretAccessKey: this.s3Config.secretAccessKey
		});

		const s3 = new AWSS3();

		if (!folderName) {
			folderName = 'uploads/';
		} else if (!folderName.match(/\/$/)) {
			folderName = `${folderName}/`;
		}

		if (generateNewName) {
			fileName = this.generateFileName(fileName);
		}

		const file: AWS.S3.PutObjectRequest = {
			ACL: acl,
			Bucket: this.s3Config.bucketName,
			Key: folderName + fileName,
			Body: fileBuffer,
			ContentType: fileMime
		};

		const response = await s3
			.putObject(file)
			.promise()
			.catch(err => {
				if (this.isDebug) {
					console.log(err);
				}
				return false;
			});

		if (response) {
			if (this.isDebug) {
				console.log('File uploaded to S3.');
			}
			return {
				status: 'success',
				message: 'File uploaded to s3.',
				path: 'https://' + this.s3Config.bucketName + `.s3${this.s3Config.region?.length ? '.' + this.s3Config.region : ''}.amazonaws.com/` + folderName + fileName
			};
		} else {
			throw 'Couldn\'t write file to s3...';
		}
	}

	public static async uploadStream(
		fileBuffer: Stream,
		fileName: string,
		fileMime: string,
		folderName?: string,
		generateNewName?: boolean
	) {
		AWS.config.update({
			accessKeyId: this.s3Config.accessKeyId,
			secretAccessKey: this.s3Config.secretAccessKey
		});

		const s3 = new AWSS3();

		if (!folderName) {
			folderName = 'uploads/';
		} else if (!folderName.match(/\/$/)) {
			folderName = `${folderName}/`;
		}

		if (generateNewName) {
			fileName = this.generateFileName(fileName);
		}

		let file = {
			ACL: 'public-read',
			Bucket: this.s3Config.bucketName,
			Key: folderName + fileName,
			Body: fileBuffer,
			ContentType: fileMime
		};

		let response = await s3
			.upload(file)
			.promise()
			.catch(err => {
				console.log(err);
				return false;
			});

		if (response) {
			console.log('File uploaded to S3.');
			return {
				status: 'succeeded',
				message: 'File uploaded to s3.',
				path: 'https://' + this.s3Config.bucketName + `.s3${this.s3Config.region?.length ? '.' + this.s3Config.region : ''}.amazonaws.com/` + folderName + fileName
			};
		} else {
			throw 'Couldn\'t write file to s3...';
		}
	}

	public static async getFileStream(fileKey: string, req?: Request): Promise<{ stream: Readable; contentType: string }> {
		const params: AWS.S3.GetObjectRequest = {
			Bucket: this.s3Config.bucketName,
			Key: fileKey
		};

		let s3 = new AWSS3({
			httpOptions: {
				timeout: 1200000 // 20min
			}
		});

		const headObjectResponse = await s3
			.headObject(params)
			.promise()
			.catch(err => {
				console.log(err);
				return null;
			});

		if (!headObjectResponse) {
			throw new Error(`Couldn't get file headers.`);
		}

		const contentType = headObjectResponse.ContentType || 'application/octet-stream';

		if (req?.headers?.range) {
			params.Range = req.headers.range;
		}

		const stream = s3.getObject(params).createReadStream();

		return { stream, contentType };
	}

	public static async remove(objects) {
		AWS.config.update({
			accessKeyId: this.s3Config.accessKeyId,
			secretAccessKey: this.s3Config.secretAccessKey
		});

		let params = {
			Bucket: this.s3Config.bucketName,
			Delete: {
				Objects: objects
			}
		};

		let s3 = new AWSS3();

		let response = await s3
			.deleteObjects(params)
			.promise()
			.catch(err => {
				console.log(err);
				return false;
			});

		if (response) {
			console.log('File(s) removed from S3.');
			return response;
		} else {
			throw 'Error removing file(s)...';
		}
	}

	public static async getContents(folderName: string, recursive: boolean = false, continuationToken?: string, progress: any[] = []) {
		AWS.config.update({
			accessKeyId: this.s3Config.accessKeyId,
			secretAccessKey: this.s3Config.secretAccessKey
		});

		if (!folderName) {
			folderName = 'uploads/';
		}

		let params: any = {
			Bucket: this.s3Config.bucketName,
			Prefix: folderName
		};

		if (continuationToken) {
			params.ContinuationToken = continuationToken;
		}

		let s3 = new AWSS3();

		let response = await s3
			.listObjectsV2(params)
			.promise()
			.catch(err => {
				console.log(err);
				return null;
			});

		if (response) {
			if (recursive) {
				if (!response.Contents) {
					response.Contents = [];
				}
				if (response.NextContinuationToken) {
					return this.getContents(folderName, true, response.NextContinuationToken, [...response.Contents, ...progress]);
				} else {
					response.Contents = [...response.Contents, ...progress];
					return response;
				}
			} else {
				return response;
			}
		} else {
			throw new Error('Error listing bucket contents...');
		}
	}

	private static generateFileName(originalName) {
		let segments = originalName.match(/(.*)\.(.*)$/);

		return uuid() + '.' + segments[2];
	}
}
