import {ArrayNotEmpty, IsArray, IsBoolean, IsDefined, IsNotEmpty, IsUUID, ValidateNested} from 'class-validator';
import {Type} from 'class-transformer';

export class ProjectCartItemsReviewDto {

	@ValidateNested({ each: true })
	@IsArray()
	@ArrayNotEmpty()
	@Type(() => ProjectCartItemReviewDto)
	items: ProjectCartItemReviewDto[];

}


export class ProjectCartItemReviewDto {

	@IsDefined()
	@IsNotEmpty()
	@IsUUID()
	cartItemId: string;

	@IsDefined()
	@IsBoolean()
	approved: boolean;

}
