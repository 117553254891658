import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "../../../environments/environment";
import { CloudinaryTransformPipe } from "./cloudinary-transform.pipe";


@Pipe({name: "cloudinaryVideoTransform"})
export class CloudinaryVideoTransformPipe extends CloudinaryTransformPipe implements PipeTransform {

  type: "video" | "image" = "video";


}