import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {
  transform<T>(array: T[], filterFn: (element: T, ...args: any[]) => boolean, ...params: any[]): T[] {
    if (!array?.length) {
      return array;
    }


    return array.filter(value => filterFn(value, ...params));


  }
}
