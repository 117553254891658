import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { LocaleDto } from '../../../../../../../api/src/creative-unit-package/models/locale.dto';

@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputComponent extends BaseInputComponent implements OnInit {

	@Input() fullWidth: boolean = false;
	@Input() darkMode: boolean = false;
	@Input() prefix: string;
	@Input() minLength: number;
	@Input() maxLength: number;
	@Input() showLocale: boolean = false;

	@Input() localeData: LocaleDto;
	@Output() localeChange = new EventEmitter<string>();

	constructor() {
		super();
	}

}
