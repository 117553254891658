import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayOrDefault'
})
export class ArrayOrDefaultPipe implements PipeTransform {

  transform(value: any[], defaultValue: any = null): any[] | any {
    if (!value || value.length === 0) {
      return defaultValue;
    }

    return value;
  }

}

