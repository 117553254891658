import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { StoryFormat } from './story-format.model';

export interface StoryFormatState extends EntityState<StoryFormat>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'story-format', resettable: true })
export class StoryFormatStore extends EntityStore<StoryFormatState> {
	constructor() {
		super();
	}
}
