import { Locale } from '../../_core/models';
import { Field } from '../../_core/fraud-prevention/modules/forms/models';

export enum ExportActionType {
	Email = 'email',
	// DV360 = 'dv360',
}

export class Translation {
	name: string;
	description: string;
	entityName: string;
	entityNamePlural: string;
	// Note, groups are not supported yet.
	form: Pick<Field, 'slug' | 'displayName' | 'placeholderValue'>[];
}

export type Translations = {
	[locale in Locale]?: Translation;
}

export class ExportActionConfig {

}

export class ExportActionAdminConfig {

}
