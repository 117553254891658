import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgClass, NgIf } from "@angular/common";
import { CartItem } from "../../../_core/state/cart/cart.model";
import { CartService } from "../../../_core/state/cart/cart.service";

@Component({
  selector: 'app-item-review-status',
  standalone: true,
    imports: [
        MatIconModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        NgIf,
        NgClass
    ],
  templateUrl: './item-review-status.component.html',
  styleUrl: './item-review-status.component.scss'
})
export class ItemReviewStatusComponent {
    @Input() item: CartItem;
    @Input() showStatusText: boolean = true;
    @Input() showActions: boolean = true;
    @Input() actionInProgress: {group?: any, itemIds?: string[]} | null;

    @Output() onItemActionEmitter: EventEmitter<string> = new EventEmitter<string>();

    constructor(public readonly projectCartService: CartService) {
    }


    onItemAction(value: string) {
        this.onItemActionEmitter.emit(value)
    }
}
