import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NewsStore, NewsState } from './news.store';
import {NewsService} from './news.service';
import {Profile} from '../session';

@Injectable({ providedIn: 'root' })
export class NewsQuery extends QueryEntity<NewsState> {
	constructor(protected override store: NewsStore, private readonly newsService: NewsService) {
		super(store);
	}

	getFeatureNews() {
		return this.getAll({ filterBy: (entity) => entity.isFeatured });
	}

	getPopupNewsForPath(profile: Profile, path?: string) {
		const pathBasedPopups = this.getAll({ filterBy: (entity) => entity.showPopup && !entity.popupCustomCondition && (path ? !entity.popupTriggerUrl || entity.popupTriggerUrl?.toLocaleLowerCase() === path.toLocaleLowerCase(): true) });
		return pathBasedPopups.filter(entity => this.newsService.showPopupFilter(entity, profile));
	}

	getPopupNewsForCustomCondition(profile: Profile) {
		const customConditionBasedPopups = this.getAll({ filterBy: (entity) => entity.showPopup && !!entity.popupCustomCondition});
		return customConditionBasedPopups.filter(entity => this.newsService.showPopupFilter(entity, profile));
	}

	getNewsList(limitTo?: number) {
		return this.getAll({ filterBy: (entity) => entity.showOnList, limitTo });
	}

	getNextMessageFromQueue() {
		if (this.getValue()?.popupQueue?.length) {
			return this.getValue().popupQueue.shift();
		}

	}

	setPopupQueue(queue: {news: any, business: any}[]) {
		this.store.update({ popupQueue: queue });
	}
}
