import { IsOptional, IsString } from 'class-validator';
import { ConnectorConfig } from '../../../models';

export class ConnectorConfigCSV extends ConnectorConfig {
	/* Target should be the assetPath of a CSV asset. */
	@IsString()
	target: string;

	@IsOptional()
	@IsString()
	columnSeparator: string = ',';
}
