import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CartStore } from "./cart.store";
import {CartItem, CartItemGroup, CartItemType, ProjectCartItemDto, ProjectCartItemsReviewDto} from "./cart.model";
import {environment} from '../../../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {DefaultResponse} from '../session';
import {EncodeJobRequest, Project, ProjectsStore} from '../project';
import { of, take } from "rxjs";
import { CartJobDto } from "../../../../../../api/src/encode/models/job-def.dto";
import {CreativeUnitPackageQuery} from "../ad/ad-unit-package.query";
import { CartQuery } from "./cart.query";
import { Utils } from "../../../../../../api/src/project/project.utils";
import { StoryFormat } from "../story-format";
import { uniq, sortBy } from "lodash";

@Injectable({ providedIn: 'root' })
export class CartService {

	constructor(
		private cartStore: CartStore,
		private cartQuery: CartQuery,
		private projectStore: ProjectsStore,
		private creativeUnitPackageQuery: CreativeUnitPackageQuery,
		private http: HttpClient
	) {}

	public get(projectId: Project['id'], listOnly?: boolean) {
		return this.http.get<DefaultResponse<CartItem[]>>(`${environment.apiUrl}/projects/${projectId}/cart-items${(listOnly ? '/list': '')}`).pipe(
			map(response => response?.data),
			map(items => this.addPosition(items)),
			map(items => items.map(item => this.prepareForAkita(item))),
			tap(items => this.set(items))
		);
	}

	public getOne(projectId: Project['id'], cartItemId: CartItem['id'], dehydrated?: boolean) {
		return this.http.get<DefaultResponse<CartItem>>(`${environment.apiUrl}/projects/${projectId}/cart-items/${cartItemId}${(dehydrated ? '/dehydrated' : '')}`).pipe(
			map(response => response?.data),
			map(item => this.prepareForAkita(item)),
			tap(item => this.set([item]))
		);
	}

	public add(projectId: Project['id'], itemDto: ProjectCartItemDto, skipHttp: boolean = false) {
		if (skipHttp) {
			const dirtyItem = {...itemDto, dirty: true} as any;
			this.cartStore.add(this.prepareForAkita(dirtyItem as CartItem));
			return of(this.prepareForAkita(itemDto as CartItem));
		} else {
			return this.http.post<DefaultResponse<CartItem>>(`${environment.apiUrl}/projects/${projectId}/cart-items`,
				this.prepareForApi(itemDto as CartItem)
			).pipe(
				map(response => response?.data),
				tap(item => this.cartStore.add(this.prepareForAkita(item)))
			)
		}
	}

	public update(projectId: Project['id'],
				  id: CartItem['id'],
				  itemDto: Partial<ProjectCartItemDto>,
				  skipHttp: boolean = false,
				  updateStore: boolean = false) {
		if (skipHttp) {
			const dirtyItem = {...itemDto, dirty: true};
			return of(this.cartStore.update(id, dirtyItem));
		} else {
			return this.http.patch<DefaultResponse<CartItem>>(`${environment.apiUrl}/projects/${projectId}/cart-items/${id}`,
				this.prepareForApi(itemDto as CartItem)
			).pipe(
				map(response => response?.data),
			).pipe(
				tap(item => {
					if (updateStore) {
						this.cartStore.update(id, this.prepareForAkita(item))
					}
				})
			);
		}
	}

	public remove(projectId: Project['id'], id: CartItem['id'], skipHttp: boolean = false) {
		if (skipHttp) {
			return of(this.cartStore.remove(id));
		} else {
			return this.http.delete<DefaultResponse<{id: string}>>(`${environment.apiUrl}/projects/${projectId}/cart-items/${id}`,
			).pipe(
				map(response => response?.data),
				tap(item => {
					this.cartStore.remove(item.id);
					this.projectStore.update(projectId, project => ({
					...project,
					cartItems: project.cartItems?.filter(i => i.id !== item.id)
					}))
				})
			)
		}
	}

	public clear() {
		this.cartStore.set([]);
	}

	public getItemCheckoutTable(projectId: Project['id'], id: CartItem['id']) {
		return this.http.get(`${environment.apiUrl}/projects/${projectId}/cart-items/${id}/checkout-table`).toPromise();
	}

	public requestPreview(projectId: Project['id'], id: CartItem['id'], request: EncodeJobRequest) {
		return this.http.post(`${environment.apiUrl}/projects/${projectId}/cart-items/${id}/request-preview`, request);
	}

	public submitCartJob(projectId: Project['id'], request: CartJobDto) {
		return this.http.post(`${environment.apiUrl}/projects/${projectId}/submit-cart-job`, request);
	}

	public addReview(projectId: Project['id'], cartItemsReviewDtos: ProjectCartItemsReviewDto, skipHttp: boolean = false) {
		if (skipHttp) {
			return of(this.cartStore.update(cartItemsReviewDtos.items));
		} else {
			return this.http.post<DefaultResponse<CartItem[]>>(`${environment.apiUrl}/projects/${projectId}/cart-items/review`, cartItemsReviewDtos
			).pipe(
				map(response => response?.data),
				tap(items => {
					items = items.map(item => this.prepareCartItemWithPackage(item))
					this.cartStore.upsertMany(items?.map(item => this.prepareForAkita(item)))
				})
			)
		}
	}

	public set(items: CartItem[]) {
		this.cartStore.set(items?.map(item => {
			return this.prepareForAkita(this.prepareCartItemWithPackage(item));
		}));
	}

	public setActive(id: string) {
		this.cartStore.setActive(id);
	}

	public clearActive() {
		this.cartStore.setActive(null);
	}

	public prepareForAkita(item: CartItem): CartItem {
		// Hygiene: Sync item ad unit values with the ad customizations.
		if (item.type !== CartItemType.AD_UNIT) return item;

		return {
			...item
			// itemData: CreativeUnitWithCustomizations.syncValuesWithCreativeUnit(item.itemData)
		}
	}

	public prepareForApi(item: CartItem): CartItem {
		return {
			...item,
			id: undefined,
			projectId: undefined
			// itemData: CreativeUnitWithCustomizations.syncValuesWithCustomizations(item.itemData)
		}
	}

	public convertItemsToGroups(items: CartItem[]): CartItemGroup[] {
		let groups: CartItemGroup[] = [];
		// Group items
		// Separate out videos into their own group for each item.
		items.forEach(item => {
			if (item.type === CartItemType.VIDEO) {
				groups.push(this.convertVideoItemToGroup(item));
			} else if (item.type === CartItemType.AD_UNIT) {
				// Check if a group already exists by the itemData packageId.
				const groupIndex = groups.findIndex(group => group.id === item.metadata.packageId);

				if (groupIndex === -1) {
					groups.push(this.convertCreativeUnitItemToGroup(item));
				} else {
					groups[groupIndex].items.push(item);
				}
			}
		});

		return groups;
	}

	convertVideoItemToGroup(item: CartItem): CartItemGroup {
		return {
			id: item.id,
			name: item.name,
			type: item.type,
			items: [item],
			thumbnailUrl: item.metadata?.thumbnailUrl,
			metadata: []
		};
	}

	convertCreativeUnitItemToGroup(item: CartItem): CartItemGroup {
		let thumbnailUrl: string | undefined;
		if (item.metadata.package?.thumbnail || item.metadata.thumbnail) {
			thumbnailUrl = `https://res.cloudinary.com/${environment.cloudinaryCloudName}/image/fetch/c_fill,h_100,w_100/` +
				(item.metadata.thumbnail?.assetPath || item.metadata.package?.thumbnail?.assetPath);
		}

		return {
			id: item.metadata.packageId,
			name: item.metadata.package?.name || item.metadata.packageId,
			type: item.type,
			parent: item.metadata.package,
			items: [item],
			thumbnailUrl,
			metadata: []
		}
	}

	getReviewStatus(approved?: boolean, short?: boolean): string {
		if (approved === true) {
			return $localize `:Cart Item Review Status@@cartItemReviewStatusApprovedText:Approved`;
		} else if (approved === false) {
			return $localize `:Cart Item Review Status@@cartItemReviewStatusRejectedText:Rejected`;
		} else {
			if (short) {
				return $localize `:Cart Item Review Status@@cartItemReviewStatusPendingText:Pending`;
			} else return $localize `:Cart Item Review Status@@cartItemReviewStatusPendingReviewText:Pending Review`;
		}
	}

	getReviewStatusIcon(approved?: boolean): string {
		if (approved === true) {
			return 'done';
		} else if (approved === false) {
			return 'close';
		} else return 'schedule';
	}

	getReviewStatusClass(approved?: boolean): string {
		if (approved === true) {
			return 'approved';
		} else if (approved === false) {
			return 'rejected';
		} else return 'pending';
	}

	getVideoStoryFormat(workspaceState: any) {
		return Utils.getProjectStoryFormatRaw(workspaceState);
	}

	getVideoFormatText(storyFormat: StoryFormat) {
		if (!storyFormat || !storyFormat.title){
			return;
		}

		return (storyFormat.description  || storyFormat.title)?.replace(/<br ?\/?>/g, ' ').trim()
	}

	getVideoResolution(storyFormat: StoryFormat) {
		if (!storyFormat || !storyFormat.canvasWidth || !storyFormat.canvasHeight){
			return;
		}

		return storyFormat.canvasWidth + 'x' + storyFormat.canvasHeight;
	}

	setCartItemsFromProjectId(projectId: string) {
		this.get(projectId).pipe(take(1)).subscribe();
	}

	private prepareCartItemWithPackage(item: CartItem) {
        let foundPackage;
        const packageId = item.itemData?.packageId || item.metadata?.packageId;
        if (packageId) {
            foundPackage = this.creativeUnitPackageQuery.getValue().entities[packageId];
        }
        return {
            ...item,
            itemData: {
                ...item.itemData,
                package: foundPackage
            },
            metadata: {
                ...item.metadata,
                package: foundPackage
            }
        };
    }


	private addPosition(items: CartItem[]): CartItem[] {
		let packageIds = uniq(items.map(i => i.itemData?.packageId).filter(id => !!id));
		for (let packageId of packageIds) {
			let position: number = 0;
			let groupedByPackage = items.filter(i => i.itemData.packageId == packageId);
			if (groupedByPackage[0].itemData.position != undefined) break;
			let sortedByDimensions = groupedByPackage.sort((a: CartItem, b: CartItem) => b.itemData.dimensions?.width - a.itemData.dimensions?.width);
			for (let sortedItem of sortedByDimensions) {
				let item = items.find(i => i.id == sortedItem.id);
				item.itemData.position = position;
				item["dirty"] = true;
				position++;
			}
		}

		return items;
	}
}
