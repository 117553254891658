<div class="dialog-header mb-5">
	<div class="dialog-title mb-1">
		<h1 class="title mb-1" mat-dialog-title>{{ newsToShow.title }}</h1>
		<mat-icon [mat-dialog-close]="null" class="cursor-pointer">close</mat-icon>
	</div>
	<div class="subtitle mb-4" *ngIf="subtitle" [innerHtml]="subtitle | safeHtml"></div>
	<div class="copy" *ngIf="newsToShow.copy" [innerHtml]="newsToShow.copy | safeHtml"></div>
</div>

<div mat-dialog-content class="dialog-content">
	<div  class="media">
		<app-asset-media [media]="newsToShow.media"></app-asset-media>
	</div>
	<div class="content p-1" [innerHtml]="newsToShow.content | safeHtml">
	</div>

</div>

<div mat-dialog-actions class="dialog-footer mt-8" *ngIf="newsToShow?.popupButtonsLeft?.length || newsToShow?.popupButtonsRight?.length">
	<div *ngFor="let buttons of [newsToShow?.popupButtonsLeft, newsToShow?.popupButtonsRight]">
		<button *ngFor="let button of buttons"
				mat-button
				color="{{button.color}}"
				[ngClass]="{'mat-raised-button' : button.type === 'raised', 'mat-stroked-button' : button.type === 'stroked', 'mat-flat-button':  button.type === 'flat', 'size-medium': button.size === 'medium', 'size-big': button.size === 'big'}"
				(click)="onButtonClick(button)"
		>
			{{button.caption}}
		</button>
	</div>
</div>
