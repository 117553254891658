import { IsOptional, IsString } from 'class-validator';
import { ConnectorConfig } from '../../../models';

export class ConnectorConfigPexels extends ConnectorConfig {
	/* Target is the default search term for Pexels to retrieve.  Leave blank to default to popular assets */
	@IsString()
	target: string;

	@IsOptional()
	@IsString()
	apiKey?: string;

	attribution?: string = `<a href="https://www.pexels.com">Assets provided by Pexels</a>`;
}
