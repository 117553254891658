import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaInputComponent extends BaseInputComponent implements OnInit {
	@Input() minRows: number = 6;
	@Input() maxRows: number = 20;
	@Input() maxLength: number;

	constructor() {
		super();
	}

}
