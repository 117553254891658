<ng-container [formGroup]="form">
	<mat-form-field class="no-underline no-hint" appearance="fill" [floatLabel]="floatLabel">
		<mat-label *ngIf="label">{{ label }}</mat-label>
		<textarea
			matInput
			cdkTextareaAutosize
			[disabled]="isDisabled"
			[name]="controlName"
			[formControlName]="controlName"
			[placeholder]="placeholder"
			[cdkAutosizeMinRows]="minRows"
			[cdkAutosizeMaxRows]="maxRows"
			[maxlength]="maxLength"
		></textarea>
	</mat-form-field>

	<div *ngIf="hint" class="input-hint">{{ hint }}</div>
</ng-container>
