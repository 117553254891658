<app-select-input
	*ngIf="filteredToolOptions.length > 1"
	class="tool-select"
	[options]="filteredToolOptions"
	[currentValue]="activeToolOption"
	label="Tools"
	[fullWidth]="true"
	(valueChange)="setActiveTool($event)"
></app-select-input>

<div class="tool-container" [class.mt-4]="filteredToolOptions.length > 1">

	<ng-container [ngSwitch]="activeToolOption?.id">

		<ng-container *ngSwitchCase="'background'">
			<app-image-tool-background
				[activeAsset]="activeAsset"
				[tools]="activeToolOption?.tools"
				(setActiveAsset)="setActiveAsset.emit($event)"
				(setActiveVariations)="setActiveVariations.emit($event)"
			></app-image-tool-background>
		</ng-container>

		<ng-container *ngSwitchCase="'text-to-image'">
			<app-image-tool-image-generation
				[activeAsset]="activeAsset"
				[tools]="activeToolOption?.tools"
				[config]="config"
				(setActiveAsset)="setActiveAsset.emit($event)"
				(setActiveVariations)="setActiveVariations.emit($event)"
			></app-image-tool-image-generation>
		</ng-container>

		<ng-container *ngSwitchCase="'target-crop'">
			<app-image-tool-crop
				[targetCropConfig]="config.targetCropConfig"
				(targetCropConfigChange)="setConfigValue({ targetCropConfig: $event })"
			></app-image-tool-crop>
		</ng-container>

	</ng-container>

</div>
