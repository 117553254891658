import {Pipe, PipeTransform} from '@angular/core';
import {Project} from '../../_core/state/project';
import {CartItem, CartItemType} from '../../_core/state/cart/cart.model';


/**
 * Create poster url for project or cart item
 */

@Pipe({
	name: 'poster'
})
export class ProjectPosterPipe implements PipeTransform {
	transform(item: Project | CartItem, segmentId?: number, defaultPoster?: string): string {

		if (!item) {
			return defaultPoster;
		}

		const workspaceState = 'workspaceState' in item ? item.workspaceState : item.itemData;

		if (!workspaceState) {
			return defaultPoster;
		}

		if (item instanceof CartItem) {
			if (item.type === CartItemType.AD_UNIT){
				return workspaceState?.package?.thumbnail?.assetPath || defaultPoster;
			}
		}

		//video

		if (!segmentId) {
			segmentId = workspaceState?.['UI/story-segment']?.ids?.[0] || 1
		}

		const poster = workspaceState?.['UI/story-segment']?.entities?.[segmentId]?.activeClip?.video?.poster;

		return poster || defaultPoster;
	}

}
