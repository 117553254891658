import { IsArray, IsBoolean, IsNotEmpty, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { CustomizationOptionLayerDto } from '../layer/customization-option-layer.dto';
import { VisibilityConditionDto } from '../visibility-condition.dto';
import { LocaleDto } from '../locale.dto';

/**
 * CustomizationOptionMappingDto
 * Contains the logic to map a customization option to a layer or a set of layers.
 */
export class CustomizationOptionMappingDto {
	@IsOptional()
	@ValidateNested({ each: true })
	@IsArray()
	@Type(() => CustomizationOptionLayerDto)
	layers?: CustomizationOptionLayerDto[];

	@IsOptional()
	@ValidateNested()
	@Type(() => CustomizationOptionLayerDto)
	layer?: CustomizationOptionLayerDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => CustomizationOptionDto)
	creativeUnits?: CustomizationOptionDto[];

	@IsOptional()
	@IsObject()
	variables?: object;

	@IsOptional()
	@IsObject()
	queryParams?: object;

	@IsOptional()
	@IsString()
	styles?: string;

	@ValidateNested({ each: true })
	@IsArray()
	@IsOptional()
	@Type(() => VisibilityConditionDto)
	visibilityConditions?: VisibilityConditionDto[];

	public static flattenOptionLayers(customization: CustomizationOptionDto) {
		const layers = [];
		if (customization?.layer) {
			layers.push(customization.layer);
		}
		if (customization?.layers?.length > 0) {
			customization.layers.forEach(l => {
				layers.push(l);
			})
		}
		return layers;
	}
}

export class CustomizationOptionDto extends CustomizationOptionMappingDto {
	@IsString()
	id: string;

	@IsString()
	@IsOptional()
	name?: string;

	@IsString()
	label: string;

	@IsNotEmpty()
	value: string | boolean;

	@IsOptional()
	creativeUnitId?: string; // Used to reference a creative unit.

	@IsBoolean()
	@IsOptional()
	resetStylesToDefault?: boolean;

	@IsOptional()
	locale?: LocaleDto;
}
