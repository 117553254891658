import { Pipe, PipeTransform } from '@angular/core';
import markdownit from 'markdown-it'
@Pipe({
	name: 'markdown'
})
export class MarkDownPipe implements PipeTransform {
	transform(value: string): string {
		const md = markdownit();
		return md.render(value);
	}
}
