import { Component, Inject, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PublicExportAction } from '../../../../../../../../api/src/export-action/export-action.entity';
import { DataSourceService } from '../../../../../_core/state/data-source/data-source.service';
import { SelectedExportActionEntity } from '../export-destinations.component';
import { Field, SelectOption } from '../../../../../../../../api/src/_core/fraud-prevention/modules/forms/models';
import { map } from 'rxjs/operators';
import { CreativeUnitPackageCategoryService } from '../../../../../_core/state/creative-unit-package-category/creative-unit-package-category.service';
import { PublicCreativeUnitPackageCategory } from '../../../../../../../../api/src/creative-unit-package-category/creative-unit-package-category.entity';

export interface ExportDestinationsSettingsDialogData {
	exportDestination?: PublicExportAction | any;
	addedEntities: SelectedExportActionEntity[];
	allEntities: any[];

}

/**
 * Settings dialog for email export destination
 */

@Component({
	selector: 'app-export-destinations-settings-dialog',
	templateUrl: './export-destinations-settings-dialog.component.html',
	styleUrls: ['./export-destinations-settings-dialog.component.scss']
})
export class ExportDestinationsSettingsDialogComponent implements OnInit {
	readonly categoryDataSourceSlug = 'export-destinations-categories';
	readonly maxEntities = 1;

	public categories$: Observable<PublicCreativeUnitPackageCategory[]>;

	public form: UntypedFormGroup;
	public categoryFormControl = new FormControl();
	public categoryField: Field;
	public selectedCategory: { name: string, value: string };


	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		public dialogRef: MatDialogRef<ExportDestinationsSettingsDialogComponent>,
		readonly dataSourceService: DataSourceService,
		private readonly categoryService: CreativeUnitPackageCategoryService,
		@Inject(MAT_DIALOG_DATA) public data: ExportDestinationsSettingsDialogData,
	) {
		this._createForm();

		this.categoryField = data.exportDestination?.action.entityForm.find((field: Field) => field.slug === 'categories');

		this.categories$ = this.categoryService.get({ page: 1, limit: 1000, filters: {}, sort: { sortBy: 'created' } }).pipe(
			map((response) => response.data));
	}


	ngOnInit(): void {

	}

	get customAddresses(): UntypedFormArray {
		return this.form.controls['customAddresses'] as UntypedFormArray;
	}

	submit() {
		const actionConfig = {comment: this.form.value.comment};
		const selectedPrinters = this.form.value.printers?.map(printer => ({...printer, enabled: true, added: false})) || [];
		const customAddresses = this.form.value.customAddresses.map(customAddress => {
			return {
				bottlerName: customAddress.bottlerName,
				contacts: customAddress.contacts,
				bottlerRegion: '-',
				enabled: true,
				added: true

			}
		}) || [];

		const entityConfig = [...selectedPrinters, ...customAddresses];

		if (!selectedPrinters.length && !customAddresses.length) {
			return; // No printers selected
		}


		this.dialogRef.close({ actionConfig, entityConfig});


	}

	addCustomAddress() {
		if (!this.customAddresses) {
			this.form.patchValue({customAddresses: []});
		}

		this.customAddresses?.push(this._createCustomAddresses({bottlerName: '', contacts: ''}));
	}


	removeCustomAddresses(index: number) {
		this.customAddresses.removeAt(index);
	}

	onDeselectPrinters(printer: any) {
		this.form.patchValue({
			printers: this.form.value.printers.filter((selectedPrinter) => selectedPrinter.contacts !== printer.contacts)
		});
	}



	getActionDetails(actionValue: string, suggestions: any[]) {
		return suggestions?.find(suggestion => suggestion.contacts === actionValue) || {contacts: actionValue};
	}

	printerCompare(printer1: any, printer2: any) {
		return printer1.contacts === printer2.contacts;
	}

	printerCategoryFilter(emailSuggestions: any, category: string) {
		if (!category){
			return true;
		}

		return emailSuggestions.categories.split(',').includes(category);
	}

	isPrinterOptionDisabled(option: any, selectedItems: any[], customItems: any[], maxItems = 1): boolean {
		return (selectedItems?.length || 0 + customItems?.length || 0) >= maxItems && !selectedItems.find(item => item.contacts === option.contacts);
	}

	private _createForm() {
		this.form = this.formBuilder.group({
			printers: [this.data?.addedEntities.filter(printer => !!printer.enabled && !printer.customAddress) || []],
			customAddresses: this.formBuilder.array(this.data?.addedEntities.filter(printer => printer.customAddress).map(({bottlerName, contacts}) => this._createCustomAddresses({contacts, bottlerName})) || []),
			comment: [this.data.exportDestination?.comment || ''],
		});

	}

	private _createCustomAddresses({bottlerName, contacts}) {
		return this.formBuilder.group({
			bottlerName: [bottlerName],
			contacts: [contacts, [Validators.required, Validators.email]]
		})
	}

}
