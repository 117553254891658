import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { News, NewsService } from '../../../_core/state/news';
import { DialogButton } from '../../../_core/state/global/global.model';
import { Router } from '@angular/router';
import { SessionQuery } from '../../../_core/state/session';
import { first } from 'rxjs';
import {NewsPopupDialogDataModel} from './news-popup-dialog-data.model';
import {PublicBusiness} from '../../../../../../api/src/business/business.entity';

@Component({
	selector: 'app-news-popup-dialog',
	templateUrl: './news-popup-dialog.component.html',
	styleUrls: ['./news-popup-dialog.component.scss']
})
export class NewsPopupDialogComponent implements OnInit {

	public newsToShow: News;
	public business: PublicBusiness;
	public subtitle: string;
	constructor(
		public dialogRef: MatDialogRef<NewsPopupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: NewsPopupDialogDataModel,
		private readonly router: Router,
		private readonly newsService: NewsService,
		private readonly sessionQuery: SessionQuery
	) {
		this.newsToShow = data.news;
		this.business = data.business;

		const userProfile = sessionQuery.getProfile();

		if (userProfile?.id && data.news.id) {
			newsService.setPopupViewed(data.news.id, userProfile.id);
		}

		// custom subtitle for specific news
		if (data.news.popupCustomCondition === 'businessExternalPaymentMethodSetup' && userProfile.businesses.length > 1 && data.business?.name) {
			this.subtitle = `Onboard ${data.business.name}`;
		}
	}

	ngOnInit() {}

	onButtonClick(button: DialogButton): void {
		switch (button.action) {
			case 'close':
				//just close the modal
				this.dialogRef.close(button.action);
				break;
			case 'more':
				// add when we will have news page
				this._dismissNewsPopup(button.action);
				break;
			case 'dismiss':
				this._dismissNewsPopup(button.action);
				break;
			case 'openLink':
				if (button.actionParameter) {
					this.router.navigateByUrl(button.actionParameter);
				}
				this._dismissNewsPopup(button.action);
				break;
			case 'openLinkNewTab':
				if (button.actionParameter) {
					window.open(button.actionParameter, '_blank');
				}
				this._dismissNewsPopup(button.action);
				break;

			case 'openPaymentMethodSetup':
				const business = this.business || this.sessionQuery.getBusinesses()?.[0];

				if (business?.id) {
					if (business.hasActiveSubscription === false) {
						this.router.navigate(['/setup-subscription/' + business.id], {
							replaceUrl: true
						});
					} else {
						this.router.navigate([`/settings/business/${business.id}/billing`], {
							queryParams: {ref: 'external-onboarding'}
						});
					}
				}

				this._dismissNewsPopup(button.action);
				break;
		}
	}

	private _dismissNewsPopup(response?: string) {
		const profile = this.sessionQuery.getProfile();
		this.newsService
			.setDismissedNews(this.newsToShow.id, profile)
			.pipe(first())
			.subscribe(() => this.dialogRef.close(response));
	}
}
