import { CartItemType } from '../../../../../../api/src/cart/project-cart-item.entity';
import { Entity } from '../entities.model';
import { PublicEncodeJob } from '../../../../../../api/src/encode/encode-job.entity';
import { CreativeUnitWithCustomizationsDto } from '../../../../../../api/src/creative-unit-package/models/creative-unit/creative-unit.dto';
import { CreativeUnitPackageDto } from '../../../../../../api/src/creative-unit-package/models/creative-unit-package.dto';
import { LocaleDto } from '../../../../../../api/src/creative-unit-package/models/locale.dto';
import { ExportType } from "../../../../../../api/src/creative-unit-package/creative-unit-package.entity";

export { CartItemType, ProjectCartItem, PublicProjectCartItem } from '../../../../../../api/src/cart/project-cart-item.entity';
export { ProjectCartItemDto } from '../../../../../../api/src/cart/models/project-cart-item.dto';
export { ProjectCartItemReviewDto, ProjectCartItemsReviewDto } from '../../../../../../api/src/cart/models/project-cart-items-review.dto';

export class CartItem {
	id: string;
	name?: string;
	type: CartItemType;
	projectId: string;
	parentId?: string;
	itemData: CreativeUnitWithCustomizationsDto | any;
	metadata: Record<string, any>;
	encodeJob?: PublicEncodeJob;
	locale?: LocaleDto;
	approved?: boolean;
	acceptedCreativePackageTermsDate?: string;
}

export class CartItemGroup {
	id: string;
	name: string;
	type: CartItemType;
	parent?: CreativeUnitPackageDto;
	items: CartItem[];
	metadata: Entity[]; // An array of relevant metadata that should go in the subline.
	thumbnailUrl?: string;
}

export function getExportTypesFromGroup(group: CartItemGroup): ExportType[] {
	const types =  Array.from(new Set(group.items.map(item => item.metadata?.['exportTypes'])?.flat().filter(Boolean)));
	return (types.length ? types : undefined) || group.parent?.exportTypes || group.items[0].metadata?.['exportTypes'] || [ExportType.VIDEO];
}
