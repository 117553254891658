import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CreativeUnitPackageDto } from '../../../../../../api/src/creative-unit-package/models/creative-unit-package.dto';

export interface CreativeUnitPackageState extends EntityState<CreativeUnitPackageDto, string>, ActiveState {
	editor: {
		activeCreativeUnitId: string;
	}
}

const initialState = {
	editor: {
		activeCreativeUnitId: null
	}
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'ad'
})
export class CreativeUnitPackageStore extends EntityStore<CreativeUnitPackageState> {
	constructor() {
		super(initialState);
	}
}
