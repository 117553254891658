import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import {SessionQuery} from '../state/session';
import {GlobalService} from '../state/global';

export const AdEditorGuard: CanActivateFn = (route, state) => {
	const sessionQuery = inject(SessionQuery)
	const globalService = inject(GlobalService)
	const router = inject(Router);

	if (sessionQuery.isAtLeastSuperAdmin()) {
		return true;
	} else {
		if (sessionQuery.hasAdEditorPermission()) {
			return true;
		} else {
			router.navigate(['']);
			globalService.triggerCustomMessage($localize `:Error Message@@errorMessagePageAccessPermissionErrorText:You do not have permission to access this page`, 'danger');
			return false;
		}
	}

}
