import { Injectable } from '@angular/core';
import { Overlay } from './overlay.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface OverlayState extends EntityState<Overlay> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'overlay' })
export class OverlayStore extends EntityStore<OverlayState> {
	constructor() {
		super();
	}
}
