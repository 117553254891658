<ng-container *localVar="settings$ | async as settings">
	<div class="footer" *ngIf="settings?.showFooter">
		<div class="footer-details">
			<img [src]="settings.organizationLogo?.data?.attributes?.url" class="logo" alt="Logo" height="24" width="24" />
			<span>{{ settings.footerCopyrightText }}</span>
		</div>
		<div class="footer-actions">
			<ng-container *ngFor="let link of settings.footerLinks; let isLast = last">
				<mat-icon *ngIf="link.icon" [class]="link.cssClass">{{ link.icon }}</mat-icon>
				<a *ngIf="link.url" [class]="link.cssClass" [href]="link.url" [target]="link.linkTarget || ''" [innerHTML]="link.label | markdown"></a>
				<u *ngIf="!link.url" [class]="link.cssClass" (click)="$event.stopPropagation(); $event.preventDefault(); onFooterLinkAction(link.action, settings)" [innerHTML]="link.label | markdown"></u>
			<span *ngIf="!isLast">|</span>
			</ng-container>
		</div>
	</div>
</ng-container>
