import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Project } from './project.model';
import { PublicEncodeJob } from '../../../../../../api/src/encode/encode-job.entity';

export interface ProjectsState extends EntityState<Project>, ActiveState {
	activeEncodeJob: PublicEncodeJob;
}

const initialState = {
	active: null,
	activeEncodeJob: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'projects'
})
export class ProjectsStore extends EntityStore<ProjectsState> {
	constructor() {
		super(initialState);
	}
}
