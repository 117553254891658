import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState, EntityUIStore } from '@datorama/akita';
import { Story, StoryUI } from './story.model';

export interface StoryState extends EntityState<Story>, ActiveState {}
export interface StoryUIState extends EntityState<StoryUI> {}

const initialState = {
	active: null
};

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'story'
})
export class StoryStore extends EntityStore<StoryState> {
	ui!: EntityUIStore<StoryUIState>;

	constructor() {
		super(initialState);
		this.createUIStore().setInitialEntityState({ overlays: [] });
	}
}
