<div class="group mt-3 mb-3">
	<app-text-input
		class="block mb-3"
		label="Target X"
		[currentValue]="form.value.target.x"
		(valueChange)="form.patchValue({ target: { x: $event } })"
	></app-text-input>

	<app-text-input
		class="block mb-3"
		label="Target Y"
		[currentValue]="form.value.target.y"
		(valueChange)="form.patchValue({ target: { y: $event } })"
	></app-text-input>

	<app-text-input
		class="block mb-3"
		label="Target Width"
		[currentValue]="form.value.target.width"
		(valueChange)="form.patchValue({ target: { width: $event } })"
	></app-text-input>

	<app-text-input
		class="block mb-3"
		label="Target Height"
		[currentValue]="form.value.target.height"
		(valueChange)="form.patchValue({ target: { height: $event } })"
	></app-text-input>
</div>
