import { Pipe, PipeTransform } from '@angular/core';
import { environment } from "../../../environments/environment";
import { CurrencyPipe } from "@angular/common";

@Pipe({
  name: 'currencyEnv'
})
export class CurrencyEnvPipe implements PipeTransform {
	transform(value: string | number, length?: 'code' | 'symbol' | 'symbol-narrow' | 'code-symbol'): string {
		let currencyPipe = new CurrencyPipe(environment.locale);

		if (length == 'code-symbol') {
			return currencyPipe.transform(value, environment.currencyCode) + ' ' + environment.currencyCode;
		}
		return currencyPipe.transform(value, environment.currencyCode, length);
	}
}

