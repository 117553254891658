import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CartItem } from './cart.model';

export interface CartState extends EntityState<CartItem, string>, ActiveState {

}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'cart-item'
})
export class CartStore extends EntityStore<CartState> {
	constructor() {
		super();
	}
}
