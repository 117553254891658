import { IsOptional, IsString } from 'class-validator';
import { ConnectorConfig } from '../../../models';

export class ConnectorConfigCloudinary extends ConnectorConfig {
	/* Target should be the path structure for Cloudinary to a particular folder. */
	@IsString()
	target: string;

	@IsOptional()
	@IsString()
	environment?: string;

	@IsOptional()
	@IsString()
	accessToken?: string;

	// Other properties specific to Cloudinary...
}
