import { Type } from "class-transformer";
import { IsBoolean, IsOptional, ValidateNested } from "class-validator";

export class PrintBoxPropertiesDto {
	llx: number; // lower left x in millimeters
	lly: number; // lower left y in millimeters
	urx: number; // upper right x in millimeters
	ury: number; // upper right y in millimeters
}

export class ColorMappingItemDto {
	/**
	 * Hex color code is the key, CMYK color code is the value.
	 * Example: { '#ff0000': '0,100,100,0' }
	 */
	[hexColor: string | number]: string;
}


export class PrintConfigDto {
	// TODO: Should more strictly type this...
	@IsOptional()
	@IsBoolean()
	addMirroredBleeds?: boolean;

	@IsOptional()
	bleedBox?: PrintBoxPropertiesDto;

	@IsOptional()
	colorMapping?: ColorMappingItemDto;
}

export const defaultPrintConfig: PrintConfigDto = {
	bleedBox: {
		llx: 0,
		lly: 0,
		urx: 0,
		ury: 0
	},
	colorMapping: {}
};
