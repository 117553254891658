/**
 * NPM Modules
 */
import crypto, { Encoding } from 'crypto';
import bcrypt from 'bcrypt';

export enum CIPHERS {
	AES_128 = 'aes128',
	AES_128_CBC = 'aes-128-cbc',
	AES_192 = 'aes192',
	AES_256 = 'aes256'
}

export enum CIPHER_ENCODINGS {
	'ascii',
	'utf8',
	'hex',
	'base64'
}

export class Crypt {
	private static readonly bcryptSaltRounds = process.env.BCRYPT_SALT_ROUNDS || 11;

	/**
	 * Creates a cryptographically secure random number.
	 *
	 * @param bytes (optional) Number of bytes of randomness to generate
	 */
	public static random(bytes?: number): number {
		if (!bytes) {
			bytes = 32;
		}

		// Get bytes, convert to 32-bit int, divide by 32-bit max for 0-1 decimal.
		return crypto.randomBytes(bytes).readUInt32LE(0) / 0xffffffff;
	}

	/**
	 * Creates a cryptographically secure random hex string.
	 *
	 * @param bytes (optional) Number of bytes of randomness to generate.
	 */
	public static randomHex(bytes?: number): string {
		if (!bytes) {
			bytes = 32;
		}
		const buf = crypto.randomBytes(bytes);
		// Convert buffer to hex.
		return buf.toString('hex');
	}

	/**
	 * Creates a cryptographically secure random base64 string.
	 *
	 * @param bytes (optional) Number of bytes of randomness to generate.
	 */
	public static randomBase64(bytes?: number): string {
		if (!bytes) {
			bytes = 32;
		}
		const buf = crypto.randomBytes(bytes);
		// Convert buffer to base64.
		return buf.toString('base64');
	}

	public static encrypt(
		data: string,
		key: string,
		iv: string,
		algorithm: CIPHERS = CIPHERS.AES_256,
		encoding: Encoding = 'base64'
	): string {
		const cipher = crypto.createCipheriv(algorithm, Buffer.from(key, 'base64'), iv);

		return cipher.update(data, 'utf8', encoding) + cipher.final(encoding);
	}

	public static decrypt(
		data: string,
		key: string,
		iv: string,
		algorithm: CIPHERS = CIPHERS.AES_256,
		encoding: Encoding = 'base64'
	): string {
		const decipher = crypto.createDecipheriv(algorithm, Buffer.from(key, 'base64'), iv);

		return decipher.update(data, encoding) + decipher.final('utf8');
	}

	public static createSHA256Hash(...args: string[]): string {
		const sha = crypto.createHash('sha256');
		sha.update(args.join(''));
		return sha.digest('base64');
	}

	public static createHMACHash(secret: string, ...args: string[]): string {
		const hmac = crypto.createHmac('sha256', secret);
		hmac.update(args.join(''));
		return hmac.digest('base64');
	}

	public static async hashObject(data: Object, algorithm: string = 'sha256', stripNullAndEmpty: boolean = true) {
		const dataString = this.unwrapObjectData(data, stripNullAndEmpty);
		return crypto.createHash(algorithm).update(dataString).digest('hex');
	}

	private static unwrapObjectData(input: any, stripNullAndEmpty: boolean = true) {
		let output = '';
		if(Array.isArray(input)) {
			let values = [];
			for(const v of input) {
				let unwrapped = this.unwrapObjectData(v, stripNullAndEmpty);
				if(unwrapped?.length) {
					values.push(unwrapped);
				}
			}
			output = values.sort().join('');
		} else if(typeof input === 'string' && input.length) {
			output = input;
		} else if(typeof input === 'number' && !isNaN(input)) {
			output = input.toString();
		} else if(typeof input === 'boolean') {
			output = input.toString();
		} else if(input instanceof Date) {
			output = input.getTime().toString();
		} else if(typeof input === 'undefined' || input === null) {
			output = '';
		} else {
			let keys = Object.keys(input).sort();
			for(const k of keys) {
				let unwrapped = this.unwrapObjectData(input[k], true);
				if(unwrapped?.length) {
					output += k;
					output += unwrapped;
				}
			}
		}
		return output;
	}

	/**
	 * Generates a bcrypt hash for a given password.
	 * @param password The plain-text password to hash.
	 *
	 * @returns Hashed password.
	 */
	public static async hashPassword(password: string): Promise<string> {
		return bcrypt.hash(password, this.bcryptSaltRounds);
	}

	/**
	 * Checks to make sure a password matches a given bcrypt hash.
	 * @param password The plain-text password to match.
	 * @param hash A bcrypt hash to validate.
	 *
	 */
	public static async checkPassword(password: string, hash: string): Promise<boolean> {
		return bcrypt.compare(password, hash);
	}

	public static encryptObject<T>(value: T, scope?: string): string {
		if (!value || !process.env.PII_SIGNING_KEY || !process.env.PII_SIGNING_OFFSET) {
			return;
		}
		let stringValue;
		try {
			stringValue = JSON.stringify(value);
		} catch (err) {
			console.log(err);
			return;
		}
		try {
			stringValue = Crypt.encrypt(
				stringValue,
				Crypt.createSHA256Hash(process.env.PII_SIGNING_KEY, scope),
				process.env.PII_SIGNING_OFFSET
			);
		} catch (err) {
			console.log(err);
			return;
		}
		return stringValue;
	}

	public static decryptObject<T>(value: string, scope: string): T {
		if (!value || !process.env.PII_SIGNING_KEY || !process.env.PII_SIGNING_OFFSET) {
			return;
		}
		let parsedValue: T;
		try {
			parsedValue = JSON.parse(
				Crypt.decrypt(value, Crypt.createSHA256Hash(process.env.PII_SIGNING_KEY, scope), process.env.PII_SIGNING_OFFSET)
			);
		} catch (err) {
			if (process.env.DEBUG) {
				console.log(err);
			}
			return;
		}

		return parsedValue;
	}
}
