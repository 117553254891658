import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent extends BaseInputComponent implements OnInit {
	@Input() type: string;

	constructor() {
		super();
	}

}
