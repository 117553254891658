import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PageMeta, GlobalSettings } from './global.model';

export interface GlobalState {
	pages: PageMeta[];
	state: string;
	audioPlaying?: string;
	audioPreviewPlaying: boolean; // when the preview is playing
	dirtyTick?: string; // Check if the user has made any changes to the project
	editorVideoMuted: boolean; // Controls the mute state of the editor videos
	settings?: GlobalSettings;
	adminMode: boolean;
}

export function createInitialState(): GlobalState {
	return {
		pages: [
			{
				id: 'ad-studio',
				name: $localize `:Page name@@globalPageNameAdStudio:Select A Template`,
				visible: false
			},
			{
				id: 'get-started',
				name: $localize `:Page name@@globalPageNameGetStarted:Get Started`,
				visible: true
			},
			{
				id: 'editor',
				name: $localize `:Page name@@globalPageNameEditor:Edit Video`,
				visible: true
			},
			{
				id: 'music',
				name: $localize `:Page name@@globalPageNameMusic:Choose Audio`,
				visible: true,
				nextLabel: $localize `:Page Next Button Label@@globalPageNextButtonLabelSaveAndPreview:Save & Preview`
			},
			{
				id: 'review',
				name: $localize `:Page name@@globalPageNameReview:Review & Checkout`,
				visible: true,
				hideNext: true
			},
			{
				id: 'review-mode',
				name: $localize `:Page name@@globalPageNameReviewMode:Project Review`,
				visible: false,
				hideNext: false,
				nextLabel: $localize `:Page Next Button Label@@globalPageNextButtonLabelProceedToCheckout:Proceed to Checkout`
			},
			{
				id: 'checkout',
				name: $localize `:Page name@@globalPageNameCheckout:Review & Checkout`,
				visible: false,
				hideNext: true
			},
			{
				id: 'ad-studio-shim', // keep this here to keep classic project flow intact
				name: $localize `:Page name@@globalPageNameAdStudio:Select A Template`,
				visible: false
			},
			{
				id: 'download',
				name: $localize `:Page name@@globalPageNameDownload:Download Project`,
				visible: false,
				hideNext: true
			},
			{
				id: 'export',
				name: $localize `:Page name@@globalPageNameExport:Export Project`,
				visible: false,
				hideNext: true
			},

		],
		state: 'get-started',
		dirtyTick: undefined,
		audioPlaying: undefined,
		audioPreviewPlaying: false,
		editorVideoMuted: true,
		settings: undefined,
		adminMode: false
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'global' })
export class GlobalStore extends Store<GlobalState> {
	constructor() {
		super(createInitialState());
	}
}
