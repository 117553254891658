import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AILanguage } from "./language.model";

export interface LanguageState extends EntityState<AILanguage> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'language'
})
export class LanguageStore extends EntityStore<LanguageState> {
	constructor() {
		super();
	}
}
