import { StoryFormat } from "./workspace-state";


export enum VideoPreset {

  Preview = "Preview",
  FullHD = "FullHD",
  FullHDPreview = "FullHDPreview",
  UltraHD = "UltraHD",
  Tile1X1 = "Tile1X1",
  Tile4X5 = "Tile4X5",
  Tile9X16 = "Tile9X16"

}

export namespace VideoPreset {

  export function getFromStoryFormatDimensions(canvasWidth: number, canvasHeight: number) {
    if (canvasWidth === 1080) {
      switch (canvasHeight) {
        case 1080: return VideoPreset.Tile1X1;
        case 1350: return VideoPreset.Tile4X5;
        case 1920: return VideoPreset.Tile9X16;
      }
    }
    return VideoPreset.FullHD;

  }

  export function isTile(preset: string): boolean {
    return preset.startsWith("Tile");
  }

}