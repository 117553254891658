import { IsBoolean, IsOptional, IsString } from 'class-validator';
import { ConnectorConfig } from '../../../models';

export class ConnectorConfigBria extends ConnectorConfig {
	/* It's possible that someone copied a url into this so always parse to get the id. */
	@IsString()
	target: string;

	@IsBoolean()
	useBriaIframe?: boolean = false;

	@IsOptional()
	models?: { name: string; value: string; }[] = [
		{ name: 'Base 2.3', value: 'base-2.3' },
		{ name: 'Fast 2.3', value: 'fast-2.4' },
		{ name: 'HD 2.2', value: 'hd-2.2' },
	];

	supportedInputs?: string[] = [
		'model',
		'prompt',
		'aspect_ratio',
		'num_results',
		'medium',
	];

	aspectRatios?: string[] = [
		'3:2',
		'2:3',
		'1:1',
		'3:4',
		'4:3',
		'5:4',
		'9:16',
		'16:9'
	];
}
