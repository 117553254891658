import { InjectionToken, LOCALE_ID, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { Locale, LocaleCode } from './app/_core/models/locale';
import { registerLocaleData } from '@angular/common';
import { loadTranslations } from '@angular/localize';

// Import locales and extra data
// portuguese
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
// german
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
// french
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
// spanish
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';

import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';

import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';

import localeHr from '@angular/common/locales/hr';
import localeHrExtra from '@angular/common/locales/extra/hr';

// import localeSv from '@angular/common/locales/sv';
// import localeSvExtra from '@angular/common/locales/extra/sv';

// import localeNb from '@angular/common/locales/nb';
// import localeNbExtra from '@angular/common/locales/extra/nb';

// import localeFi from '@angular/common/locales/fi';
// import localeFiExtra from '@angular/common/locales/extra/fi';

// import localeDa from '@angular/common/locales/da';
// import localeDaExtra from '@angular/common/locales/extra/da';

import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';

import localeCs from '@angular/common/locales/cs';
import localeCsExtra from '@angular/common/locales/extra/cs';

import localeHu from '@angular/common/locales/hu';
import localeHuExtra from '@angular/common/locales/extra/hu';

import localeSk from '@angular/common/locales/sk';
import localeSkExtra from '@angular/common/locales/extra/sk';

import localeRo from '@angular/common/locales/ro';
import localeRoExtra from '@angular/common/locales/extra/ro';

import localeEl from '@angular/common/locales/el';
import localeElExtra from '@angular/common/locales/extra/el';

import localeBg from '@angular/common/locales/bg';
import localeBgExtra from '@angular/common/locales/extra/bg';

import localeSl from '@angular/common/locales/sl';
import localeSlExtra from '@angular/common/locales/extra/sl';

import localeSr from '@angular/common/locales/sr-Latn';
import localeSrExtra from '@angular/common/locales/extra/sr-Latn';

import localeHe from '@angular/common/locales/he';
import localeHeExtra from '@angular/common/locales/extra/he';

import localeTh from '@angular/common/locales/th';
import localeThExtra from '@angular/common/locales/extra/th';

import localeZhHk from '@angular/common/locales/zh';
import localeZhHkExtra from '@angular/common/locales/extra/zh';

import localeZhCn from '@angular/common/locales/zh';
import localeZhCnExtra from '@angular/common/locales/extra/zh';

import localeFrCa from '@angular/common/locales/fr';
import localeFrCaExtra from '@angular/common/locales/extra/fr-CA';

export const TRANSLATION_RECORDS = new InjectionToken<Record<string, string>>('TRANSLATION_RECORDS');

export class i18n {
	public static async getTranslationProviders(skipInstall = false) {
		let code = localStorage.getItem('localeCode');

		code = code as LocaleCode;

		if (!code) {
			return;
		}

		if (code === LocaleCode.enUS) {
			return [
				{
					provide: LOCALE_ID,
					useValue: code
				},
				{
					provide: TRANSLATION_RECORDS,
					useValue: {}
				}
			];
		}

		const translationFile: Record<string, string> = await fetch('assets/locale/messages.' + code + '.json')
			.then(response => response.text())
			.then(JSON.parse)
			.catch(() => null);

		if (!translationFile) {
			console.log('Translation file not found');
			return [
				{
					provide: LOCALE_ID,
					useValue: code
				},
				{
					provide: TRANSLATION_RECORDS,
					useValue: null
				}
			];
		}
		// Load the appropriate locale data.
		switch (code) {
			// case LocaleCode.ptBR:
			// registerLocaleData(localePt, code, localePtExtra);
			// break;
			// case LocaleCode.ptPT:
			// registerLocaleData(localePt, code, localePtExtra);
			// break;
			// case LocaleCode.deDE:
			// registerLocaleData(localeDe, code, localeDeExtra);
			// break;
			// case LocaleCode.frFR:
			// registerLocaleData(localeFr, code, localeFrExtra);
			// break;
			// case LocaleCode.esSP:
			// registerLocaleData(localeEs, code, localeEsExtra);
			// break;
			// case LocaleCode.esAR:
			// registerLocaleData(localeEs, code, localeEsExtra);
			// break;
			// case LocaleCode.itIT:
			// registerLocaleData(localeIt, code, localeItExtra);
			// break;
			// case LocaleCode.nlNL:
			// registerLocaleData(localeNl, code, localeNlExtra);
			// break;
			// case LocaleCode.hrHR:
			// registerLocaleData(localeHr, code, localeHrExtra);
			// break;
			// case LocaleCode.svSE:
			// registerLocaleData(localeSv, code, localeSvExtra);
			// break;
			// case LocaleCode.nbNO:
			// registerLocaleData(localeNb, code, localeNbExtra);
			// break;
			// case LocaleCode.fiFI:
			// registerLocaleData(localeFi, code, localeFiExtra);
			// break;
			// case LocaleCode.daDK:
			// registerLocaleData(localeDa, code, localeDaExtra);
			// break;
			// case LocaleCode.plPL:
			// registerLocaleData(localePl, code, localePlExtra);
			// break;
			// case LocaleCode.csCZ:
			// registerLocaleData(localeCs, code, localeCsExtra);
			// break;
			// case LocaleCode.huHU:
			// registerLocaleData(localeHu, code, localeHuExtra);
			// break;
			// case LocaleCode.skSK:
			// registerLocaleData(localeSk, code, localeSkExtra);
			// break;
			// case LocaleCode.roRO:
			// registerLocaleData(localeRo, code, localeRoExtra);
			// break;
			// case LocaleCode.elGR:
			// registerLocaleData(localeEl, code, localeElExtra);
			// break;
			// case LocaleCode.bgBG:
			// registerLocaleData(localeBg, code, localeBgExtra);
			// break;
			// case LocaleCode.slSI:
			// registerLocaleData(localeSl, code, localeSlExtra);
			// break;
			// case LocaleCode.srLatnRS:
			// registerLocaleData(localeSr, code, localeSrExtra);
			// break;
			// case LocaleCode.heIL:
			// registerLocaleData(localeHe, code, localeHeExtra);
			// break;
			// case LocaleCode.thTH:
			// registerLocaleData(localeTh, code, localeThExtra);
			// break;
			// case LocaleCode.zhHK:
			// 	registerLocaleData(localeZhHk, code, localeZhHkExtra);
			// 	break;
			case LocaleCode.frCA:
				registerLocaleData(localeFrCa, code, localeFrCaExtra);
				break;
			case LocaleCode.zhCN:
				registerLocaleData(localeZhCn, code, localeZhCnExtra);
				break;
			default:
				// Handle unsupported language codes
				console.error(`Unsupported locale code: ${code}`);
		}
		// Install translations.
		if (!skipInstall) {
			loadTranslations(translationFile);
		}

		return [
			{
				provide: LOCALE_ID,
				useValue: code
			},
			{
				provide: TRANSLATION_RECORDS,
				useValue: translationFile
			}
			// {
			// 	provide: TRANSLATIONS_FORMAT, useValue: 'xlf2',
			// },
			// {
			// 	provide: TRANSLATIONS, useValue: translationFile,
			// }
		];
	}
}
