import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '../state/session/session.service';
import { SessionQuery } from '../state/session';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../state/global';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	readonly UNSECURED_ROUTES = [
		'cms',
		'/auth/basic/generate-sign-in-code',
		'/auth/basic/code-sign-in',
		environment.cmsUrl,
		's3.amazonaws.com'
	];

	constructor(private readonly sessionQuery: SessionQuery, private readonly globalService: GlobalService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.isUnsecuredRoute(request.url)) {
			return next.handle(request);
		}

		return next.handle(this.setAccessToken(request, this.sessionQuery.getToken()));
	}

	private setAccessToken(request: any, accessToken: string | undefined) {
		// We clone the request, because the original request is immutable
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${accessToken}`,
				'Accept-Language': this.globalService.getLocale()
			}
		});
	}

	private isUnsecuredRoute(requestUrl: string): boolean {
		let match = false;
		this.UNSECURED_ROUTES.forEach(part => {
			if (requestUrl.indexOf(part) > -1) {
				match = true;
			}
		});
		return match;
	}
}
