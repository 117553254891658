import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, distinctUntilChanged, map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private refreshTrigger$ = new BehaviorSubject<void>(undefined);

  set(key: string, value: string) {
    localStorage.setItem(key, value);
    this.refreshTrigger$.next();
  }

  getObservable(key: string): Observable<string> {
    return this.refreshTrigger$.pipe(
      map(() => localStorage.getItem(key)),
      distinctUntilChanged(),
    );
  }
}
