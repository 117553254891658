import { flattenStrapiEntity, StrapiCollectionResponse, StrapiEntity } from '../global/global.model';

export interface Survey {
	id: string;
	questions: Question[];
	title: string;
}

export interface Question {
	id: string;
	answers: Answer[];
	instruction: string;
	questionType: string;
	title: string;
	value?: any;
}

export interface Answer {
	id: string;
	label: string;
	answerValue: string;
	filterCriteria: string;
	entries: any;
	entityType: string[];
	isFeatured?: boolean;
}

export function createSurvey(strapiEntity: StrapiEntity<Survey>, params?: Partial<Survey>) {
	const questions = strapiEntity.attributes.questions as unknown as StrapiCollectionResponse<Question>;

	return {
		...flattenStrapiEntity(strapiEntity),
		questions: questions?.data.map(question => createQuestion(question as any, params)) || [],
		...params
	} as Survey;
}

export function createQuestion(strapiEntity: StrapiEntity<Question>, params?: Partial<Question>) {
	return {
		...flattenStrapiEntity(strapiEntity),
		...params
	} as Question;
}
