<div class="content-group p-4" [formGroup]="form">
	<ng-container *ngIf="enableCustomExportFileNames">
		<div class="group-section" [ngClass]="{'mb-1': !enableCustomExportFileNames}">
			<div class="flex justify-space-between mb-2">
				<div>
					<div class="title" i18n="Section Title@@exportDestinationsExportNameTitle">Export name</div>
				</div>
			</div>

			<div class="flex align-center export-name">
				<mat-form-field class="no-underline form-field dark-color" floatLabel="never" appearance="fill">
					<mat-label i18n="Form Label@@exportDestinationsExportNameLabel">Project name & Date</mat-label>
					<mat-select formControlName="exportNamePrefix" (valueChange)="onPrefixChange($event.value)">
						<mat-option *ngFor="let option of PREFIX_SELECT_VALUES" value="option">{{ option.display }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="form-control form-field dark-color full-width flex-grow" appearance="fill">
					<mat-label i18n="Form Label@@exportDestinationsNameLabel">Name</mat-label>
					<input matInput formControlName="name"/>
				</mat-form-field>
			</div>
		</div>
	</ng-container>


	<ng-container *ngIf="enableExportActions">
		<div class="group-section">
			<div class="flex justify-space-between mb-2">
				<div class="title" i18n="Section Title@@exportDestinationsExportDestinationsTitle">Export destinations</div>

				<button mat-button class="size-small" (click)="resetSettings()">
					<mat-icon>refresh</mat-icon>
					<ng-container i18n="Button Label@@exportDestinationsResetButtonLabel">Reset</ng-container>
				</button>
			</div>


			<div class="flex justify-end mb-4" *localVar="(exportActions$ | async | filterArray : excludeAddedActions: addedExportActions) as exportDestinationToAdd">
				<button mat-button color="primary" class="size-small with-icons" [matMenuTriggerFor]="allExportActions" [disabled]="!exportDestinationToAdd?.length">
					<mat-icon>add</mat-icon>
					<ng-container i18n="Button Label@@exportDestinationsAddButtonLabel">Add</ng-container>
					<mat-icon class="ml-2">expand_more</mat-icon>
				</button>

				<mat-menu #allExportActions="matMenu">
					<div mat-menu-item *ngFor="let exportAction of exportDestinationToAdd" (click)="onAddExportAction(exportAction)">{{ exportAction.name }}</div>
				</mat-menu>
			</div>

			<div class="group-container" *ngIf="addedExportActions | arrayOrDefault else noActions">
				<div class="group flex" *ngFor="let exportAction of addedExportActions;trackBy:exportActionIdentify">
					<app-export-destinations-item
						class="full-width"
						[exportAction]="exportAction"
						(configChange)="onUpdateExportAction(exportAction, $event)"
					>
					</app-export-destinations-item>
				</div>
			</div>

			<ng-template #noActions>
				<div class="empty-value">
					<span i18n="Empty State Text@@exportDestinationsNoActionsText">No export destinations added</span>
				</div>
			</ng-template>
		</div>
	</ng-container>


	<div class="batch-items-container">


	</div>
</div>

