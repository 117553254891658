import { IsBoolean, IsOptional } from 'class-validator';
import { AssetDto } from '../../../asset/models/asset.dto';

export class LayerVideoDto extends AssetDto {
	@IsOptional()
	@IsBoolean()
	muted?: boolean;

	@IsOptional()
	@IsBoolean()
	autoPlay?: boolean;

	@IsOptional()
	@IsBoolean()
	loop?: boolean;
}
