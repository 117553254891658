import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {map, tap} from 'rxjs/operators';
import { ImageActionDto } from '../../../../../api/src/asset/models/image-action.dto';
import { AssetDto } from "../../../../../api/src/asset/models/asset.dto";
import { DefaultResponse } from "../../_core/state/session";
import { environment } from "../../../environments/environment";
import { DataSourceGenerateDto } from "../../../../../api/src/data-source/dtos/data-source-generate.dto";

@Injectable({ providedIn: 'root' })
export class ImageEditorService {

	constructor(
		private http: HttpClient
	) {}

	public generate(dataSourceId: string, params: DataSourceGenerateDto) {
		return this.http.post<DefaultResponse<Partial<AssetDto>[]>>(`${environment.apiUrl}/data-source/${dataSourceId}/generate`, params).pipe(
			map(response => response?.data)
		);
	}

	public executeAction(action: ImageActionDto<any>) {
		return this.http.post<DefaultResponse<any>>(`${environment.apiUrl}/asset/image/action`, action).pipe(
			map(response => response?.data)
		);
	}
}
