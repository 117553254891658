export enum FieldType {
	Group = 'group',
	Text = 'text',
	Textarea = 'textarea',
	Checkbox = 'checkbox',
	Select = 'select',
	Hidden = 'hidden',
	ReCaptcha = 'reCaptcha',
	Email = 'email',
	Phone = 'phone',
	State = 'state',
	Date = 'date',
	File = 'file',
	Category = 'category',
	Range = 'range'
}
