import { IsNumberString, IsOptional } from "class-validator";

export class TimingConfigDto {
	@IsOptional()
	@IsNumberString()
	startOffset?: string;

	@IsOptional()
	@IsNumberString()
	endOffset?: string;

	@IsOptional()
	@IsNumberString()
	duration?: string;
}
