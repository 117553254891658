import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SurveyStore, SurveyState } from './survey.store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Survey} from './survey.model';

@Injectable({ providedIn: 'root' })
export class SurveyQuery extends QueryEntity<SurveyState> {
	// Used to see if all the current surveys have valid question values
	areSurveysValid$: Observable<boolean> = this.selectAll().pipe(
		map(surveys => this.checkSurveysValid(surveys))
	);

	areSurveysValid(){
		return this.checkSurveysValid(this.getAll());
	}

	constructor(protected store: SurveyStore) {
		super(store);
	}



	private checkSurveysValid(surveys: Survey[]): boolean {
		if (!surveys?.length) {
			return false;
		}

		let valid = true;

		surveys?.forEach(survey => {
			survey.questions.forEach(question => {
				if (!question.value) {
					valid = false;
				}
			});
		});

		return valid;

	}
}
