import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import {News} from './news.model';
import {PublicBusiness} from '../../../../../../api/src/business/business.entity';

export interface NewsState extends EntityState<News> {
	popupQueue: {news: News, business: PublicBusiness}[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'news'
})
export class NewsStore extends EntityStore<NewsState> {
	constructor() {
		super();
	}
}
