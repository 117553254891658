import { Pipe, PipeTransform } from '@angular/core';
import { CartItemGroup } from "../../../../_core/state/cart/cart.model";

@Pipe({
  name: 'disableExportUnapprovedGroup'
})
export class DisableExportUnapprovedGroupPipe implements PipeTransform {

  transform(group: CartItemGroup, exportsRequireApproval: boolean): boolean {
	  return group.items.length > 0 && exportsRequireApproval && group.items.every(item => item.approved !== true);
  }

}
