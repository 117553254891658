import { Entity, Column, PrimaryGeneratedColumn, Unique, ManyToOne, JoinColumn, Index } from 'typeorm';
import { Site } from '../site/site.entity';

export enum AuthenticationStrategyType {
	Basic = 'basic',
	Okta = 'okta',
	SAML2_0 = 'saml2.0'
}

export enum OktaUiType {
	Redirect = 'redirect',
	Widget = 'widget'
}

export enum OktaStrategy {
	OpenIDConnect = 'openIDConnect',
	OAuth2 = 'oauth2',
	SAML = 'saml'
}

export class OktaConfig {
	clientId: string;
	oktaDomain: string;
	strategy: OktaStrategy;
	uiType: OktaUiType;
}

export class BasicConfig {
	codeLength: number;
	codeLifetime: string;
}

export class SAML2_0Config {
	loginURL: string;
	issuer: string;
	idpSigningCert: string;
	idpEncryptionCert?: string;
	spCert: string;
	// This will be encrypted for each org.
	spPKey: string;
	spDomain: string;
}

export class SAML2_0Challenge {
	userId: string;
	organizationId: string;
	nonce: string;
	host: string;
	expires: number;
}

export type PublicAuthenticationStrategy = Pick<AuthenticationStrategy, 'id' | 'name' | 'type'> & {};

@Entity('authenticationStrategies')
export class AuthenticationStrategy {
	constructor(value?: Partial<AuthenticationStrategy>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	name: string;

	@Column({
		type: 'enum',
		enum: AuthenticationStrategyType
	})
	type: AuthenticationStrategyType;

	@Column('jsonb', { nullable: false })
	config: OktaConfig | BasicConfig | SAML2_0Config;

	@Column('text')
	siteId: string;
	@ManyToOne(
		type => Site,
		site => site.authenticationStrategies,
		{
			//onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'siteId' })
	site: Site | Partial<Site>;

	public toPublic(): PublicAuthenticationStrategy {
		const pub: Partial<PublicAuthenticationStrategy> = {
			id: this.id,
			name: this.name,
			type: this.type
		};

		return pub as PublicAuthenticationStrategy;
	}
}
