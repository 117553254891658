import { Pipe, PipeTransform } from '@angular/core';
import { CartItemGroup } from "../../../../_core/state/cart/cart.model";

@Pipe({
  name: 'disableExportVideoPackage'
})
export class DisableExportVideoPackagePipe implements PipeTransform {

  transform(group: CartItemGroup): boolean {
	  return group.type == 'video' && !group?.items?.[0]?.encodeJob;
  }

}
