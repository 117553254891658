import { Injectable } from "@angular/core";
import {getEntityType, QueryEntity} from "@datorama/akita";
import { StoryFormatState, StoryFormatStore } from "./story-format.store";
import { StorySegmentQuery } from "../story-segment";
import { SessionQuery } from "../session";
import { MusicQuery } from "../music";
import {StoryFormat} from "./story-format.model";

@Injectable({ providedIn: 'root' })
export class StoryFormatQuery extends QueryEntity<StoryFormatState> {

	constructor(
		protected store: StoryFormatStore,
		protected storySegmentQuery: StorySegmentQuery,
		protected sessionQuery: SessionQuery,
		protected musicQuery: MusicQuery,
	) {
		super(store);
	}

	getActive(): StoryFormatState["active"] extends any[] ? getEntityType<StoryFormatState>[] : (getEntityType<StoryFormatState> | undefined) {
		let active: StoryFormat = super.getActive();
		if (!active) {
			active = this.getDefaultStoryFormat();
		}
		return active;
	}

	private getDefaultStoryFormat(): StoryFormat {
		return this.store.getValue().entities['1'];
	}
}
