<div *ngIf="label" class="label">{{ label }}</div>
<ng-container *ngFor="let child of children">
	<app-input
		[type]="child.type"
		[parentForm]="parentForm"
		[controlName]="child.controlName"
		[currentValue]="child.value"
		[validators]="child.validators"
		(valueChange)="changeValue($event)"
	></app-input>
</ng-container>
