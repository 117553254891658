import { User } from '../user/user.entity';
import { Entity, Column, PrimaryGeneratedColumn, OneToMany, ManyToOne, JoinColumn } from 'typeorm';
import { Business } from './business.entity';

export enum BusinessRole {
	Owner = 'owner',
	Administrator = 'administrator',
	User = 'user',
	Creative = 'creative',
	Finance = 'finance'
}

export const BusinessRolesCanCreateProject = [BusinessRole.Owner, BusinessRole.Administrator, BusinessRole.Creative, BusinessRole.User];

export const BusinessRoleMap = Object.entries(BusinessRole).reduce((acc, cur, idx) => {
	acc[cur[1]] = idx;
	return acc;
}, {});

export type PublicBusinessUser = {
	id: string;
	role: string;
	businessRole: string;
	isApprover: boolean;
	businessUserId: string;
	businessesId: string;
	canPurchase: boolean;
	adEditor: boolean;
};

@Entity('businessUsers')
export class BusinessUser {
	constructor(value?: Partial<BusinessUser>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	// role
	@Column({ type: 'enum', enum: BusinessRole, nullable: false, default: BusinessRole.User })
	public role: BusinessRole;

	//isApprover
	@Column('boolean', { nullable: false, default: false })
	public isApprover?: boolean;

	//canPurchase
	@Column('boolean', { nullable: false, default: false })
	public canPurchase?: boolean;

	// can use ad editor - adEditor
	@Column('boolean', { nullable: false, default: false })
	public adEditor?: boolean;

	//deactivated
	@Column('boolean', { nullable: true })
	public deactivated?: boolean;

	//internal
	@Column('boolean', { nullable: true, default: false })
	public internal?: boolean;

	@Column('uuid', { nullable: false })
	public usersId: string;

	@ManyToOne(
		() => User,
		user => user.userBusinesses,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'usersId' })
	public user: User | Partial<User>[];

	@Column('uuid', { nullable: false })
	public businessesId: string;

	@ManyToOne(
		() => Business,
		business => business.businessUsers,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'businessesId' })
	public business: Business | Partial<Business>;

	// role

	public toBusiness(): Business {
		return new Business({
			...this.business,
			isApprover: this.isApprover,
			role: this.role
		});
		// let bus: Partial<BusinessUser> = {
		// 	id: this.id,
		// 	role: this.role,
		// 	isApprover: this.isApprover,
		// 	deactivated: this.deactivated,
		// 	usersId: this.usersId
		// };

		// if (this.users?.length) {
		// 	bus.users = this.users.map(u => new User(u).toPublic());
		// }

		// return bus as BusinessUser;
	}

	public toUser(): User {
		return new User({
			...this.user,
			isApprover: this.isApprover,
			canPurchase: this.canPurchase,
			businessRole: this.role,
			adEditor: this.adEditor,
			deactivated: this.deactivated
		});
	}

	public toPublic(self: boolean = false) {
		let pub: Partial<BusinessUser> = {
			id: this.id,
			isApprover: this.isApprover,
			role: this.role,
			deactivated: this.deactivated,
			canPurchase: this.canPurchase,
			adEditor: this.adEditor,
			businessesId: this.businessesId,
			internal: this.internal
			// usersId: this.usersId,
			// name: this.name,
			// address: this.address,
			// phone: this.phone,
			// website: this.website,
			// billingAddress: this.billingAddress,
			// billingCity: this.billingCity,
			// billingState: this.billingState,
			// billingZip: this.billingZip,
			// modified: this.modified,
			// created: this.created
		};

		// if (this.users?.length) {
		// 	// pub.user = this.users.map(u => new User(u).toPublic());
		// }

		return pub as BusinessUser;
	}
}
