import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs';
import { Project, ProjectStatus } from './project.model';
import { ProjectsState, ProjectsStore } from './projects.store';
import { PublicEncodeJob } from '../../../../../../api/src/encode/encode-job.entity';
import { State } from '../../../../../../api/src/project/project.entity';

@Injectable({ providedIn: 'root' })
export class ProjectsQuery extends QueryEntity<ProjectsState> {
	draftProjects$ = this.selectAll({
		filterBy: project => project?.status === ProjectStatus.Draft
	});

	needsReviewProjects$ = this.selectAll({
		filterBy: project => project?.status === ProjectStatus.NeedsReview
	});

	approvedProjects$ = this.selectAll({
		filterBy: project => project?.status === ProjectStatus.Approved
	});

	finishedProjects$ = this.selectAll({
		filterBy: project => project?.status === ProjectStatus.Finished
	});

	activeEncodeJob$ = this.select(state => state.activeEncodeJob);

	completedPreviews$ = this.selectActive().pipe(map(project => this.getCompletedPreviews(project)));

	previews$ = this.selectActive().pipe(map(project => this.getPreviews(project)));

	hasActiveSubscription$ = this.selectActive().pipe(map(project => project.business?.hasActiveSubscription));

	validName$ = this.selectActive().pipe(map(project => !!project.name && project.name !== ''));

	constructor(protected override store: ProjectsStore) {
		super(store);
	}

	public getActiveEncodeJob() {
		return this.getValue().activeEncodeJob;
	}

	public getLatestEncodeJob(encodeJobs: PublicEncodeJob[]) {
		const finals = encodeJobs
			?.filter(job => job.tag === 'final')
			.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

		const previews = encodeJobs
			?.filter(job => job.tag === 'preview')
			.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

		return finals?.[0] || previews?.[0];
	}

	public isEncodeJobLatest(encodeJob: PublicEncodeJob) {
		if (!encodeJob) {
			return false;
		}

		const latestEncodeJob = this.getLatestEncodeJob(this.getActive()?.encodeJobs);
		return latestEncodeJob?.id === encodeJob?.id;
	}

	getPreviews(project: Project) {
		const previews = project?.encodeJobs
			?.filter(job => job.tag === 'preview')
			?.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());

		return previews;
	}

	getCompletedPreviews(project: Project) {
		const previews = project?.encodeJobs
			?.filter(job => job.tag === 'preview' && job.status === 'completed')
			?.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());

		return previews;
	}

	getEncodeJobIndex(encodeJob: PublicEncodeJob) {
		const previews = this.getCompletedPreviews(this.getActive());
		return previews?.findIndex(preview => preview.id === encodeJob.id);
	}

	public getLatestPreview(project: Project) {
		const previews = this.getPreviews(project)?.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
		return previews?.[0];
	}

	getEncodeJobPreview(encodeJobId: string, project: Project) {
		if (!encodeJobId) return null;
		const previews = this.getPreviews(project)
			.map(preview => {
				return preview;
			})
			.filter(preview => preview.id === encodeJobId)
			.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
		return previews?.[0];
	}

	myDraftProjects(userId: string) {
		return this.draftProjects$.pipe(
			map((projects, idx) => {
				return projects.filter(project => {
					return project.user.id === userId;
				});
			})
		);
	}

	myNeedsReviewProjects(userId: string) {
		return this.needsReviewProjects$.pipe(
			map((projects, idx) => {
				return projects.filter(project => {
					return project.user.id === userId;
				});
			})
		);
	}

	myApprovedProjects(userId: string) {
		return this.approvedProjects$.pipe(
			map((projects, idx) => {
				return projects.filter(project => {
					return project.user.id === userId;
				});
			})
		);
	}

	myFinishedProjects(userId: string) {
		return this.finishedProjects$.pipe(
			map((projects, idx) => {
				return projects.filter(project => {
					return project.user.id === userId;
				});
			})
		);
	}

	getProjectState(project: Project, encodeJob?: PublicEncodeJob) {
		if (encodeJob) {
			return 'review';
		}
		if (!project && !project?.state) {
			return 'editor';
		}
		switch (project.state) {
			case State.GET_STARTED:
				return 'get-started';
			case State.EDIT_AUDIO:
				return 'music';
			case State.EDIT_VIDEO:
				return 'editor';
			case State.REVIEW_CHECKOUT:
				return 'review';
			default:
				return 'editor';
		}
	}
}
