import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PublicDataSource } from '../../../../../../api/src/data-source/data-source.entity';

export interface DataSourceState extends EntityState<PublicDataSource>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'data-source'
})
export class DataSourceStore extends EntityStore<DataSourceState> {
	constructor() {
		super();
	}
}
