import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultResponse } from '../session';
import { environment } from '../../../../environments/environment';
import { map, Observable, tap } from 'rxjs';
import { ExportActionStore } from './export-action.store';
import { BaseEntityService } from '../base-entity.service';
import { PublicExportAction } from '../../../../../../api/src/export-action/export-action.entity';
import { ExportActionAddDto } from '../../../../../../api/src/export-action/dtos/export-action-add.dto';
import { ExportActionUpdateDto } from '../../../../../../api/src/export-action/dtos/export-action-update.dto';
import { ExportActionFindDto } from '../../../../../../api/src/export-action/dtos/export-action-find.dto';
import { PublicExportActionLog } from '../../../../../../api/src/export-action/export-action-log.entity';

@Injectable({ providedIn: 'root' })
export class ExportActionService extends BaseEntityService {
	constructor(private http: HttpClient, private exportActionStore: ExportActionStore) {
		super();
	}

	find(findReq: ExportActionFindDto = {}, skipStore = false) {
		return this.http.post<DefaultResponse<any>>(`${environment.apiUrl}/export-action/find`, findReq).pipe(
			tap(response => {
				if (!skipStore){
					this.exportActionStore.set(response.data?.results);
				}
			})
		);
	}

	get({ page = 1, limit = 10, sort }): Observable<DefaultResponse<PublicExportAction>> {
		return this.http
			.post<DefaultResponse<any>>(
				`${environment.apiUrl}/export-action/find`,
				{},
				{ params: { page, perPage: limit, sortBy: sort.sortBy, order: sort.direction } }
			)
			.pipe(
				map(response => ({
					totalResults: response.data.totalResults,
					data: response.data?.results,
					status: response.status,
					message: response.message,
					numPages: response.data.numPages,
					perPage: response.data.perPage,
					page: response.data.page
				})),
				tap(response => {
					this.exportActionStore.set(response.data);
				})
			);
	}

	getTemplates({ page = 1, limit = 10, sort }): Observable<DefaultResponse<PublicExportAction>> {
		return this.http
			.post<DefaultResponse<any>>(
				`${environment.apiUrl}/export-action/find`,
				{projectId: null},
				{ params: { page, perPage: limit, sortBy: sort.sortBy, order: sort.direction } }
			)
			.pipe(
				map(response => ({
					totalResults: response.data.totalResults,
					data: response.data?.results,
					status: response.status,
					message: response.message,
					numPages: response.data.numPages,
					perPage: response.data.perPage,
					page: response.data.page
				})),
				tap(response => {
					this.exportActionStore.set(response.data);
				})
			);
	}

	getLogs(id: string, {page = 1, limit = 10, sort}): Observable<PublicExportActionLog[]> {
		return this.http
			.get<DefaultResponse<any>>(`${environment.apiUrl}/export-action/${id}/logs`, { params: { page, perPage: limit, sortBy: sort.sortBy, order: sort.direction }})
			.pipe(
				map(response => response?.data?.results || []),
			);
	}

	public create(exportActionAddDto: ExportActionAddDto): Observable<DefaultResponse<PublicExportAction>> {
		return this.http.post<DefaultResponse<PublicExportAction>>(`${environment.apiUrl}/export-action`, exportActionAddDto).pipe(
			tap(res => {
				if (res.status === 'succeeded') {
					this.exportActionStore.upsert(res.data.id, res.data);
				}
			})
		);
	}

	public createForProject(projectId: string, exportActionAddDtos: ExportActionAddDto[]): Observable<DefaultResponse<PublicExportAction>> {
		return this.http.post<DefaultResponse<PublicExportAction>>(`${environment.apiUrl}/export-action/project/${projectId}`, exportActionAddDtos);
	}
	public update(id: string, exportActionUpdateDto: ExportActionUpdateDto): Observable<DefaultResponse<PublicExportAction>> {
		return this.http
			.put<DefaultResponse<PublicExportAction>>(`${environment.apiUrl}/export-action/${id}`, exportActionUpdateDto)
			.pipe(
				tap(res => {
					if (res.status === 'succeeded') {
						this.exportActionStore.upsert(res.data.id, res.data);
					}
				})
			);
	}

	public delete(id: string): Observable<DefaultResponse<PublicExportAction>> {
		return this.http.delete<DefaultResponse<PublicExportAction>>(`${environment.apiUrl}/export-action/${id}`).pipe(
			tap(res => {
				if (res.status === 'succeeded') {
					this.exportActionStore.remove(id);
				}
			})
		);
	}

	public setActive(id: string) {
		this.exportActionStore.setActive(id);
	}
}
