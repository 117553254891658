import { Component, inject, OnInit } from '@angular/core';
import { GlobalQuery } from "../../../_core/state/global";
import { GlobalSettings } from "../../../_core/state/global/global.model";
import { Observable } from "rxjs";
import { AsyncPipe, NgIf } from "@angular/common";
import {
	DisclaimerDialogComponent,
	DisclaimerDialogComponentData
} from "../disclaimer-dialog/disclaimer-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { SharedModule } from "../../shared.module";
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-footer',
  standalone: true,
	imports: [
		AsyncPipe,
		NgIf,
		SharedModule,
		MatIconModule
	],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
	private globalQuery: GlobalQuery = inject(GlobalQuery);
	private dialog: MatDialog = inject(MatDialog);

	public settings$: Observable<GlobalSettings>;


	ngOnInit() {
		this.settings$ = this.globalQuery.select('settings');
	}

	onFooterLinkAction(action: string, settings: GlobalSettings): void {
		if (action === 'showTerms' && settings.terms) {
			this.onShowTerms(settings.terms);
		}
	}

	private onShowTerms(termsText: string): void {
		this.dialog.open(DisclaimerDialogComponent, {
			data: {
				title: 'Terms and Conditions',
				message: termsText,
				hideAcceptButton: true,
				showClose: true,
				showDeclineButton: true,
				declineButtonText: 'Close'
			} as DisclaimerDialogComponentData,
			panelClass: ['background-color-alternative', 'max-width-lg']
		});
	}
}
