/**
 * NPM Modules
 */
import fetch, { Response } from 'node-fetch';

/**
 * Defaults and env variables
 */
const config = {
	apiKey: process.env.GOOGLE_MAPS_API_KEY
};

export namespace GoogleMaps {
	export class Geocoding {
		private static endpoint: string = 'https://maps.googleapis.com/maps/api/geocode/json';

		public static async getAddressSuggestions(address: string) {
			if (!config || !config.apiKey) {
				throw 'Not configured. Missing GOOGLE_MAPS_API_KEY.';
			}

			let response = await fetch(`${this.endpoint}?address=${address}&sensor=false&key=${config.apiKey}`).catch(err => {
				console.log(err);
				return null;
			});

			if (!response) {
				throw 'Invalid response from maps API.';
			}

			try {
				response = JSON.parse(await response.json().catch(err => null));
				console.log(response);
			} catch (e) {
				console.log(e);
				throw 'Invalid response from maps API.';
			}

			if (!response || !response.results) {
				throw 'Could not find address.';
			} else {
				return response.results;
			}
		}
	}
}
