import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { PublicUser, User } from '../user/user.entity';
import { EncodeJob, PublicEncodeJob } from '../encode/encode-job.entity';

export enum CommentStatus {
	ChangesRequested = 'changesRequested',
	Approved = 'approved'
}

export type PublicComment = Pick<Comment,
	'id' | 'status' | 'body' | 'authorId' | 'created' | 'updated'
> & {
	encodeJobId?: string;
	encodeJob?: PublicEncodeJob;
	author?: PublicUser;
}

@Entity('comments')
export class Comment {
	constructor(value?: Partial<Comment>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	body?: string;

	@Column({
		type: 'enum',
		enum: CommentStatus,
		nullable: true
	})
	status?: CommentStatus;

	@Column('uuid', { nullable: true })
	encodeJobId?: string;
	@ManyToOne(
		() => EncodeJob,
		{
			eager: true,
			orphanedRowAction: 'delete',
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'encodeJobId' })
	encodeJob?: EncodeJob;

	@Column('uuid', { nullable: false })
	authorId: string;
	@ManyToOne(
		() => User,
		{
			eager: true,
			onDelete: 'CASCADE'
		}
	)
	author: User;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created: string;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	updated: string;

	public toPublic() {
		const pub: Partial<PublicComment> = {
			id: this.id,
			status: this.status,
			body: this.body,
			created: this.created,
			updated: this.updated,
			authorId: this.authorId
		};

		if(this.encodeJobId) {
			pub.encodeJobId = this.encodeJobId;
		}

		if(this.author) {
			pub.author = new User(this.author).toPublic();
		}

		return pub as PublicComment;
	}
}