import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { StoryStore } from './story.store';
import { Story, getStoryQuery, createOverlayUIState, StoryUI } from './story.model';
import { Music, MusicStore } from '../music';
import { SurveyStore } from '../survey/survey.store';
import { createStorySegment, StorySegmentService, StorySegmentQuery } from '../story-segment';
import { environment } from '../../../../environments/environment';
import qs from 'qs';
import { StrapiCollectionResponse } from '../global/global.model';
import { createSurvey } from '../survey/survey.model';
import { ClipService, ClipStore } from '../clip';
import { createAIVoiceoverStory, createVoice, createVoiceoverPackage } from '../voiceover/voiceover.model';
import { StoryQuery } from './story.query';
import { arrayUpsert } from '@datorama/akita';
import { createOverlay, Overlay, OverlayField } from '../overlay/overlay.model';
import { takeWhile } from "rxjs";

@Injectable({ providedIn: 'root' })
export class StoryService {
	constructor(
		private storyQuery: StoryQuery,
		private storyStore: StoryStore,
		private storySegmentService: StorySegmentService,
		private musicStore: MusicStore,
		private surveyStore: SurveyStore,
		private clipStore: ClipStore,
		private http: HttpClient,
		private readonly clipService: ClipService,
		private readonly storySegmentQuery: StorySegmentQuery
	) {}

	get() {
		// console.log('Story Query', getStoryQuery(environment.cmsSiteId));
		const query = qs.stringify(
			{
				populate: {
					story_segments: {
						populate: {
							assetFilters: {
								populate: "*"
							},
							overlays: {
								populate: ["fields", "languageCode"]
							}
						}
					},
					surveys: {
						populate: {
							questions: {
								populate: ["answers"]
							}
						}
					},
					overlays: {
						populate: ["fields", "languageCode"]
					},
					assetFilters: {
						populate: "*"
					},
					audioSettings: {
						populate: {
							aiVoiceoverStories: {
								populate: "voiceoverSegments"
							},
							voiceoverPackages: {
								populate: "voiceoverSegments"
							},
							voices: {
								populate: "*"
							}
						}
					}
				}
			},
			{
				encodeValuesOnly: true // prettify URL
			}
		);

		return this.http
			.get<StrapiCollectionResponse<Story>>(`${environment.cmsUrl}/api/stories?${query}`, {
				headers: {
					Authorization: `Bearer ${environment.cmsToken}`
				}
			})
			.pipe(
				map(response =>
					response?.data?.map((e: any) => ({
						id: e.id,
						...e.attributes,
						storySegments: e.attributes.story_segments?.data.map(ss => createStorySegment(ss)) || [],
						story_segments: undefined,
						surveys: e.attributes.surveys?.data.map(s => createSurvey(s)) || [],
						overlays: e.attributes.overlays?.data.map(o => createOverlay(o)) || [],
						audioSettings: {
							...e.attributes.audioSettings,
							aiVoiceoverStories:
								e.attributes.audioSettings.aiVoiceoverStories?.data.map(s => createAIVoiceoverStory(s)) || [],
							voiceoverPackages: e.attributes.audioSettings.voiceoverPackages?.data.map(s => createVoiceoverPackage(s)) || [],
							voices: e.attributes.audioSettings.voices?.data.map(s => createVoice(s)) || []
						}
					}))
				),
				tap((entries: Story[]) => {
					this.storyStore.set(entries);
				})
			);
	}

	setActive(story: Story) {
		this.storyStore.setActive(story.id);
		this.storySegmentService.set(story.storySegments);
		this.musicStore.set(story.music);
		this.surveyStore.set(story.surveys);
		this.clipStore.set([]);

		if (story.videoFormats?.length) {
			this.setActiveVideoFormat(story.videoFormats[0]);
		}
	}

	loadClips() {
		if (this.storyQuery.getActive()) {
			// Since this service call makes several API calls, reset the store first and let the calls all add to the store.
			this.storyQuery.setClipsLoaded(false);
			this.clipService.removeAll();

			this.clipService.getAllClipsForStory(this.storyQuery.getActiveId() as string)
				.pipe(takeWhile(() => this.storyQuery.getActiveId() !== null))
				.subscribe(clips => {

				// Now that we have all the clips, let's load default UI for any of them without it.
				this.storySegmentService.setDefaultClips(this.storySegmentQuery.getAll());

				// HACK: to get existing projects to reset their active state.
				this.storySegmentService.setActive(null);
				this.storySegmentService.setActive(this.storyQuery.getActive().storySegments[0].id);
				this.storyQuery.setClipsLoaded(true);
			});
		} else {
			// Go back to the home page.
			// this.globalService.setState('get-started');
		}
	}

	reset() {
		this.storyStore.setActive(null);
		this.storyStore.ui.reset();
		this.storySegmentService.reset();
		this.musicStore.reset();
		this.surveyStore.reset();
		this.clipStore.set([]);
	}

	setActiveVideoFormat(videoFormat) {
		this.storyStore.updateActive(active => ({
			videoFormats: active.videoFormats.map(vf => {
				if (vf.id === videoFormat.id) {
					return {
						...vf,
						active: true
					};
				} else {
					return {
						...vf,
						active: false
					};
				}
			})
		}));
	}

	toggleActiveVideoFormat(videoFormat) {
		this.storyStore.updateActive(active => ({
			videoFormats: active.videoFormats.map(vf => {
				if (vf.id === videoFormat.id) {
					// Toggle active
					if (vf.active) {
						return {
							...vf,
							active: false
						};
					} else {
						return {
							...vf,
							active: true
						};
					}
				} else {
					return vf;
				}
			})
		}));
	}

	updateOverlay(id: Overlay['id'], key: string, value: any) {
		const activeId = this.storyQuery.getActiveId();
		const ui = this.storyQuery.ui.getEntity(activeId);
		const overlay = ui?.overlays.find(o => o.id === id) || createOverlayUIState(id);

		this.storyStore.ui.upsert(activeId, {
			overlays: arrayUpsert(ui?.overlays || [], id, {
				...overlay,
				[key]: value
			})
		});
	}

	updateOverlayField(overlayId: Overlay['id'], fieldId: OverlayField['id'], value: any) {
		const activeId = this.storyQuery.getActiveId();
		const ui = this.storyQuery.ui.getEntity(activeId) as StoryUI;
		const overlay = ui?.overlays.find(o => o.id === overlayId) || createOverlayUIState(overlayId);

		this.storyStore.ui.upsert(activeId, {
			overlays: arrayUpsert(ui.overlays, overlayId, {
				...overlay,
				fields: arrayUpsert(overlay.fields || [], fieldId, { id: fieldId, value })
			})
		});
	}

	updateOverlaySnapshot(overlayId: Overlay['id'], snapshot: string) {
		const activeId = this.storyQuery.getActiveId();
		const ui = this.storyQuery.ui.getEntity(activeId) as StoryUI;
		const overlay = ui?.overlays.find(o => o.id === overlayId) || createOverlayUIState(overlayId);

		this.storyStore.ui.upsert(activeId, {
			overlays: arrayUpsert(ui.overlays, overlayId, {
				...overlay,
				snapshot
			})
		});
	}

	setLoading(loading: boolean) {
		this.storyStore.setLoading(loading);
	}
}
