import { Entity, Column, PrimaryGeneratedColumn, OneToMany, ManyToOne, JoinColumn, JoinTable, ManyToMany } from 'typeorm';

import { Project, PublicProject } from '../project/project.entity';

import { EncodeTask, PublicEncodeTask } from './encode-task.entity';
import { PublicUser, User } from '../user/user.entity';
import { Comment } from '../comment/comment.entity';
import { ProjectCartItem, PublicProjectCartItem } from '../cart/project-cart-item.entity';

export enum JobStatus {
	Pending = 'pending',
	Active = 'active',
	Completed = 'completed',
	Failed = 'failed'
}

export enum JobTag {
	Final = 'final',
	Preview = 'preview',
	Export = 'export'
}

export interface JobResult {
	encodeTimeMS: number;
	files: {
		kind: string;
		location: string;
	}[];
}

export type PublicEncodeJob = Pick<
	EncodeJob,
	'id' | 'tag' | 'created' | 'status' | 'projectId' | 'requiresApproval' | 'approved' | 'userLocale'
> & {
	project?: PublicProject;
	encodeTasks?: PublicEncodeTask[];
	approvers?: PublicUser[];
	reviewers?: PublicUser[];
	comments?: any[];
	created?: string;
	cartItems: PublicProjectCartItem[];
	user?: PublicUser;
};

@Entity('encode_jobs')
export class EncodeJob {
	constructor(value?: Partial<EncodeJob>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column('text', { nullable: true })
	public tag?: string;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public created: string;

	@Column({
		type: 'enum',
		enum: JobStatus,
		default: JobStatus.Pending
	})
	public status: JobStatus;

	@Column('text', { nullable: true })
	public projectId: string;
	@ManyToOne(
		type => Project,
		project => project.encodeJobs,
		{
			eager: false,
			onDelete: 'CASCADE',
			nullable: true
		}
	)
	@JoinColumn({ name: 'projectId' })
	public project?: Project | Partial<Project>;

	@OneToMany(
		type => EncodeTask,
		task => task.encodeJob,
		{
			eager: false,
			onDelete: 'CASCADE',
			cascade: true
		}
	)
	public encodeTasks?: EncodeTask[] | Partial<EncodeTask>[];

	@Column('boolean', { default: false })
	public requiresApproval?: boolean;

	@ManyToMany(() => User, {
		nullable: true
	})
	@JoinTable({ name: 'encodeJobApprovers' })
	public approvers?: User[] | Partial<User>[];

	@ManyToMany(() => User, {
		nullable: true
	})
	@JoinTable({ name: 'encodeJobReviewers' })
	public reviewers?: User[] | Partial<User>[];

	@OneToMany(
		() => Comment,
		comment => comment.encodeJob,
		{
			nullable: true,
			onDelete: 'CASCADE'
		}
	)
	public comments?: Comment[];

	@Column('boolean', { default: false })
	public approved?: boolean;

	@OneToMany(
		() => ProjectCartItem,
		cartItem => cartItem.encodeJob,
		{ eager: false, nullable: true }
	)
	public cartItems?: ProjectCartItem[] | Partial<ProjectCartItem>[];

	@Column('text', { nullable: true })
	public userId: string;
	@ManyToOne(
		() => User,
		user => user.id,
		{
			eager: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'userId' })
	public user: User | Partial<User>;

	@Column('text', { nullable: true })
	public userLocale?: string;

	public toPublic(exclude?: Array<keyof PublicEncodeJob>) {
		let pub: Partial<PublicEncodeJob> = {
			id: this.id,
			tag: this.tag,
			created: this.created,
			status: this.status,
			requiresApproval: this.requiresApproval,
			approved: this.approved,
			projectId: this.projectId,
			userLocale: this.userLocale
		};

		if (this.project && !exclude?.includes('project')) {
			pub.project = new Project(this.project).toPublic(['encodeJobs']);
		}

		if (this.encodeTasks?.length && !exclude?.includes('encodeTasks')) {
			pub.encodeTasks = (this.encodeTasks as EncodeTask[]).map(t => new EncodeTask(t).toPublic(['encodeJob']));
		}

		if (this.approvers?.length) {
			pub.approvers = this.approvers?.map(a => new User(a).toPublic());
		}

		if (this.reviewers?.length) {
			pub.reviewers = this.reviewers?.map(r => new User(r).toPublic());
		}

		if (this.comments?.length) {
			pub.comments = this.comments?.map(c => new Comment(c).toPublic());
		}

		if (this.cartItems?.length && !exclude?.includes('cartItems')) {
			pub.cartItems = (this.cartItems as ProjectCartItem[]).map(a => new ProjectCartItem(a).toPublic());
		}

		if (this.user) {
			pub.user = new User(this.user).toPublic();
		}

		return pub as PublicEncodeJob;
	}
}
