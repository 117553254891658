import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

declare let ga: Function;
declare let gtag: Function;

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {
	private enabled: boolean;
	private gaType: 'UA' | 'GTM' | 'none' = 'none';
	private analyticsId: string;
	private userId: string;
	private sessionId: string;
	private deviceType: string;
	private businessId: string;
	private dimensions = {};

	constructor() {
		if (environment.analyticsMode === 'app') {
			if (!localStorage.getItem('GAID')) {
				localStorage.setItem('GAID', new Date().getTime() + '-' + Math.round(Math.random() * 10000));
			}
			this.userId = localStorage.getItem('GAID');
		}

		// Pull the tracking id from the index file.
		// tslint:disable-next-line: no-string-literal
		if (window['gaID'] !== 'none') {
			// tslint:disable-next-line: no-string-literal
			this.analyticsId = window['gaID'];
		}

		// Pull the tracking type from the index file.
		// tslint:disable-next-line: no-string-literal
		if (window['gaType'] !== 'none') {
			// tslint:disable-next-line: no-string-literal
			this.gaType = window['gaType'];
		}

		if (this.analyticsId !== 'none' && this.gaType !== 'none') {
			this.enabled = true;
		}
	}

	public setSessionVars(userId, deviceType?, businessId?) {
		if (!this.enabled) {
			return;
		}
		this.userId = userId;

		if (deviceType) {
			this.deviceType = deviceType;
		}

		if (businessId) {
			this.businessId = businessId;
		}
	}

	public setDimension(dimensionKey, dimensionValue) {
		if (!this.enabled) {
			return;
		}
		if (this.gaType === 'UA') {
			return;
		}

		// Create object to map that is just { dimension1: dimension1,... }
		this.dimensions = {
			...this.dimensions,
			dimensionKey
		};

		// Map all of those so they are being read in events
		if (typeof gtag === 'function') {
			gtag('config', 'GA_MEASUREMENT_ID', {
				custom_map: this.dimensions
			});
		}

		// Set that dimension using the same key we mapped
		const obj = {};
		obj[dimensionKey] = dimensionValue;


		window['dataLayer'].push(obj);
		// gtag('event', 'set_dimensions', obj);
	}

	public getUserId() {
		if (!this.enabled) {
			return;
		}
		return this.userId;
	}

	public startSession(forceNewSession?: boolean): void {
		if (!this.enabled) {
			return;
		}
		if (!this.sessionId) {
			if (this.userId) {
				this.sessionId = this.userId;
			} else {
				this.sessionId = 'anonymous-user-' + Date.now().toString();
			}

			if (this.gaType === 'UA') {
				ga('set', 'userId', this.sessionId);
				ga('send', 'pageview', { sessionControl: 'start' });
			} else if (this.gaType === 'GTM') {
				if (typeof gtag === 'function') {
					gtag('config', 'GA_MEASUREMENT_ID', {
						user_id: this.sessionId
					});
				}
			}
		} else if (forceNewSession) {
			this.stopSession();
			this.startSession();
		}
	}

	public stopSession(): void {
		if (!this.enabled) {
			return;
		}
		if (this.sessionId) {
			if (this.gaType === 'UA') {
				ga('send', 'pageview', { sessionControl: 'end' });
				ga('set', 'userId', '');
			}
			this.sessionId = undefined;
		}
	}

	public sendEvent(category: string, action: string, label?: string, val?: number, extra?: any): void {
		if (!this.enabled) {
			return;
		}

		this.startSession();

		if (this.gaType === 'UA') {
			const obj: any = {
				eventCategory: category,
				eventAction: action,
				user: this.sessionId
			};

			if (label) {
				obj.eventLabel = label;
			}

			if (val) {
				obj.eventValue = val;
			}

			if (extra) {
				obj.extra = extra;
			}

			ga('send', 'event', obj);
		} else if (this.gaType === 'GTM') {
			const obj: any = {
				event_category: category,
				user: this.sessionId
			};

			if (label) {
				obj.event_label = label;
			}

			if (val) {
				obj.value = val;
			}

			if (typeof gtag === 'function') {
				gtag('event', action, obj);
			}
		}
	}

	public setPage(page: string, pageTitle?: string): void {
		if (!this.enabled) {
			return;
		}
		if (this.gaType === 'UA') {
			ga('set', 'page', page);
		} else if (this.gaType === 'GTM') {
			if (typeof gtag === 'function') {
				gtag('config', this.analyticsId, {
					page_title: pageTitle || 'undefined',
					page_path: page,
					send_page_view: true
				});
			}
		}
	}

	public sendPageView(page: string, pageTitle?: string): void {
		if (!this.enabled) {
			return;
		}
		this.startSession();
		if (this.gaType === 'UA') {
			ga('set', 'page', page);
			ga('send', {
				hitType: 'pageView',
				user: this.sessionId
			});
		} else if (this.gaType === 'GTM') {
			this.setPage(page, pageTitle);
		}
	}
}
