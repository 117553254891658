import {Observable} from 'rxjs';

export function scrollToElementId(id: string) {
	let top = document.getElementById(id);
	if (top !== null) {
		top.scrollIntoView();
		top = null;
	}
}



export class ResizeObservable extends Observable<ResizeObserverEntry> {
	constructor(elem: HTMLElement) {
		super(subscriber => {
			const ro = new ResizeObserver(entries => {
				subscriber.next(entries?.[0])
			});

			// Observe one or multiple elements
			ro.observe(elem);

			return function unsubscribe() {
				ro.unobserve(elem);
				ro.disconnect();
			}
		});
	}
}
