import { IsDateString, IsMimeType, IsNumberString, IsOptional, IsString, IsUUID, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class ImageDimensionsDto {
	@IsNumberString()
	@IsOptional()
	width?: string;

	@IsNumberString()
	@IsOptional()
	height?: string;
}


export class AssetDto {
	@IsUUID()
	id: string;

	@IsString()
	title: string;

	@IsString()
	type: string;

	@IsString()
	assetPath: string;

	@IsString()
	@IsOptional()
	businessId: string | null;

	@IsUUID()
	@IsOptional()
	projectId: string | null;

	@IsString()
	provider: string;

	@IsUUID()
	siteId: string;

	@IsString()
	@IsDateString()
	created: string;

	@IsString()
	@IsOptional()
	description: string | null;

	@Type(() => ImageDimensionsDto)
	@ValidateNested()
	dimensions: ImageDimensionsDto;

	@IsMimeType()
	mimeType: string;

	@IsOptional()
	mediaType?: string;

	@IsOptional()
	externalIds: Record<string, string>;

	// Used optionally to apply values to other template areas.
	@IsOptional()
	mappings?: any; // TODO: Figure out how to type this without breaking API: CustomizationOptionMappingDto

	public static toReceiptMarkdown(asset: AssetDto, includeDevInfo = false) {
		let mdString: string = '';
		if (asset?.title) {
			mdString += `__Title:__ ${asset.title}<br>`;
		}
		if (asset?.description) {
			mdString += `__Description:__ ${asset.description}<br>`;
		}
		if (asset?.dimensions?.width && asset?.dimensions?.height) {
			mdString += `__Dimensions:__ ${asset.dimensions.width + 'x' + asset.dimensions.height}<br>`;
		}
		if (asset?.assetPath) {
			mdString += `__Path:__ ${asset.assetPath}<br>`;
		}

		if (includeDevInfo) {
			if (asset?.mimeType) {
				mdString += `__Mime Type:__ ${asset.mimeType}<br>`;
			}
			if (asset?.provider) {
				mdString += `__Provider:__ ${asset.provider}<br>`;
			}
		}
		return mdString;
	}
}

export const AssetDtoItemDef: AssetDto = {
	id: '',
	title: '',
	type: '',
	assetPath: '',
	businessId: null,
	projectId: null,
	provider: '',
	siteId: '',
	created: '',
	description: null,
	dimensions: {
		width: '',
		height: ''
	},
	mimeType: '',
	mediaType: '',
	externalIds: {}
};
