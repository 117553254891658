import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { ClipStore, ClipState } from './clip.store';

@Injectable({ providedIn: 'root' })
export class ClipQuery extends QueryEntity<ClipState> {
	constructor(protected override store: ClipStore) {
		super(store);
	}

	selectClipsForSegment(segmentId: string, storyId?: string) {
		if (storyId) {
			return this.selectAll().pipe(map(clips => clips.filter(clip => clip.segmentId === segmentId && clip.storyId === storyId)));
		} else {
			return this.selectAll().pipe(map(clips => clips.filter(clip => clip.segmentId === segmentId)));
		}
	}

	getClipsForStory(storyId: string) {
		return this.getAll()?.filter(clip => clip.storyId === storyId) || [];
	}

	getClipsForSegment(segmentId: string) {
		return this.getAll()?.filter(clip => clip.segmentId === segmentId) || [];
	}
}
