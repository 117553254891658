import { Entity, Column, PrimaryGeneratedColumn, ManyToOne, JoinColumn } from 'typeorm';

import { ProviderConfig } from './models/syncing-provider.dto';
import { DataSource, PublicDataSource } from '../data-source/data-source.entity';
import { Project } from '../project/project.entity';

export type PublicSyncingProvider = Pick<ProjectsSyncingProvider, 'id' | 'config'> & {
	dataSource: Partial<PublicDataSource>;
};
@Entity('projectsSyncingProviders')
export class ProjectsSyncingProvider {
	constructor(value?: Partial<ProjectsSyncingProvider>) {
		for (const k in value) {
			if (!value[k]) {
				continue;
			}

			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column('uuid')
	public projectId: string;

	@ManyToOne(
		() => Project,
		project => project.id,
		{
			eager: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'projectId' })
	public project: Project | Partial<Project>;

	@Column('uuid')
	public dataSourceId: string;

	@ManyToOne(() => DataSource, {
		eager: false,
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'dataSourceId' })
	public dataSource: DataSource | Partial<DataSource>;

	@Column('jsonb', { nullable: true })
	public config: ProviderConfig;

	public toPublic(exclude?: Array<keyof PublicSyncingProvider>) {
		let pub: Partial<PublicSyncingProvider> = {
			id: this.id,
			config: this.config
		};

		if (this.dataSource && !exclude?.includes('dataSource')) {
			pub.dataSource = new DataSource(this.dataSource).toPublic();
		}

		return pub as PublicSyncingProvider;
	}
}
