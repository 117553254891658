import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';
import { StrapiProduct } from '../_core/third-party/strapi';
import { String } from '../_core/utils/utils.string';

export enum ProductType {
	Subscription = 'Subscription',
	Individual = 'Individual'
}

export type PublicProduct = Pick<Product, 'id' | 'title' | 'slug' | 'price' | 'description' | 'countries'>;

@Entity('product')
@Index(['remoteId', 'slug'], { unique: true })
export class Product {
	constructor(value?: Partial<Product>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}
	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', {})
	title: string;

	@Column({ type: 'text', unique: true, nullable: true })
	slug: string;

	@Column('float', {})
	price: number;

	@Column('text')
	productPriceId: string;

	@Column({ type: 'text', unique: true })
	remoteId: number;

	@Column('text', { nullable: true })
	description: string;

	@Column('text', { nullable: true })
	userDescription: string;

	@Column({ type: 'enum', enum: ProductType })
	type: ProductType;

	@Column('boolean', {})
	enabled: boolean;

	@Column('boolean', { name: 'default_product' })
	defaultProduct: boolean;

	@Column('json', { nullable: true })
	countries: string[];

	public static buildFromStrapi(strapiProduct: StrapiProduct): Product {
		const p: Product = new Product();
		p.remoteId = strapiProduct.id;
		p.title = strapiProduct.attributes.title;
		p.slug = strapiProduct.attributes.slug || String.strapiSlugify(strapiProduct.attributes.title);
		p.price = strapiProduct.attributes.price;
		p.productPriceId = strapiProduct.attributes.productPriceId;
		p.description = strapiProduct.attributes.description || '';
		p.userDescription = strapiProduct.attributes.userDescription || '';
		p.type = strapiProduct.attributes.type;
		p.enabled = strapiProduct.attributes.enabled;
		p.defaultProduct = strapiProduct.attributes.defaultProduct;
		p.countries = strapiProduct.attributes.countries.data.map(country => country.attributes.alpha2Code) || [];

		return p;
	}

	public toPublic(self: boolean = false) {
		const pub: Partial<PublicProduct> = {
			id: this.id,
			title: this.title,
			slug: this.slug,
			price: this.price,
			description: this.description
		};

		return pub as PublicProduct;
	}
}
