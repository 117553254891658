import { Injectable } from '@angular/core';
import { QueryEntity, EntityUIQuery, filterNil, isUndefined } from '@datorama/akita';
import { StorySegmentStore, StorySegmentState, StorySegmentUIState } from './story-segment.store';
import { Observable, combineLatest } from 'rxjs';
import { map, auditTime, filter, switchMap } from 'rxjs/operators';
import { StorySegment, StorySegmentUI } from './story-segment.model';
import { Clip, ClipQuery } from '../clip';
import { replaceMergeTags } from '../../utils/string.utils';
import { SessionQuery } from '../session';
import { Overlay, OverlayField } from '../overlay/overlay.model';
import { MusicQuery } from '../music';
import { StoryUI } from '../story/story.model';
import { stringToSlug } from '../../../_core/utils/string.utils';
import { segmentFilter } from '../collection/collection.data';
import { Project } from '../../../../../../api/src/project/project.entity';
import { over } from 'cypress/types/lodash';
import { Utils } from '../../../../../../api/src/project/project.utils';
import { CartQuery } from "../cart/cart.query";

@Injectable({ providedIn: 'root' })
export class StorySegmentQuery extends QueryEntity<StorySegmentState> {
	ui: EntityUIQuery<StorySegmentUIState, StorySegmentUI>;

	// Combine segment and active clip overlays together to form the complete overlay state
	activeSegmentOverlays$ = this.selectActiveWithUI().pipe(
		map(segment => {
			const activeClip = this.getActiveClip(segment.id);

			// if any segment overlay fields match the active clip overlay fields, remove them from the segment overlays.
			let segmentOverlays = JSON.parse(JSON.stringify(segment.overlays)) || [];
			segmentOverlays = segmentOverlays.map(overlay => {
				if (overlay.fields) {
					overlay.fields = overlay.fields.filter(field => {
						return !activeClip?.overlays?.find(clipOverlay => {
							return clipOverlay.fields.find(clipField => {
								return clipField.label === field.label;
							});
						});
					});
				}

				return overlay;
			});

			// Return the segment and clip overlays combined and unique.
			return [...(segmentOverlays || []), ...(activeClip?.overlays || [])].filter(
				(overlay, index, self) => self.findIndex(o => o.id === overlay.id) === index
			);
		})
	);

	areSegmentsValid$: Observable<boolean>;

	constructor(
		private readonly clipQuery: ClipQuery,
		protected store: StorySegmentStore,
		private readonly sessionQuery: SessionQuery,
		private readonly musicQuery: MusicQuery,
		private readonly cartQuery: CartQuery,
	) {
		super(store);
		this.createUIQuery();

		// Test if the segments all have active clips
		this.areSegmentsValid$ = combineLatest([this.selectAll(), this.ui.selectAll()]).pipe(
			map(([segments, ui]) => {
				let valid = true;
				segments.forEach(segment => {
					if (!this.getIsValid(segment.id)) {
						valid = false;
					}
				});
				return valid;
			})
		);
	}

	selectEntitiesWithUI(): Observable<StorySegment[]> {
		return combineLatest([this.selectAll(), this.ui.selectAll({ asObject: true })]).pipe(
			map(([segments, segmentsUI]) => {
				return segments.map(segment => {
					return {
						...segment,
						ui: segmentsUI[segment.id]
					};
				});
			}),
			auditTime(100)
		);
	}

	selectEntityWithUI(id: StorySegment['id']): Observable<StorySegment> {
		return combineLatest([this.selectAll(), this.ui.selectAll({ asObject: true }), this.clipQuery.selectClipsForSegment(id)]).pipe(
			filter(([segments, segmentsUI, clips]) => id && segments.length > 0),
			map(([segments, segmentsUI, clips]) => {
				const segment = segments.find(s => s.id === id);
				if (!segment) {
					console.warn('No segment found for id', id);
				}
				if (!segmentsUI[id]) {
					console.warn('No segment UI found for id', id);
				}

				return {
					...segment,
					ui: segmentsUI[id] || ({} as StorySegmentUI),
					clips
				};
			}),
			auditTime(100)
		);
	}

	getEntitiesWithUI(): StorySegment[] {
		return this.getAll().map(segment => {
			return {
				...segment,
				ui: this.ui.getEntity(segment.id)
			};
		});
	}

	getEntityWithUI(id: string): StorySegment {
		return {
			...this.getEntity(id),
			ui: this.ui.getEntity(id)
		};
	}

	getActiveWithUI(): StorySegment {
		return {
			...this.getActive(),
			ui: this.ui.getEntity(this.getActiveId())
		};
	}

	selectActiveWithUI(): Observable<StorySegment> {
		return this.selectActive().pipe(
			filterNil,
			switchMap(segment => this.selectEntityWithUI(segment.id))
		);
	}

	selectActiveClip(id: string): Observable<Clip> {
		return this.ui.selectEntity(id, 'activeClip');
	}

	getActiveClip(id: string): Clip {
		return this.ui.getEntity(id)?.activeClip;
	}

	getIsValid(id: StorySegment['id']) {
		return this.ui.getEntity(id)?.errors?.length < 1 ? true : false;
	}

	/*
		Utility Functions
	*/

	getOverlayUIState(ui: StorySegmentUI, overlay: Overlay) {
		return ui.overlays.find(o => o.id === overlay?.id);
	}

	getOverlayUIStateValue(ui: StorySegmentUI | StoryUI, overlay: Overlay, key: string) {
		const val = ui.overlays.find(o => o.id === overlay.id)?.[key];

		// Support boolean 'false' values
		if (typeof val === 'undefined') {
			return overlay[key];
		} else {
			return val;
		}
	}

	//add param called mergedTagsStateOverride
	//
	getFieldValue(
		ui: StorySegmentUI | StoryUI,
		overlay: Overlay,
		field: OverlayField,
		mergeTags?: boolean,
		mergedTagsStateOverride?: any,
		project?: any
	) {
		let value = ui.overlays.find(o => o.id === overlay.id)?.fields.find(f => f.id === field.id)?.value;
		if (isUndefined(value)) {
			value = field.defaultValue;
		}

		if (mergeTags && typeof value === 'string') {
			const mergeTagState = {
				profile: this.sessionQuery.getProfile(),
				business: this.sessionQuery.getBusinesses().filter(b => {
					return b.id === project?.business?.id;
				})[0],
				...mergedTagsStateOverride
			};

			this.getEntitiesWithUI().forEach(segment => {
				mergeTagState[segment.slug] = segment;
			});

			if (value.includes('{core')) {
				value = value.replace(
					'core',
					Object.keys(mergeTagState).find(key => key.includes('core'))
				);
			}

			return replaceMergeTags(value || '', mergeTagState);
		}

		return value;
	}

	// Get an array of all the possible values for a custom field across the active clips.
	getActiveClipsCustomFieldValue(slug: string) {
		return this.getEntitiesWithUI()
			.map(segment => segment.ui.activeClip?.video?.customFields[slug] || [])
			.reduce((acc, val) => acc.concat(val), []);
	}

	// Get an array of all the active clips in the segment.
	getAllActiveClipsInSegment(segment: object) {
		return this.getEntitiesWithUI().map(segment => segment.ui?.activeClip?.video || []);
	}

	getMergedTagsInOverlay(overlay: Overlay) {
		const activeId = this.getActiveId();
		const activeUi = this.ui.getEntity(activeId);

		return this.findMergeFieldsInOverlay(overlay, activeUi);
	}

	getMergedTagsInSegment(project?: Project, forVoiceover: boolean = false) {
		const activeId = this.getActiveId();
		const activeUi = this.ui.getEntity(activeId);
		let fields = [];

		let workspace = this.cartQuery.getActive()?.itemData || project?.workspaceState;
		console.log("workspace", workspace);
		let overlayArray = Utils.getProjectOverlays(workspace).flat();

		console.log("overlayArray", overlayArray);
		// console.log('Utils.getProjectOverlays(project)');
		// console.log('entities', project.workspaceState['UI/story-segment'].entities);
		// overlay = overlayArray.concat(overlay).filter((o, index) => overlayArray.indexOf(o) == index);

		// let segmentSlugs = Utils.getProjectSegments(project.workspaceState).map((segment, index) => {
		// 	return { id: segment.id, slug: segment.slug };
		// });

		// let segmentsArray = Utils.getProjectOverlays(project).map((segment, index) => {
		// 	console.log('seg', segment);
		// 	return Utils.getOverlayFields(
		// 		overlayArray,
		// 		project.workspaceState['UI/story-segment'].entities[segment.id],
		// 		project.workspaceState['UI/story-segment'],
		// 		segmentSlugs
		// 	);
		// });

		// console.log('segmentsArray', segmentsArray);

		let processedActiveClips = overlayArray.map(overlay => {
			if (overlay?.fields) {
				// console.log('erere overlay', overlay);
				overlay?.fields.map(field => {
					// Identify the fields custom field slug for getActiveClipsCustomFieldValue function
					let customFieldString = field.defaultValue?.includes('${')
						? field.defaultValue?.replace('${', '').replace('}', '')
						: field.defaultValue;
					// console.log('customFieldString', customFieldString);
					let customFieldSlug = customFieldString?.slice(customFieldString?.lastIndexOf('.') + 1);

					if (field.label) {
						const fieldSlug = stringToSlug(field.label);

						fields[fieldSlug] =
							this.getActiveClipsCustomFieldValue(customFieldSlug)?.[0] ||
							activeUi?.overlays?.find(o => o.id === overlay.id)?.fields.find(f => f.id === field.id)?.value ||
							overlay.fields.find(f => f.id === field.id)?.value ||
							overlay.fields.find(f => f.id === field.id)?.defaultValue;

						if (field.overrideFieldForVoiceover && forVoiceover) {
							const hasActiveClipValue = fields[fieldSlug] === this.getActiveClipsCustomFieldValue(customFieldSlug)?.[0];

							if (hasActiveClipValue) {
								fields[stringToSlug(field.overrideFieldForVoiceover)] = fields[fieldSlug];
							}
						}
					}
				});
			}
		});

		if (processedActiveClips) {
			// console.log('segmentsArray', segmentsArray);
			return { fields: fields };
		}
	}

	findMergeFieldsInOverlay(overlay: any, activeUi: StorySegmentUI | StoryUI) {
		let fields = [];
		let mergedFields = overlay.fields.map(field => {
			if (field.label) {
				fields[stringToSlug(field.label)] =
					activeUi.overlays.find(o => o.id === overlay.id)?.fields.find(f => f.id === field.id)?.value ||
					overlay.fields.find(f => f.id === field.id)?.defaultValue;
			}

			return;
		});

		if (mergedFields) {
			return { fields: fields };
		}
	}

	isOverlayVisible(overlay: Overlay, segment: StorySegment) {
		return this.getOverlayUIStateValue(segment?.ui, overlay, 'isEnabled');
	}

}
