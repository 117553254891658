import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoryFormatStore } from './story-format.store';
import { StoryFormat } from './story-format.model';
import { createStory, StoryService, StoryStore } from '../story';
import { SurveyStore } from '../survey/survey.store';
import { map, tap } from 'rxjs/operators';
import qs from 'qs';
import { StrapiCollectionResponse } from '../global/global.model';
import { environment } from '../../../../environments/environment';
import { createOverlay } from '../overlay/overlay.model';
import { LanguageQuery } from '../language';
import { GlobalService } from '../global/global.service';
import { CMS_LOCALES } from '../../../../../../api/src/_core/craft-cms/carft-cms.model';

@Injectable({ providedIn: 'root' })
export class StoryFormatService {
	constructor(
		private storyFormatStore: StoryFormatStore,
		private storyStore: StoryStore,
		private languageQuery: LanguageQuery,
		private storyService: StoryService,
		private surveyStore: SurveyStore,
		private http: HttpClient,
		private globalService: GlobalService
	) {}

	get(countryCode: string = 'US') {
		// console.log('Story Query', getStoryQuery(environment.cmsSiteId));
		const query = qs.stringify(
			{
				locale: this.getCmsLocale(),

				populate: {
					assetFilters: {
						populate: '*'
					},
					stories: {
						populate: {
							story_segments: {
								populate: {
									assetFilters: {
										populate: '*'
									},
									overlays: {
										populate: ['fields', 'languageCode']
									}
								}
							},
							surveys: {
								populate: {
									questions: {
										populate: 'answers'
									}
								}
							},
							overlays: {
								populate: ['fields', 'languageCode']
							},
							assetFilters: {
								populate: '*'
							},
							audioSettings: {
								populate: {
									aiVoiceoverStories: {
										populate: 'voiceoverSegments'
									},
									voiceoverPackages: {
										populate: 'voiceoverSegments'
									},
									voices: {
										populate: {
											languageConfigs: {
												populate: 'language'
											}
										}
									}
								}
							},
							language: {
								populate: '*'
							},
							countries: {
								fields: ['alpha2Code']
							}
						}
					},
					overlays: {
						populate: ['fields', 'languageCode', 'countries']
					}
				}
			},
			{
				encodeValuesOnly: true // prettify URL
			}
		);

		return this.http
			.get<StrapiCollectionResponse<StoryFormat>>(`${environment.cmsUrl}/api/story-formats?${query}`, {
				headers: {
					Authorization: `Bearer ${environment.cmsToken}`
				}
			})
			.pipe(
				map(response => {
					// check if country is associated with any of story
					const isCountryAssociationEnabled =
						response?.data?.findIndex(
							(e: any) =>
								e.attributes.stories?.data?.findIndex(
									(s: any) => !!s.attributes.countries?.data?.find((c: any) => !!c.attributes.alpha2Code)
								) > -1
						) > -1;
					const isCountryAssociationForOverlaysEnabled =
						response?.data?.findIndex(
							(e: any) =>
								e.attributes.overlays?.data?.findIndex(
									(s: any) => !!s.attributes.countries?.data?.find((c: any) => !!c.attributes.alpha2Code)
								) > -1
						) > -1;

					return response?.data?.map(
						(e: any): StoryFormat => ({
							id: e.id,
							title: e.title,
							description: e.description,
							canvasWidth: e.canvasWidth,
							canvasHeight: e.canvasHeight,
							...e.attributes,
							stories:
								e.attributes.stories?.data
									?.filter(s =>
										isCountryAssociationEnabled
											? s.attributes.countries?.data?.findIndex(c => c.attributes.alpha2Code === countryCode) > -1
											: true
									)
									?.map(s => createStory(s)) || [],
							overlays:
								e.attributes.overlays?.data
									?.filter(o =>
										isCountryAssociationForOverlaysEnabled
											? o.attributes.countries?.data.findIndex(c => c.attributes.alpha2Code === countryCode) > -1
											: true
									)
									?.map(o => createOverlay(o)) || []
						})
					);
				}),
				map(storyFormats => {
					// make sure there is only one enabled overlay per format per language
					storyFormats.forEach(storyFormat => {
						if (storyFormat.overlays.length > 1) {
							if (storyFormat.overlays.filter(o => o.isEnabled)?.length !== 1) {
								// disable all overlays
								storyFormat.overlays = [...storyFormat.overlays?.map(o => ({ ...o, isEnabled: false }))];
								this.languageQuery.getAll().forEach(language => {
									// enable the first overlay for language
									const firstForLang = storyFormat.overlays.find(o => {
										return o?.languageCode?.data?.attributes?.languageCode === language.languageCode;
									});
									if (firstForLang) {
										firstForLang.isEnabled = true;
									}
								});
							}
						}
					});
					return storyFormats;
				}),
				tap((entries: StoryFormat[]) => {
					this.storyFormatStore.set(entries);
				})
			);
	}

	setActive(storyFormat: StoryFormat) {
		this.storyFormatStore.setActive(storyFormat.id);

		this.storyStore.setActive(null);
		this.storyStore.set(storyFormat.stories);
		this.surveyStore.reset();
	}

	reset() {
		this.storyFormatStore.setActive(null);
		this.storyService.reset();
	}

	setLoading(loading: boolean) {
		this.storyFormatStore.setLoading(loading);
	}

	isSocial(storyFormat: StoryFormat) {
		return !(storyFormat.canvasWidth == 1920 && storyFormat.canvasHeight == 1080);
	}

	isLong(storyFormat: StoryFormat) {
		if (storyFormat) {
			return storyFormat.canvasHeight >= storyFormat.canvasWidth;
		}
		return 1080 >= 1920;
	}

	isWide(storyFormat: StoryFormat) {
		if (storyFormat) {
			return storyFormat.canvasWidth > storyFormat.canvasHeight;
		}
		return 1920 > 1080;
	}

	getCmsLocale(fallback?: string) {
		const code = this.globalService.getLocale();

		return CMS_LOCALES[code] || fallback;
	}
}
