import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'objectValues'
})
export class ObjectValuesPipe implements PipeTransform {
	transform(object: Object): any[] {
		if (object === undefined || object === null) {
			return [];
		}

		return Object.values(object);

	}
}
