export function isAfter(date1, date2) {
	if (typeof date1 === 'string') {
		date1 = new Date(date1);
	}

	if (typeof date2 === 'string') {
		date2 = new Date(date2);
	}

	return new Date(date1).getTime() > new Date(date2).getTime();
}

export function toDateString(date: Date) {
	return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
		.getDate()
		.toString()
		.padStart(2, '0')}`;
}

export function todayString(): string {
	let today: Date = new Date();
	return `${monthNames[today.getMonth()]}${today.getDate()}${today.getFullYear()}`;
}


export const monthNames: string[] = [
	"January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
]
