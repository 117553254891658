export class ObjectUtils {
	/**
	 * Safely traverse through an object with a dot notated path string
	 * @param path
	 * @param obj
	 */
	public static resolveDotNotationPath(path, obj) {
		return path?.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || self);
	}

	public static createFromDotNotationPath(path) {
		return path.split('.').reduceRight((prev, curr) => ({ [curr]: prev }), {});
	}

	public static unfreeze(obj: any) {
		return JSON.parse(JSON.stringify(obj));
	}

	public static getPropertyByName(input: Object | any[], keyName: string) {
		for (const [k, v] of Object.entries(input)) {
			if (k === keyName) {
				return v;
			}
			if (Array.isArray(v)) {
				const result = this.getPropertyByName(v, keyName);
				if (result !== -1) {
					return result;
				}
			}
			if (typeof v === 'object') {
				const result = this.getPropertyByName(v, keyName);
				if (result !== -1) {
					return result;
				}
			}
		}
		return -1;
	}

	public static cleanObject(obj, includeEmptyStrings = false) {
		// If the input is not an object, return it as it is
		if (typeof obj !== 'object' || obj === null) {
			return obj;
		}

		// Iterate over each property of the object
		Object.keys(obj).forEach(key => {
			// Recursively clean the nested object
			obj[key] = this.cleanObject(obj[key]);

			// If the cleaned value is an object and is either empty or has only undefined values
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				// Check if the object is empty or all properties are undefined
				const isEmpty = Object.keys(obj[key]).length === 0;
				// const allUndefined = Object.values(obj[key]).every(value => value === undefined);
				const allUndefinedOrEmptyString = Object.values(obj[key]).every(
					value => value === undefined || (value === '' && !includeEmptyStrings)
				);

				// If the object is empty or all properties are undefined, delete the property
				if (isEmpty || allUndefinedOrEmptyString) {
					delete obj[key];
				}
			}
		});

		return obj;
	}
}
