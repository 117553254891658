import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, EntityUIStore, ActiveState } from '@datorama/akita';
import { StorySegment, StorySegmentUI } from './story-segment.model';

export interface StorySegmentState extends EntityState<StorySegment>, ActiveState {}
export interface StorySegmentUIState extends EntityState<StorySegmentUI> {}

const initialState = {
	active: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'story-segment'
})
export class StorySegmentStore extends EntityStore<StorySegmentState> {
	ui: EntityUIStore<StorySegmentUIState>;

	constructor() {
		super(initialState);
		this.createUIStore().setInitialEntityState({ activeClip: undefined, overlays: [], errors: [] });
	}
}
