import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CreativeUnitPackageState, CreativeUnitPackageStore } from './ad-unit-package.store';
import { Observable } from 'rxjs';
import { CreativeUnitWithCustomizationsDto } from '../../../../../../api/src/creative-unit-package/models/creative-unit/creative-unit.dto';

@Injectable({ providedIn: 'root' })
export class CreativeUnitPackageQuery extends QueryEntity<CreativeUnitPackageState> {

	/**
	 * Selects the active Creative Unit from the editor state.
	 */
	activeCreativeUnitId$: Observable<CreativeUnitWithCustomizationsDto['id']> = this.select(state => state.editor?.activeCreativeUnitId);

	constructor(
		protected override store: CreativeUnitPackageStore
	) {
		super(store);
	}

	getActiveCreativeUnitId(): CreativeUnitWithCustomizationsDto['id'] {
		return this.getValue().editor?.activeCreativeUnitId;
	}
}
