import {Column, Entity, JoinColumn, JoinTable, ManyToOne, OneToMany, PrimaryGeneratedColumn} from 'typeorm';
import { Business, PublicBusiness } from '../business/business.entity';
import { Project, PublicProject } from '../project/project.entity';
import { PublicSite, Site } from '../site/site.entity';
import { PublicUser, User } from '../user/user.entity';
import {BusinessUser} from '../business/business-user.entity';

export type PublicAsset = Pick<
	Asset,
	| 'id'
	| 'type'
	| 'provider'
	| 'title'
	| 'description'
	| 'siteId'
	| 'businessId'
	| 'projectId'
	| 'userId'
	| 'assetPath'
	| 'created'
	| 'dimensions'
	| 'mimeType'
> & {
	site?: PublicSite;
	business?: PublicBusiness;
	project?: PublicProject;
	user?: PublicUser;
	isReadOnly?: boolean;
};

export enum Type {
	Image = 'image',
	Music = 'music',
	Voice = 'voice',
	Video = 'video',
	Audio = 'audio',
	Font = 'font'
}

export enum Provider {
	Amazon = 'amazon',
	Cloudinary = 'cloudinary'
}

export interface AssetDimensions {
	width: number;
	height: number;
}

@Entity('assets')
export class Asset {
	constructor(value?: Partial<Asset>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column({
		type: 'enum',
		enum: Type,
		nullable: false
	})
	public type: Type;

	@Column({
		type: 'enum',
		enum: Provider,
		nullable: false
	})
	public provider: Provider;

	@Column('text', { nullable: false })
	public title: string;

	@Column('text', { nullable: true })
	public description?: string;

	@Column('uuid', { nullable: false })
	public siteId: string;
	@ManyToOne(() => Site, {
		eager: false,
		nullable: false,
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'siteId' })
	public site?: Site | Partial<Site>;

	@Column('uuid', { nullable: true })
	public businessId?: string;
	@ManyToOne(() => Business, {
		eager: false,
		nullable: true,
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'businessId' })
	public business?: Business | Partial<Business>;

	@Column('uuid', { nullable: true })
	public projectId?: string;
	@ManyToOne(() => Project, {
		eager: false,
		nullable: true,
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'projectId' })
	public project?: Project | Partial<Project>;

	@Column('uuid', { nullable: true })
	public userId?: string;
	@ManyToOne(() => User, {
		eager: false,
		nullable: true,
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'userId' })
	public user?: User | Partial<User>;

	@Column('text', { nullable: false })
	public assetPath: string;

	@Column('json', { nullable: true })
	public dimensions: AssetDimensions;

	@Column('text', { nullable: true })
	public mimeType: string;

	@Column('timestamptz', { default: () => 'NOW()' })
	public created: string;

	@OneToMany(
		() => Business,
		business => business.logo,
		{
			onDelete: 'SET NULL'
		}
	)
	@JoinTable({ name: 'businesses' })
	public businessLogos?: Business[];

	public toPublic(exclude?: Array<keyof PublicAsset>) {
		let pub: Partial<PublicAsset> = {
			id: this.id,
			type: this.type,
			provider: this.provider,
			title: this.title,
			description: this.description,
			dimensions: this.dimensions,
			mimeType: this.mimeType,
			siteId: this.siteId,
			businessId: this.businessId,
			projectId: this.projectId,
			assetPath: this.assetPath,
			created: this.created
		};

		if (this.site && !exclude.includes('site')) {
			pub.site = new Site(this.site).toPublic(['assets']);
		}

		if (this.business && !exclude.includes('business')) {
			pub.business = new Business(this.business).toPublic();
		}

		if (this.project && !exclude.includes('project')) {
			pub.project = new Project(this.project).toPublic(['assets']);
		}

		if (this.user && !exclude.includes('user')) {
			pub.user = new User(this.user).toPublic();
		}

		return pub as PublicAsset;
	}
}
