import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ExportActionState, ExportActionStore } from './export-action.store';

@Injectable({ providedIn: 'root' })
export class ExportActionQuery extends QueryEntity<ExportActionState> {
	constructor(protected override store: ExportActionStore) {
		super(store);
	}
}
