import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocaleDto } from '../../../../../../api/src/creative-unit-package/models/locale.dto';
import { LocalizationService } from '../../../_core/services/localization.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-set-locale-dialog',
  templateUrl: './set-locale-dialog.component.html',
  styleUrl: './set-locale-dialog.component.scss'
})
export class SetLocaleDialogComponent {
	localizationService = inject(LocalizationService);

	// Turn locales into options for our form.
	public locales$ = this.localizationService.locales$.pipe(
		map(locales => locales.map(locale => ({ label: locale.code, value: locale.code })))
	);

	constructor(
		public dialogRef: MatDialogRef<SetLocaleDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			localeData: LocaleDto;
		}
	) {}

	ngOnInit(): void {}

	setLocaleData(localeData: LocaleDto) {
		this.data.localeData = localeData;
	}

	save() {
		this.dialogRef.close(this.data.localeData);
	}
}
