import { ID } from '@datorama/akita';
import { Clip } from '../clip';
import { AssetFilter, flattenStrapiEntity, StrapiCollectionResponse, StrapiEntity, ValidationError } from '../global/global.model';
import { createOverlay, Overlay } from '../overlay/overlay.model';

export interface StorySegment {
	id: string;
	slug: string;
	required: boolean;
	overlays: any;
	insertByDefault: boolean;
	ignoreGlobalOverlay: boolean;
	maximumDuration: number;
	canResizeSegment: boolean;
	canUploadVideo: boolean;
	endingSegment: boolean;
	clips: Clip[];
	title: string; // CMS facing title
	label: string; // User facing label
	assetFilters: AssetFilter[];
	assetPath: string;
	relatedVoiceoverSlugs: string;
	ui?: StorySegmentUI;
}
export interface StorySegmentUI {
	activeClip: Clip;
	overlays: Partial<Overlay>[];
	errors: ValidationError[];
}

export function createStorySegment(strapiEntity: StrapiEntity<StorySegment>, params?: Partial<StorySegment>) {
	const overlays = strapiEntity.attributes.overlays as unknown as StrapiCollectionResponse<Overlay>;

	return {
		...flattenStrapiEntity(strapiEntity),
		overlays: overlays?.data.map(overlay => createOverlay(overlay as any, params)) || [],
		...params
	} as StorySegment;
}
