import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PublicExportAction } from '../../../../../../api/src/export-action/export-action.entity';

export interface ExportActionState extends EntityState<PublicExportAction>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'export-actions'
})
export class ExportActionStore extends EntityStore<ExportActionState> {
	constructor() {
		super();
	}
}
