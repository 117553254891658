import { IsEnum, IsNumber, IsOptional } from 'class-validator';

export enum AudioTag {
	Music = 'music',
	Sfx = 'sfx',
	Voiceover = 'voiceover',
	Custom = 'custom'
}

export class LayerAudioDto {

	@IsOptional()
	@IsEnum(AudioTag)
	volumeTag?: AudioTag;

	@IsOptional()
	@IsNumber()
	integratedLoudness?: number;

	@IsOptional()
	@IsNumber()
	loudnessRange?: number;

	@IsOptional()
	@IsNumber()
	maxTruePeak?: number;

	@IsOptional()
	@IsNumber()
	volume?: number;

	public static toReceiptMarkdown(audio: LayerAudioDto, includeDevInfo = false) {
		let mdString: string = '';
		if (audio?.volumeTag) {
			mdString += `__Audio Tag:__ ${audio.volumeTag}<br>`;
		}
		if (audio?.volume) {
			mdString += `__Audio Volume:__ ${audio.volume}<br>`;
		}

		if (includeDevInfo) {
			if (audio?.integratedLoudness) {
				mdString += `__Integrated Loudness:__ ${audio.integratedLoudness}<br>`;
			}
			if (audio?.loudnessRange) {
				mdString += `__Loudness Range:__ ${audio.loudnessRange}<br>`;
			}
			if (audio?.maxTruePeak) {
				mdString += `__Max True Peak:__ ${audio.maxTruePeak}<br>`;
			}
		}
		return mdString;
	}
}
