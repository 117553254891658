import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ImagePickerActionsDto } from '../../../../../../../api/src/creative-unit-package/models/customization/customization.dto';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SharedModule } from "../../../shared.module";
import { InputModule } from "../../../components/input/input.module";

@Component({
  selector: 'app-image-tool-crop',
  templateUrl: './image-tool-crop.component.html',
  styleUrl: './image-tool-crop.component.scss'
})
export class ImageToolCropComponent implements OnChanges {
	@Input() targetCropConfig: ImagePickerActionsDto['targetCropConfig'];
	@Output() targetCropConfigChange: EventEmitter<Partial<ImagePickerActionsDto['targetCropConfig']>> = new EventEmitter<Partial<ImagePickerActionsDto['targetCropConfig']>>();

	public form: UntypedFormGroup;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.form) {
			this.form = new UntypedFormGroup({
				target: new UntypedFormGroup({
					x: new UntypedFormControl(this.targetCropConfig?.target?.x),
					y: new UntypedFormControl(this.targetCropConfig?.target?.y),
					width: new UntypedFormControl(this.targetCropConfig?.target?.width),
					height: new UntypedFormControl(this.targetCropConfig?.target?.height)
				})
			});

			this.form.valueChanges.subscribe((value) => {
				this.targetCropConfigChange.emit({
					target: {
						x: Number(value.target.x),
						y: Number(value.target.y),
						width: Number(value.target.width),
						height: Number(value.target.height)
					}
				});
			});

		} else {
			this.form.patchValue({
				target: {
					x: Number(this.targetCropConfig?.target?.x),
					y: Number(this.targetCropConfig?.target?.y),
					width: Number(this.targetCropConfig?.target?.width),
					height: Number(this.targetCropConfig?.target?.height)
				}
			}, { emitEvent: false });
		}
	}

}
