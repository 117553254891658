import { Injectable } from '@angular/core';
import { AIVoice, Voiceover, VoiceoverPackage, VoiceoverPackageConfig } from './voiceover.model';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FormGroup } from '@angular/forms';

export interface VoiceoverState extends EntityState<Voiceover>, ActiveState {
	selectedVoiceoverPackage: VoiceoverPackage;
	selectedVoiceoverPackageConfig: VoiceoverPackageConfig;
	aiVoiceList: AIVoice[];
}

const initialState = {
	selectedVoiceoverPackage: undefined,
	selectedVoiceoverPackageConfig: undefined,
	aiVoiceList: []
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'voiceover' })
export class VoiceoverStore extends EntityStore<VoiceoverState> {
	constructor() {
		super(initialState);
	}
}
