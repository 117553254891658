import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BusinessRole } from '../../../../../../api/src/business/business-user.entity';
import { SessionQuery } from '../session';

import { BusinessStore, BusinessState } from './business.store';

@Injectable({ providedIn: 'root' })
export class BusinessQuery extends QueryEntity<BusinessState> {
	constructor(protected override store: BusinessStore, private readonly sessionQuery: SessionQuery) {
		super(store);
	}

	public getUserBusinesses() {
		return this.sessionQuery.getProfile()?.businesses;
	}

	public getUsersLatestBusiness() {
		const businesses = this.getUserBusinesses()
			?.slice()
			.sort((a, b) => {
				return new Date(b.created).getTime() - new Date(a.created).getTime();
			});
		return businesses?.[0];
	}

	public getUserBusiness(id: string) {
		return this.sessionQuery.getProfile()?.businesses?.find(b => b.id === id);
	}

	public getManageable(isAdmin: boolean = false) {
		return this.selectAll({
			filterBy: b => {
				if (isAdmin) {
					return true;
				}
				if ([BusinessRole.Owner, BusinessRole.Administrator, BusinessRole.Finance].includes(b.role)) {
					return true;
				}
				return false;
			}
		});
	}

	public getFinanceManageable(isAdmin: boolean = false) {
		return this.selectAll({
			filterBy: b => {
				// if (isAdmin) {
				// 	return true;
				// }
				if ([BusinessRole.Owner, BusinessRole.Finance].includes(b.role)) {
					return true;
				}
				return false;
			}
		});
	}
}
