import { Entity, Column, PrimaryGeneratedColumn, ManyToOne, OneToMany, JoinColumn } from 'typeorm';

import { PublicUser, User } from '../user/user.entity';
import { EncodeJob, PublicEncodeJob } from '../encode/encode-job.entity';
import { Site } from '../site/site.entity';
import { Asset, PublicAsset } from '../asset/asset.entity';
import { Business, PublicBusiness } from '../business/business.entity';
import { TaskResult } from '../encode/models/task-result';
import { ProjectCartItem, PublicProjectCartItem } from '../cart/project-cart-item.entity';
import { StoryFormat } from './models/workspace-state';
import { ProjectsSyncingProvider, PublicSyncingProvider } from '../syncing-provider/syncingProvider.entity';
import { Hierarchy } from '../_core/third-party/wpp-open/models';

export enum State {
	GET_STARTED = 'GET_STARTED',
	EDIT_VIDEO = 'EDIT_VIDEO',
	EDIT_AUDIO = 'EDIT_AUDIO',
	REVIEW_CHECKOUT = 'REVIEW_CHECKOUT'
}


export enum Status {
	Draft = 'draft',
	NewVersion = 'newVersion',
	NeedsReview = 'needsReview',
	Approved = 'approved',
	Finished = 'finished'
}


export enum SortStrategy {
	ASC = 'ASC',
	DESC = 'DESC'
}

export enum SortString {
	Name = 'name',
	Created = 'created',
	Status = 'status'
}

export class ProjectMetadata {
	focus?: string;
	format?: string;
	storyFormat?: string;
	wppOpen?: {
		hierarchy?: Hierarchy;
	};
}

export class ProjectPreview {
	name: string;
	video: TaskResult;
	businessName: string;
	format: string;
	storyFormat: Partial<StoryFormat>;
	focus: string;
	created: string;
}

export class ProjectDownload {
	name: string;
	businessName: string;
	created: string;
	encodeJob: PublicEncodeJob;
}

export type PublicProject = Pick<
	Project,
	| 'id'
	| 'name'
	| 'siteId'
	| 'status'
	| 'state'
	| 'workspaceState'
	| 'metadata'
	| 'requiresApproval'
	| 'approved'
	| 'created'
	| 'modified'
	| 'version'
	| 'businessId'
	| 'remoteId'
> & {
	encodeJobs?: PublicEncodeJob[];
	assets?: PublicAsset[];
	user?: PublicUser;
	business?: PublicBusiness;
	cartItems: PublicProjectCartItem[];
	syncingProviders: PublicSyncingProvider[];
};
@Entity('projects')
export class Project {
	constructor(value?: Partial<Project>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column('text', { nullable: true, default: 'New Project' })
	public name: string;

	@Column('text')
	public userId: string;
	@ManyToOne(
		() => User,
		user => user.id,
		{
			eager: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'userId' })
	public user: User | Partial<User>;

	@Column('text')
	public siteId: string;
	@ManyToOne(
		() => Site,
		site => site.id,
		{
			eager: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'siteId' })
	public site: Site | Partial<Site>;

	@Column('text', { nullable: true })
	remoteId?: string;

	@Column({
		type: 'enum',
		enum: Status,
		default: Status.Draft,
		nullable: false
	})
	public status: Status;

	@Column({
		type: 'enum',
		enum: State,
		default: State.GET_STARTED,
		nullable: false
	})
	public state: State;

	@Column('jsonb', { nullable: true })
	public workspaceState: any;

	@Column('jsonb', { nullable: true })
	public metadata?: ProjectMetadata;

	@Column('boolean', { nullable: true })
	public requiresApproval?: boolean;

	@Column('boolean', { nullable: true })
	public approved?: boolean;

	@OneToMany(
		() => EncodeJob,
		job => job.project,
		{
			eager: false,
			nullable: true
		}
	)
	public encodeJobs?: EncodeJob[] | Partial<EncodeJob>[];

	@OneToMany(
		() => Asset,
		asset => asset.project,
		{
			eager: false,
			nullable: true
		}
	)
	public assets?: Asset[] | Partial<Asset>[];

	@Column('boolean', { default: false })
	public delete: boolean;

	@Column('uuid', { nullable: true })
	public businessId?: string;
	@ManyToOne(
		() => Business,
		business => business.id,
		{
			eager: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'businessId' })
	public business: Business | Partial<Business>;

	@OneToMany(
		() => ProjectCartItem,
		cartItem => cartItem.project,
		{ eager: false, nullable: true }
	)
	public cartItems?: ProjectCartItem[] | Partial<ProjectCartItem>[];

	@OneToMany(
		() => ProjectsSyncingProvider,
		syncingProvider => syncingProvider.project,
		{ eager: false, nullable: true }
	)
	public syncingProviders?: ProjectsSyncingProvider[] | Partial<ProjectsSyncingProvider>[];

	/**
	 * TODO: Figure out if we want this here, or a id ref to the encode job instead
	 */
	// @Column('text', { unique: true, nullable: false })
	// download_url: string;

	/**
	 * Versioning
	 * This will help us know what version of the projects page to use.
	 */
	@Column({ type: 'text', default: () => '1' })
	public version: string;

	/**
	 * TODO: Figure out how we're doing project billing and reference here if the site type has billing
	 */
	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public created: string;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public modified: string;

	public toPublic(exclude?: Array<keyof PublicProject>) {
		let pub: Partial<PublicProject> = {
			id: this.id,
			name: this.name,
			siteId: this.siteId,
			remoteId: this.remoteId,
			status: this.status,
			state: this.state,
			workspaceState: this.workspaceState,
			metadata: this.metadata,
			requiresApproval: this.requiresApproval,
			approved: this.approved,
			created: this.created,
			version: this.version,
			modified: this.modified,
			businessId: this.businessId
		};

		if (this.encodeJobs?.length && !exclude?.includes('encodeJobs')) {
			pub.encodeJobs = (this.encodeJobs as EncodeJob[]).map(j => new EncodeJob(j).toPublic(['project']));
		}

		if (this.assets?.length && !exclude?.includes('assets')) {
			pub.assets = (this.assets as Asset[]).map(a => new Asset(a).toPublic(['project']));
		}

		if (this.cartItems?.length && !exclude?.includes('cartItems')) {
			pub.cartItems = (this.cartItems as ProjectCartItem[]).map(a => new ProjectCartItem(a).toPublic());
		}

		if (this.syncingProviders?.length && !exclude?.includes('syncingProviders')) {
			pub.syncingProviders = (this.syncingProviders as ProjectsSyncingProvider[]).map(a => new ProjectsSyncingProvider(a).toPublic());
		}

		if (this.user) {
			pub.user = new User(this.user).toPublic();
		}

		if (this.business) {
			pub.business = new Business(this.business).toPublic();
		}

		return pub as PublicProject;
	}
}
