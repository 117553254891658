import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Business } from './business.model';

export interface BusinessState extends EntityState<Business, string>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'business'
})
export class BusinessStore extends EntityStore<BusinessState> {
	constructor() {
		super();
	}
}
