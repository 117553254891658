import { Component, Input, OnInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { InputType } from '../input.model';

@Component({
	selector: 'app-group-input',
	templateUrl: './group-input.component.html',
	styleUrls: ['./group-input.component.scss']
})
export class GroupInputComponent extends BaseInputComponent implements OnInit {

	@Input() children: InputType[];

	constructor() {
		super();
	}

}
