import { flattenStrapiEntity, StrapiEntity } from '../global/global.model';
import { StorySegment } from '../story-segment';
import { Group } from './overlay/overlay.component';
import {AILanguage} from "../language";

export interface Overlay {
	id: string;
	title: string;
	type?: 'global' | 'segment' | 'clip';
	instruction?: string;
	isEnabled: boolean;
	fields: OverlayField[];
	canDisable?: boolean;
	snapshot?: string;
	parentId?: StorySegment['id']; // Only overlays pull from a story segment will have this.
	visible?: boolean;
	groupingObject?: groupingObject[];
	groupingObjectRaw?: Group[];
	offset?: number;
	duration?: number;
	clipOffsetVertical?: number;
	clipOffsetHorizontal?: number;
	slug?: string;
	enableVideoUpload?: boolean;
	localizeLogo?: boolean;
	previewURL?: string;
	languageCode?: {
		id?: string;
		data?: {
			attributes?: AILanguage
		}
	};
	enforcedFontFamily?: string;
}

export interface groupingObject {
	group: group;
}

export interface group {
	groups: any[];
	type: string;
	fields: fields;
	groupOptions: any;
	fieldOptions: any;
}
export interface fields {
	fields: string | object;
	visible?: boolean;
}

export interface OverlayField {
	fieldType: string;
	horizontalOffset: number;
	id: string;
	instructions: string;
	label: string;
	positionHorizontally: 'left' | 'center' | 'right';
	positionVertically: 'top' | 'middle' | 'bottom';
	verticalOffset: number;
	value: any;
	defaultValue: any;
	required: boolean;
	textColor: string;
	fontSize: number;
	fontFamily: string;
	fontWeight: number;
	letterSpacing: number;
	textAlign: string;
	maxWidth: number;
	maxHeight: number;
	prependText: string;
	appendText: string;
	lineHeight: number;
	forceUppercase: boolean;
	allowMovementX: boolean;
	allowMovementY: boolean;
	allowScalingX: boolean;
	allowScalingY: boolean;
	allowRotation: boolean;
	disableRender?: boolean;
	alignmentOptionsEnabled?: boolean;
	sendToBack?: boolean;
	alignmentOrientationPadding?: number;
	boundingBoxMaxWidth?: number;
	boundingBoxMaxHeight?: number;
	maxTopBoundsPercent?: number;
	maxBottomBoundsPercent?: number;
	maxLeftBoundsPercent?: number;
	maxRightBoundsPercent?: number;
	opacity?: number;
	size?: number;
	imageWidth: number;
	imageHeight: number;
	shapeStrokeWidth?: number;
	shapeStrokeColor?: string;
	shapeLength?: number;
	originX?: 'left' | 'center' | 'right';
	originSpacing?: number;
	disableMovementDropdown: boolean;
	overrideFieldForVoiceover: string;
}

export function createOverlay(strapiEntity: StrapiEntity<Overlay>, params?: Partial<Overlay>) {
	return {
		...flattenStrapiEntity(strapiEntity),
		...params
	} as Overlay;
}
