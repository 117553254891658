import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';

export type PublicPaymentMethod = Pick<
	PaymentMethod,
	'id' | 'label' | 'name' | 'description' | 'ctaButtonLabel' | 'enabled' | 'paymentService' | 'slug' | 'countries'
> & {
	// Other Public Properties
};

export enum PaymentService {
	Stripe = 'stripe',
	External = 'external'
}

export enum PaymentMethodSlug {
	PartsNumber = 'parts-number',
	CreditCard = 'credit-card'
}

@Entity('paymentMethods')
@Index(['slug'], { unique: true })
export class PaymentMethod {
	constructor(value?: Partial<PaymentMethod>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	public name: string;

	@Column('text', { nullable: true })
	public label?: string;

	@Column('text', { nullable: true })
	public ctaButtonLabel?: string;

	@Column('text', { nullable: false })
	public slug: string;

	@Column('text', { nullable: true })
	public description?: string;

	@Column({
		type: 'enum',
		enum: PaymentService,
		default: PaymentService.Stripe
	})
	public paymentService: PaymentService;

	@Column('json', { nullable: true })
	countries: string[];

	@Column('boolean', { nullable: false, default: true })
	public enabled: boolean;

	public toPublic() {
		const pub: Partial<PublicPaymentMethod> = {
			id: this.id,
			name: this.name,
			label: this.label,
			ctaButtonLabel: this.ctaButtonLabel,
			description: this.description,
			enabled: this.enabled,
			paymentService: this.paymentService,
			slug: this.slug,
			countries: this.countries
		};

		// Other public transformations

		return pub as PublicPaymentMethod;
	}
}
