import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { PublicExportAction } from '../../../../../../../../api/src/export-action/export-action.entity';
import { DataSourceService } from '../../../../../_core/state/data-source/data-source.service';
import { GlobalQuery } from '../../../../../_core/state/global';
import { ExportDestinationsSettingsDialogComponent, ExportDestinationsSettingsDialogData } from '../export-destinations-settings-dialog/export-destinations-settings-dialog.component';
import { ExportActionType } from '../../../../../../../../api/src/export-action/models';
import { SelectedExportActionEntity, SelectedExportActions } from '../export-destinations.component';

@Component({
	selector: 'app-export-destinations-item',
	templateUrl: './export-destinations-item.component.html',
	styleUrls: ['./export-destinations-item.component.scss'],
})
export class ExportDestinationsItemComponent implements OnInit {

	@Input() exportAction: PublicExportAction;
	@Output() configChange = new EventEmitter<{ actionConfig: SelectedExportActions, entityConfig: SelectedExportActionEntity[] }>();


	private entitiesDataSourceSlug: string;
	entitiesDataSource$: Observable<any>;

	selectedEntities: SelectedExportActionEntity[] = [];
	allExportActionEntities: any[] = [];

	constructor(
		private readonly dialog: MatDialog,
		readonly dataSourceService: DataSourceService,
		readonly globalQuery: GlobalQuery
	) {
	}


	ngOnInit() {
		this.entitiesDataSourceSlug = this.exportAction?.action.dataSource.slug + this.exportAction?.id

		this.entitiesDataSource$ = this.dataSourceService.query(this.entitiesDataSourceSlug, {}).pipe(
			map(entitiesResponse => entitiesResponse?.data?.results),
			tap(entities => this.allExportActionEntities = entities)
		);

		this.selectedEntities = this.getEntities(this.exportAction);
	}

	getEntities(exportAction: PublicExportAction) {

		if (exportAction.action.type === ExportActionType.Email) {
			return (exportAction.action.config?.['email-to'] || [])?.map(
				contacts => ({contacts, enabled: true, customAddress: false})
			) || [];
		}
	}


	onEnableExportAction(exportAction: PublicExportAction, enabled: boolean) {
		this.exportAction = {...this.exportAction, enabled}
		this._changeConfig();
	}

	onEditExportAction(exportDestination: PublicExportAction) {
		const dialogSettings: ExportDestinationsSettingsDialogData = {
			exportDestination,
			addedEntities: this.selectedEntities,
			allEntities: this.allExportActionEntities,
		};

		this.dialog
			.open(ExportDestinationsSettingsDialogComponent, {
				width: '640px',
				panelClass: ['background-color-alternative', 'max-width-lg'],
				disableClose: true,
				data: dialogSettings
			})
			.afterClosed()
			.pipe(first())
			.subscribe((response: { actionConfig, entityConfig }) => {
				if (response) {
					this.exportAction = {...this.exportAction, ...response.actionConfig} as any
					this.selectedEntities = response.entityConfig;

					this._changeConfig();
				}
			});
	}


	getActionEntityDetails(entity: any, exportAction: PublicExportAction, allExportActionEntities: any[]) {
		if (exportAction.action.type === ExportActionType.Email) {
			const address = allExportActionEntities?.find(actionEntity => actionEntity.contacts === entity.contacts) || {...entity};
			return [address.bottlerName, address.contacts];
		}
	}


	private _changeConfig() {
		this.configChange.emit(
			{
				actionConfig: {enabled: this.exportAction.enabled},
				entityConfig: this.selectedEntities
			}
		)
	}
}
