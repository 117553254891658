import { Injectable } from '@angular/core';
import { EntityUIQuery, filterNil, QueryEntity } from '@datorama/akita';
import { StoryStore, StoryState, StoryUIState } from './story.store';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import { StorySegmentQuery } from '../story-segment/story-segment.query';
import { map, filter, switchMap } from 'rxjs/operators';
import { Story, StoryUI } from './story.model';
import { replaceMergeTags } from '../../utils/string.utils';
import { SessionQuery } from '../session';
import { Overlay, OverlayField } from '../overlay/overlay.model';
import { Music, MusicQuery } from '../music';

@Injectable({ providedIn: 'root' })
export class StoryQuery extends QueryEntity<StoryState> {
	ui!: EntityUIQuery<StoryUIState, StoryUI>;
	private clipsLoadedSubject = new BehaviorSubject<boolean>(true);
	public clipsLoaded$: Observable<boolean>;

	constructor(
		protected store: StoryStore,
		protected storySegmentQuery: StorySegmentQuery,
		protected sessionQuery: SessionQuery,
		protected musicQuery: MusicQuery
	) {
		super(store);
		this.createUIQuery();
		this.clipsLoaded$ = this.clipsLoadedSubject.asObservable();
	}

	selectEntitiesWithUI(): Observable<Story[]> {
		return combineLatest([this.selectAll(), this.ui.selectAll({ asObject: true })]).pipe(
			map(([items, itemsUI]) => {
				return items.map(item => {
					return {
						...item,
						ui: itemsUI[item.id]
					};
				});
			})
		);
	}

	selectEntityWithUI(id: Story['id']): Observable<Story> {
		return combineLatest([this.selectAll({ asObject: true }), this.ui.selectAll({ asObject: true })]).pipe(
			map(([items, itemsUI]) => {
				return {
					...items[id],
					ui: itemsUI[id] || ({} as StoryUI)
				};
			})
		);
	}

	selectActiveWithUI(): Observable<Story> {
		return this.selectActive().pipe(
			filterNil,
			switchMap(segment => this.selectEntityWithUI(segment.id))
		);
	}

	// Get the active story group with StorySegment object merged in
	public selectActiveStoryWithSegments(): Observable<Story | undefined> {
		return combineLatest([this.selectActive(), this.storySegmentQuery.selectAll()]).pipe(
			map(([group, segments]) => {
				console.log('selectActivewithsegments', group, segments);
				if (!group || !segments) {
					return undefined;
				}

				const ssg: Story = {
					...group,
					storySegments: group.storySegments.map((segmentId: string) => {
						return segments.find(segment => segment.id === segmentId);
					})
				};
				return ssg;
			})
		);
	}

	public getActiveWithUI(): Story {
		return {
			...this.getActive(),
			ui: this.ui.getEntity(this.getActiveId())
		} as Story;
	}

	/*
		Utility Functions
	*/

	getOverlayUIState(ui: StoryUI, overlay: Overlay) {
		return ui.overlays.find(o => o.id === overlay.id);
	}

	getOverlayUIStateValue(ui: StoryUI, overlay: Overlay, key: string) {
		const val: any = ui.overlays.find(o => o.id === overlay.id)?.[key];

		// Support boolean 'false' values
		if (typeof val === 'undefined') {
			return overlay[key];
		} else {
			return val;
		}
	}

	getFieldValue(ui: StoryUI, overlay: Overlay, field: OverlayField, mergeTags?: boolean, project?: any) {
		const value = ui.overlays.find(o => o.id === overlay.id)?.fields?.find(f => f.id === field.id)?.value || field.defaultValue;

		if (mergeTags) {
			return replaceMergeTags(value || '', {
				profile: this.sessionQuery.getProfile(),
				business: this.sessionQuery.getBusinesses().filter(b => {
					return b.id === project?.business?.id;
				})[0]
			});
		}

		return value;
	}

	selectMusicOptions() {
		return combineLatest([this.musicQuery.selectAll(), this.storySegmentQuery.selectEntitiesWithUI()]).pipe(
			map(([music, segments]) => {
				// Test if any active clips have specified certain music.
				const activeClipMusic = segments
					.map(segment => segment.ui?.activeClip?.video?.musicIds || [])
					.reduce((acc: string[], val: any) => acc.concat(val), []);

				// If the active clips have specified music, return only those.
				if (activeClipMusic.length) {

					// Return hydrated music objects and remove any undefined values.
					return activeClipMusic
						.map(id => music.find(m => m.id === id))
						.filter(m => m) as Music[];
				} else {
					// get the segments duration
					let duration = 0;
					segments.map(segment => {
						duration += segment.maximumDuration;
					});

					// Otherwise just return the music we've loaded already.
					// filter by the segments duration.
					return music.filter(m => {
						return Number(parseFloat(m.duration.toString()).toFixed(1)) === duration;
					});
				}
			})
		);
	}

	selectMusicOptionsWithFocus(focus?: string): Observable<Music[]> {
		if (!focus) {
			return this.musicQuery.selectAll();
		}

		return this.musicQuery.selectAll().pipe(map(music => music.filter(m => m.name?.toLowerCase().indexOf(focus.toLowerCase()) > -1)));
	}

	setClipsLoaded(value: boolean) {
		this.clipsLoadedSubject.next(value);
	}
}
