import { ArrayNotEmpty, IsArray, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import { ConnectorConfig, ItemDef } from '../../../models';
import { Type } from 'class-transformer';

export class ConnectorConfigNative extends ConnectorConfig {
	@IsOptional()
	@IsString()
	target: string = '';

	@ArrayNotEmpty()
	@ValidateNested({ each: true })
	@Type(() => ItemDef)
	mapping: ItemDef[];
}