<div *ngIf="tools.includes(ImageActionType.BackgroundRemoval)" class="group mt-3 mb-3">
	<button mat-flat-button class="block with-loading" (click)="removeBackground()" [disabled]="loadingAction === ImageActionType.BackgroundRemoval">
		<span class="text" i18n="Remove Background Button@@imageToolBackgroundRemoveBackgroundButtonLabel">Remove Background</span>
		<span class="spinner"><mat-spinner diameter="20" color="accent"></mat-spinner></span>
	</button>
</div>

<div *ngIf="tools.includes(ImageActionType.BlurBackground)" class="group mt-3 mb-3">
	<button mat-flat-button class="block with-loading" (click)="blurBackground()" [disabled]="loadingAction === ImageActionType.BlurBackground">
		<span class="text" i18n="Blur Background Button@@imageToolBackgroundBlurBackgroundButtonLabel">Blur Background</span>
		<span class="spinner"><mat-spinner diameter="20" color="accent"></mat-spinner></span>
	</button>
</div>

<div *ngIf="tools.includes(ImageActionType.ReplaceBackground)" class="group mt-3 mb-3">
	<app-textarea-input
		label="Replace Background Prompt"
		i18n-label="Replace Background Prompt@@imageToolBackgroundReplaceBackgroundPromptLabel"
		placeholder="Enter a prompt of what you want to replace the background with."
		i18n-placeholder="Replace Background Prompt Placeholder@@imageToolBackgroundReplaceBackgroundPromptPlaceholder"
		[parentForm]="replaceBackgroundForm"
		[controlName]="'bg_prompt'"
	></app-textarea-input>

	<button mat-flat-button class="block with-loading mt-3" (click)="replaceBackground()" [disabled]="loadingAction === ImageActionType.ReplaceBackground">
		<span class="text"
			  i18n="Generate Button@@imageToolBackgroundGenerateButtonLabel">Generate</span>
		<span class="spinner"><mat-spinner diameter="20" color="accent"></mat-spinner></span>
	</button>
</div>
