import { Entity, Column, PrimaryGeneratedColumn, ManyToOne, JoinColumn } from 'typeorm';

import { OutputConfig, TaskDto as TaskDef } from './models/job-def.dto';
import { EncodeJob, PublicEncodeJob } from './encode-job.entity';
import { TaskResult } from './models/task-result';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { ExportType } from '../creative-unit-package/creative-unit-package.entity';

export enum TaskStatus {
	Pending = 'pending',
	Active = 'active',
	Completed = 'completed',
	Failed = 'failed'
}

export class TaskError {
	@IsString()
	@IsNotEmpty()
	message: string;

	@IsOptional()
	@IsString()
	extra?: string;
}

export type EncodeTaskMetadata = {
	isVideo: boolean;
	isAd: boolean;
	formats: ExportType[];
	outputConfig: OutputConfig;
};

export type PublicEncodeTask = Pick<EncodeTask, 'id' | 'messageId' | 'created' | 'errors' | 'result' | 'status'> & {
	encodeJob?: PublicEncodeJob;
	metadata?: EncodeTaskMetadata;
};

@Entity('encode_tasks')
export class EncodeTask {
	constructor(value?: Partial<EncodeTask>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column('uuid')
	encodeJobId: string;
	@ManyToOne(
		type => EncodeJob,
		job => job.encodeTasks,
		{
			eager: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'encodeJobId' })
	public encodeJob: EncodeJob | Partial<EncodeJob>;

	@Column('text', { unique: true, nullable: true })
	public messageId?: string;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public created: string;

	@Column('jsonb', { nullable: false })
	public taskDef: TaskDef;

	@Column({
		type: 'enum',
		enum: TaskStatus,
		default: TaskStatus.Pending
	})
	public status: TaskStatus;

	@Column('jsonb', { nullable: true })
	public errors?: TaskError[];

	@Column('jsonb', { nullable: true })
	public result?: TaskResult;

	public toPublic(exclude?: Array<keyof PublicEncodeTask>) {
		let pub: Partial<PublicEncodeTask> = {
			id: this.id,
			messageId: this.messageId,
			created: this.created,
			errors: this.errors,
			result: this.result,
			status: this.status
		};

		if (this.encodeJob && !exclude?.includes('encodeJob')) {
			pub.encodeJob = new EncodeJob(this.encodeJob).toPublic(['encodeTasks']);
		}

		pub.metadata = {
			isVideo: !!this.taskDef?.video,
			isAd: !!this.taskDef?.adBuilder || !!this.taskDef?.adOptimizer || !!this.taskDef?.pdfBuilder,
			outputConfig: this.taskDef?.adBuilder?.output || this.taskDef?.adOptimizer?.output || this.taskDef?.pdfBuilder?.output || this.taskDef?.video?.output || {},
			formats: this.taskDef?.adBuilder?.formats || this.taskDef?.adOptimizer?.formats || this.taskDef?.pdfBuilder?.formats || [ExportType.VIDEO]
		};

		return pub as PublicEncodeTask;
	}
}
