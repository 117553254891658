import { Column, Entity, Index, JoinTable, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { BusinessesTag } from './businessesTag.entity';

export type PublicTag = Pick<Tag, 'id' | 'name'>;

@Entity('tags')
export class Tag {
	constructor(value?: Partial<Tag>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}
	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column({ type: 'text', unique: true })
	public name: string;

	@Column('text', { nullable: true })
	public description: string;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public created: string;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public modified: string;

	@OneToMany(
		() => BusinessesTag,
		businessTag => businessTag.business,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinTable({ name: 'businessTag' })
	public businessTag?: BusinessesTag[] | Partial<BusinessesTag[]>;

	public toPublic(self: boolean = false) {
		let pub: Partial<PublicTag> = {
			id: this.id,
			name: this.name
		};

		return pub as PublicTag;
	}
}
