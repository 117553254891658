<div class="dialog-header">
	<h1 mat-dialog-title>
		<ng-container *ngIf="data.title">{{ data.title }}</ng-container>
		<ng-container *ngIf="!data.title" i18n="Dialog title@@termsAndConditionsTitle">Terms and Conditions</ng-container>
	</h1>
	<mat-icon [mat-dialog-close]="null" *ngIf="data.showClose" class="cursor-pointer">close</mat-icon>
</div>

<div mat-dialog-content class="mt-5">
	<p class="instructions" *ngIf="data.showInstructions&&data.instructionsText" [innerHtml]="data.instructionsText | safeHtml"></p>
	<p class="instructions" *ngIf="data.showInstructions&&!data.instructionsText" [innerHtml]="'Please you agree to the following Terms of Service:'" i18n="Instructions@@termsAndConditionsInstructionsText"></p>
	<div *ngIf="!data.htmlMessage" class="disclaimer" (scroll)="scrollDisclaimer($event)" [innerHtml]="data.message | markdown | safeHtml"></div>
	<div *ngIf="data.htmlMessage" class="disclaimer" (scroll)="scrollDisclaimer($event)" [innerHtml]="data.message  | safeHtml"></div>
</div>

<div mat-dialog-actions class="dialog-actions mt-4 pb-4" *ngIf="!!data.showDeclineButton || !data.hideAcceptButton">
	<button class="cancel" mat-flat-button *ngIf="!!data.showDeclineButton" (click)="skip()">
		<ng-container *ngIf="data.declineButtonText">{{ data.declineButtonText }}</ng-container>
		<ng-container *ngIf="!data.declineButtonText" i18n="Decline Button@@termsAndConditionsDeclineButtonLabel">Decline</ng-container>
	</button>
	<button
		*ngIf="!data.hideAcceptButton"
		[disabled]="data.requireRead && !readDisclaimer"
		mat-flat-button
		color="{{data.acceptButtonColor || 'primary'}}"
		[mat-dialog-close]="data"
		class="button-extra-small">
		<span>
			<ng-container *ngIf="data.acceptButtonText">{{ data.acceptButtonText }}</ng-container>
			<ng-container *ngIf="!data.acceptButtonText" i18n="Accept Button@@termsAndConditionsAcceptButtonLabel">Accept</ng-container>
		</span>
	</button>
</div>
