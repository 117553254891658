import { IsBoolean, IsNumberString, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class LayerAutosizeDto {
	@IsString()
	@IsOptional()
	maxWidth?: string | number;

	@IsString()
	@IsOptional()
	maxHeight?: string | number;

	@IsBoolean()
	@IsOptional()
	multiLine?: boolean;

	@IsBoolean()
	@IsOptional()
	wrap?: boolean;

	@IsNumberString()
	@IsOptional()
	minFontSize?: string;

	@IsNumberString()
	@IsOptional()
	maxFontSize?: string;
}

export class LayerTextDto {
	@IsOptional()
	@IsString()
	content: string;

	@IsObject()
	@IsOptional()
	@ValidateNested()
	@Type(() => LayerAutosizeDto)
	autoSize?: LayerAutosizeDto;
}
