import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseEntityService } from '../base-entity.service';
import { CreativeUnitPackageCategoryStore } from "./creative-unit-package-category.store";
import { DefaultResponse } from "../session";
import { Observable, map, tap } from "rxjs";
import { environment } from "../../../../environments/environment";
import { PublicCreativeUnitPackageCategory } from "../../../../../../api/src/creative-unit-package-category/creative-unit-package-category.entity";
import { CreativeUnitPackageCategoryAddDto } from "../../../../../../api/src/creative-unit-package-category/dtos/creative-unit-package-category-add.dto";
import { CreativeUnitPackageCategoryUpdateDto } from "../../../../../../api/src/creative-unit-package-category/dtos/creative-unit-package-category-update.dto";
import { CreativeUnitPackageCategoryFindDto } from "../../../../../../api/src/creative-unit-package-category/dtos/creative-unit-package-category-find.dto";

@Injectable({ providedIn: 'root' })
export class CreativeUnitPackageCategoryService extends BaseEntityService {

	constructor(
		private http: HttpClient,
		private contentSectionStore: CreativeUnitPackageCategoryStore
	) {super();}

	get({page = 1, limit = 10, sort, filters}): Observable<DefaultResponse<PublicCreativeUnitPackageCategory[]>> {

		return this.http
			.post<DefaultResponse<any>>(`${environment.apiUrl}/creative-unit-package-category/find`, filters || {}, { params: { page, perPage: limit, sortBy: sort.sortBy, order: sort.direction} })
			.pipe(
				map(response => ({
					totalResults: response.data.totalResults,
					data: response.data?.results,
					status: response.status,
					message: response.message,
					numPages: response.data.numPages,
					perPage: response.data.perPage,
					page: response.data.page
				})),
				tap(response => {
					this.contentSectionStore.set(response.data);
				})
			);
	}

	getOne(id: string): Observable<DefaultResponse<PublicCreativeUnitPackageCategory>> {
		return this.http.get<DefaultResponse<PublicCreativeUnitPackageCategory>>(`${environment.apiUrl}/creative-unit-package-category/${id}`)
			.pipe(
				tap(res => {
					if (res.status === 'succeeded'){
						this.contentSectionStore.upsert(res.data.id, res.data);
					}
				})
			);
	}

	public create(contentSectionAddDto: CreativeUnitPackageCategoryAddDto): Observable<DefaultResponse<PublicCreativeUnitPackageCategory>> {
		return this.http.post<DefaultResponse<PublicCreativeUnitPackageCategory>>(`${environment.apiUrl}/creative-unit-package-category`, contentSectionAddDto)
			.pipe(
				tap(res => {
					if (res.status === 'succeeded'){
						this.contentSectionStore.upsert(res.data.id, res.data)
					}
				})
			);
	}

	public update(contentSectionSlug: string, contentSectionUpdateDto: CreativeUnitPackageCategoryUpdateDto): Observable<DefaultResponse<PublicCreativeUnitPackageCategory>> {
		return this.http.put<DefaultResponse<PublicCreativeUnitPackageCategory>>(`${environment.apiUrl}/creative-unit-package-category/${contentSectionSlug}`, contentSectionUpdateDto)
			.pipe(
				tap(res => {
					if (res.status === 'succeeded'){
						this.contentSectionStore.upsert(res.data.id, res.data);
					}

				})
			);
	}

	public delete(contentSectionSlug: string): Observable<DefaultResponse<PublicCreativeUnitPackageCategory>> {
		return this.http.delete<DefaultResponse<PublicCreativeUnitPackageCategory>>(`${environment.apiUrl}/creative-unit-package-category/${contentSectionSlug}`)
			.pipe(
				tap(res => {
						if (res.status === 'succeeded') {
							this.contentSectionStore.remove(contentSectionSlug);
						}
					}
				));
	}

}
