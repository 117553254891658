<mat-expansion-panel
	*localVar="entitiesDataSource$ | async as entitiesDataSource"
	#expansionPanel
	[togglePosition]="'before'"
	[expanded]="false"
	[class.selected]="exportAction.enabled"
>
	<mat-expansion-panel-header>
		<div class="left">
			<mat-checkbox
				color="primary"
				class="dark no-border no-radius"
				[checked]="exportAction.enabled"
				(click)="$event.stopImmediatePropagation();"
				(change)="onEnableExportAction(exportAction, $event.checked)"

			></mat-checkbox>

			<div class="custom-expansion-indicator" [class.expanded]="expansionPanel.expanded">
				<mat-icon>expand_more</mat-icon>
			</div>

			<div class="group-title">
				<span>{{ exportAction.name }}</span>
				<span class="font">{{exportAction.action?.description || ''}} </span>
			</div>

		</div>
		<div class="right">
			<button mat-button class="size-small" (click)="$event.stopImmediatePropagation(); onEditExportAction(exportAction)">
				<mat-icon inline>edit</mat-icon>
				<ng-container i18n="Button Label@@exportDestinationsEditButtonLabel">Edit</ng-container>
			</button>
		</div>

	</mat-expansion-panel-header>

	<div class="item-container">
		<ng-container *ngFor="let entity of selectedEntities">
			<div class="item">
				<div class="left">
					<mat-checkbox
						color="primary"
						class="dark no-border no-radius"
						[checked]="entity.enabled"
						[disabled]="true"
					></mat-checkbox>
					<div class="name-container" *ngIf="entity | transformObject: getActionEntityDetails: exportAction: allExportActionEntities as description">
						<div class="name">{{ description?.[0] || '' }}</div>
						<div class="value" >{{ description?.[1] || ''}}</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</mat-expansion-panel>
