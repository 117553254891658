import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SessionState, Profile } from './session.model';
import { BusinessUser } from '../../../../../../api/src/business/business-user.entity';

const SESSION_KEY = 'modularVideoSession';
const DEALER_INFO = 'modularVideoDealerInfo';
export const ORG_SETTINGS = 'dealerPortalOrgSettings';
export const STYLE_SETTINGS = 'modularVideoStyleSettings';

export function createInitialSessionState(): SessionState {
	return {
		token: null,
		profile: {
			email: undefined,
			role: undefined,
			photo: undefined,
			social: {},
			dealer: {
				name: undefined,
				website: undefined,
				...getSession(DEALER_INFO)
			}
		},
		ui: {
			emailInput: undefined
		},
		...getSession(SESSION_KEY)
	};
}

export function getSession(key: string) {
	const session = localStorage.getItem(key);
	return session ? JSON.parse(session) : {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
	constructor() {
		super(createInitialSessionState());
	}

	login(session: Partial<SessionState>) {
		this.update(
			state =>
				({
					...state,
					...session,
					// Take this out when we move dealer info to the API.
					profile: {
						...session.profile,
						dealer: {
							name: undefined,
							website: undefined,
							...getSession(DEALER_INFO)
						}
					}
				} as Partial<SessionState>)
		);
		localStorage.setItem(SESSION_KEY, JSON.stringify(session));
	}

	logout() {
		localStorage.removeItem(SESSION_KEY);
		localStorage.removeItem(DEALER_INFO);
		localStorage.removeItem(ORG_SETTINGS);

		if (window['fcWidget']) {
			window['fcWidget'].destroy();
		}

		this.update(createInitialSessionState());
	}

	updateProfile(profile: Partial<Profile>) {
		// Merge any profile properties in with existing profile
		let newState;
		this.update(state => {
			newState = {
				...state,
				profile: {
					...state.profile,
					...profile
				}
			};

			return newState;
		});

		// Update local storage as well
		if (profile.settings) {
			localStorage.setItem(DEALER_INFO, JSON.stringify(profile.settings));
		}
	}
}
