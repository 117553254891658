import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {StrapiMedia, StrapiSingleResponse} from '../../../_core/state/global/global.model';
import {AssetService} from '../../../_core/state/asset/asset.service';

@Component({
	selector: 'app-asset-media',
	templateUrl: './asset-media.component.html',
	styleUrls: ['./asset-media.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class AssetMediaComponent implements OnInit {

	@Input() media: StrapiSingleResponse<StrapiMedia>;
	@Input() videoAutoplay = true;
	@Input() videoControls = false;

	constructor(readonly assetService: AssetService) {}

	ngOnInit() {
	}


}
