import { C } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GlobalQuery } from '../global';
import { Asset, AssetQueryRequest } from '../global/global.model';
import { getAssetFilterTagCriteria } from '../global/global.utils';
import { MusicQuery } from '../music';
import { Overlay, OverlayField } from '../overlay/overlay.model';
import { OverlayQuery } from '../overlay/overlay.query';
import { DefaultResponse } from '../session';
import { StoryQuery } from '../story';
import { StorySegment, StorySegmentQuery } from '../story-segment';
import { Survey } from '../survey/survey.model';
import { SurveyQuery } from '../survey/survey.query';
import { Clip } from './clip.model';
import { ClipStore } from './clip.store';
import {StoryFormat, StoryFormatQuery} from '../story-format';
import { ProjectsQuery } from '../project';

@Injectable({ providedIn: 'root' })
export class ClipService {
	constructor(
		private globalQuery: GlobalQuery,
		private storyQuery: StoryQuery,
		private surveyQuery: SurveyQuery,
		private overlayQuery: OverlayQuery,
		private musicQuery: MusicQuery,
		private clipStore: ClipStore,
		private http: HttpClient,
		private storySegmentQuery: StorySegmentQuery,
		private storyFormatQuery: StoryFormatQuery,
		private projectsQuery: ProjectsQuery
	) {}

	public get(segmentId: string, storyId: string, params: AssetQueryRequest) {
		const filters = {};
		// If we have a storyId, we want to filter by any survey criteria there.
		// If we have a segmentId, we want to filter by any filter criteria there as well.

		let httpParams: { [key: string]: any } = {
			provider: params.provider,
			path: params.path,
			type: 'clip'
		};

		if (params.includeTags) {
			httpParams['includeTags'] = params.includeTags;
		}
		if (params.excludeTags) {
			httpParams['excludeTags'] = params.excludeTags;
		}

		httpParams['projectId'] = this.projectsQuery.getActiveId();

		return this.http
			.get<DefaultResponse<Asset[]>>(`${environment.apiUrl}/asset`, {
				params: httpParams
			})
			.pipe(
				map(response => {
					const segment: StorySegment = this.storySegmentQuery.getEntity(segmentId);
					return response?.data.map(
						asset =>
							({
								id: `${storyId}-${segmentId}-${asset.id}`,
								name: asset.name,
								storyId,
								segmentId,
								video: asset,
								duration: segment && segment.maximumDuration,
								seek: 0
							} as Clip)
					);
				}),
				map(clips =>
					clips.map(clip => {
                        let storyFormatOverlays = this.storyFormatQuery.getActive().overlays;
						if (storyFormatOverlays.length > 0) {
                            const storyLanguageCode: string = this.storyQuery.getActive().language.languageCode;
							storyFormatOverlays = storyFormatOverlays.filter(overlay => {
								if (storyLanguageCode && overlay.languageCode && overlay.languageCode.data && overlay.languageCode.data.attributes) {
									return overlay.languageCode.data.attributes.languageCode === storyLanguageCode
								} else {
									return true;
								}
							});
							clip.overlays = storyFormatOverlays;
						} else if (clip.video?.overlayIds) {
							// Populate the clip with overlays if it has overlay ids.
							try {
								let overlayIds = clip.video.overlayIds;
								clip.overlays = overlayIds
									.map(id => this.overlayQuery.getEntity(id) as Overlay)
									.filter(i => i !== undefined);
							} catch (e) {
								console.log('Error parsing overlay ids', clip.name, clip);
							}
						}

						return clip;
					})
				),
				map((clips: Clip[]) => {
					clips.map((clip: Clip) => {
						let isCustomClip = false;
						clip.video.overlayIds.forEach((id: number | string) => {
							if (this.overlayQuery.getEntity(id) && this.overlayQuery.getEntity(id).enableVideoUpload) {
								isCustomClip = true;
							}
						});

						if (isCustomClip && clip.overlays) {
							clip.volume = 0; // volume set to 0

							// get horizontal offset if background exists
							let backgroundField: OverlayField;
							clip.overlays.forEach((overlay: Overlay) => {
								backgroundField = overlay.fields.find((field: OverlayField) => field.sendToBack);
							});

							if (backgroundField && backgroundField.imageWidth) {
								clip.horizontalOffset = (backgroundField.imageWidth / 2) * -1;
							}
						}
					});
					return clips;
				}),
				tap(clips => {
					this.clipStore.add(clips);
				})
			);
	}

	public getAllClipsForStory(storyId: string) {
		const story = this.storyQuery.getActive();
		const storyFormat: StoryFormat = this.storyFormatQuery.getActive();
		this.clipStore.setLoading(true);

		let storySegmentWithUI = this.storySegmentQuery.getEntitiesWithUI();
		let clipAssetFilters = [];
		// Get the clip specific assetFilters
		let done = storySegmentWithUI
			.map((segment: StorySegment, i: number) => {
				if (segment.ui?.activeClip?.video?.assetFilters && segment.ui?.activeClip?.video?.assetFilters.length > 0) {
					return segment.ui?.activeClip?.video?.assetFilters;
				}
			})
			.filter(i => i !== undefined);

		if (done) {
			clipAssetFilters = done[0];
			// Build a param object for the API call.
			const queries = story?.storySegments.map((segment: StorySegment, i: number) => {
				// create a copy of the segment
				let segmentCopy = { ...segment };
				// if there are assetFilter on the clip
				if (clipAssetFilters && clipAssetFilters.length > 0) {
					segmentCopy.assetFilters = segmentCopy.assetFilters.concat(clipAssetFilters).filter(i => i !== undefined);
					// Replace the existing exclude asset filter with the clips specific include asset filters
					let temp = segment.assetFilters.filter(filter => {
						return !clipAssetFilters.some(clipFilter => {
							if (
								filter.tags === clipFilter.tags &&
								filter.filterCriteria === 'exclude' &&
								clipFilter.filterCriteria === 'include' &&
								filter.overrideParentEntityFilters === false &&
								clipFilter.overrideParentEntityFilters === true
							) {
								return true;
							}
						});
					});
					segmentCopy.assetFilters = [...temp, ...clipAssetFilters].filter(i => i !== undefined);
				} else {
					// if there are no clip specific asset filter use original segment
					segmentCopy = { ...segment };
				}

				let params: any = {
					provider: this.globalQuery.getValue().settings?.assetProvider,
					path: segment.assetPath || this.globalQuery.getValue().settings?.defaultClipPath,

					// Get all the tags for clips
					...getAssetFilterTagCriteria(
						'clips',
						storyFormat.assetFilters,
						story.assetFilters,
						this.surveyQuery.getAll() as Survey[],
						[segmentCopy]
					)
				};

				return this.get(segment.id, storyId, params);
			});

			return forkJoin(queries).pipe(tap(() => this.clipStore.setLoading(false)));
		}
	}

	public updateClip(id: Clip['id'], key: string, value: any) {
		this.clipStore.update(id, clip => ({
			[key]: value
		}));
	}

	public removeAll() {
		this.clipStore.remove();
	}

	isCustomClip(clip: Clip): boolean {
		// need to find better solution
		if (clip && clip.overlays) {
			return !!clip.overlays.find((overlay: Overlay) => overlay.enableVideoUpload);
		}
	}

	public getClipTags(clip: Clip): string[] {
		const tags: string[] = [];

		if (clip.video.customFields.ModelYear) {
			tags.push(clip.video.customFields.ModelYear);
		}

		if (clip.video.customFields.model) {
			tags.push(clip.video.customFields.model);
		}
		// add more tags here

		return tags;
	}
}
