import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { CartState, CartStore } from './cart.store';
import { combineLatest, map } from 'rxjs';
import { CreativeUnitPackageQuery } from '../ad/ad-unit-package.query';
import { CartItem, CartItemType } from "./cart.model";
import { cloneDeep } from "lodash";

@Injectable({ providedIn: 'root' })
export class CartQuery extends QueryEntity<CartState> {

	selectItemsFromActivePackage$ = combineLatest([
		this.selectAll(),
		this.creativeUnitPackageQuery.selectActiveId()
	]).pipe(
			// filter(([items, id]) => items.length > 0 && id !== undefined),
			// tap(([items, id]) => console.log('Side Effect', items, id)),
			map(([items, id]) => this.getItemsFromPackage(id))
		);

	selectActiveItemsFromCart$ = this.selectAll({
		filterBy(item: CartItem) {
			const isCorrectType = item.type === CartItemType.VIDEO || item.type === CartItemType.AD_UNIT;

			let hasSetData = true;
			if (item.type === CartItemType.VIDEO) {
                hasSetData = !!(item.itemData?.story?.active || item.metadata?.storyTitle) && !!item.name;
            }

            return isCorrectType && hasSetData;
		}
	})

	constructor(
		protected override store: CartStore,
		protected creativeUnitPackageQuery: CreativeUnitPackageQuery
	) {
		super(store);
	}

	getItemsFromPackage(id: string | ID) {
		return this.getAll({
			filterBy: i => i.itemData?.packageId === id
		});
	}

	getItemsFromSamePackageAsActive() {
		const activeItem = this.getActive();
		if (!activeItem) return [];

		return this.getItemsFromSamePackage(activeItem.id);
	}

	getItemsFromSamePackage(packageId: string) {
		const item = this.creativeUnitPackageQuery.getEntity(packageId);
		if (!item) return [];

		return this.getAll({
			filterBy: i => i.itemData?.packageId === item.id
		});
	}

	getActiveItemsFromCart() {
		return this.getAll({
			filterBy(item: CartItem) {
				const isCorrectType = item.type === CartItemType.VIDEO || item.type === CartItemType.AD_UNIT;

				let hasSetData = true;
				if (item.type === CartItemType.VIDEO) {
					hasSetData = !!item.metadata.storyTitle && !!item.name;
				}

				return isCorrectType && hasSetData;
			}
		})
	}

	getVideoItemThumbnailUrlFromItemData(itemData: any): string | undefined {
		let thumbnailUrl: string | undefined;

		if(itemData?.['UI/story-segment']) {
			const segmentId = itemData?.['UI/story-segment']?.ids?.[0] || 1
			thumbnailUrl = itemData?.['UI/story-segment']?.entities?.[segmentId]?.activeClip?.video?.poster;
		}

		return thumbnailUrl;
	}

}
