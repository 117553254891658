import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'textSingularPlural'
})
export class TextSingularPluralPipe implements PipeTransform {

    transform(value: number, singularText: string, pluralText: string, hideValue = false): string {
		const valueText = hideValue ? '' : value + ' ';

        if (value === 1) {
            return `${valueText}${singularText}`;
        }

		return `${valueText}${pluralText}`;

    }

}
