import { trigger, state, style, transition, animate, query, group } from '@angular/animations';

export function fade(name: string, duration: number, x: string = '0', y: string = '0') {
	return trigger(name, [
		state('in', style({ opacity: 1 })),
		transition(':enter', [style({ opacity: 0, transform: `translate3d(${x}, ${y}, 0)` }), animate(duration)]),
		transition(':leave', animate(duration, style({ opacity: 0, transform: `translate3d(${x}, ${y}, 0)` })))
	]);
}
export function routerTransitionFade(name: string, duration: string) {
	return trigger(name, [
		transition('* <=> *', [
			query(':enter, :leave', [style({ position: 'fixed', width: '100%' })], { optional: true }),
			group([
				query(':enter', [style({ opacity: 0 }), animate(duration, style({ opacity: 1 }))], { optional: true }),
				query(':leave', [style({ opacity: 1 }), animate(duration, style({ opacity: 0 }))], { optional: true })
			])
		])
	]);
}
