import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PublicCreativeUnitPackageCategory } from '../../../../../../api/src/creative-unit-package-category/creative-unit-package-category.entity';

export interface CreativeUnitPackageCategoryState extends EntityState<PublicCreativeUnitPackageCategory> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'creative-unit-package-listing'
})
export class CreativeUnitPackageCategoryStore extends EntityStore<CreativeUnitPackageCategoryState> {
	constructor() {
		super();
	}
}
