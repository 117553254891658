import { IsString } from 'class-validator';
import { ConnectorConfig } from '../../../models';

export class ConnectorConfigSmartSheet extends ConnectorConfig {
	/* Target should be the id of the worksheet to access. */
	@IsString()
	target: string;

	@IsString()
	accessToken: string;

	// Other properties specific to SmartSheet...
}
