import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AssetDto } from '../../../../../../api/src/asset/models/asset.dto';

export interface AssetState extends EntityState<AssetDto, string>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'asset'
})
export class AssetStore extends EntityStore<AssetState> {
	constructor() {
		super();
	}
}
