import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperComponent } from './component/image-cropper.component';
import { materialModules } from '../../../angular-material.module';

@NgModule({
  imports: [
    CommonModule,
	materialModules
  ],
  declarations: [
    ImageCropperComponent,

  ],
  exports: [
    ImageCropperComponent
  ]
})
export class ImageCropperModule {
}
