import { flattenStrapiEntity, StrapiEntity, StrapiSingleResponse } from '../global/global.model';
import { AIVoiceSettings } from '../voiceover/voiceover.model';

export class AILanguage {
	name: string;
	languageCode: string;
	defaultLanguage?: boolean;
}

export interface AILanguageSettings extends AIVoiceSettings {
	language: AILanguage;
}

export function createLanguage(strapiEntity: StrapiEntity<AILanguage>, params?: Partial<AILanguage>) {
	return {
		...flattenStrapiEntity(strapiEntity),
		...params
	} as AILanguage;
}

export function createLanguageSettings(languageSettings: AILanguageSettings, params?: Partial<AILanguageSettings>) {
	const language = (languageSettings as any) as unknown as StrapiSingleResponse<AILanguage>;

	return {
		...languageSettings,
		language: createLanguage(language?.data as any),
		...params
	} as AILanguageSettings;
}
